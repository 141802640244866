import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../../api";
import dayjs from "dayjs";
import {
    Box, Grid, Button, TextInput, Text, Group, Select, useMantineTheme,Tabs,
} from "@mantine/core";
import Loader from "../../../components/Loader";
import MainCard from "../../../components/Cards/MainCard";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import PendingList from "./pending";
import ApprovedList from "./approved";
import {IconCalendar} from "@tabler/icons";
import {showCustomNotification} from "../../../helper/customNotification";
import {isOracleError} from "../../../helper/common";
import ReportCardLoader from "../../../components/ReportCardLoader";
import {DatePicker} from "@mantine/dates";
import TextFieldInput from "../../../components/TextFieldInput";
import SelectDropdown from "../../../components/SelectDropdown";

const Wps = () => {

    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [pendingListData, setPendingListData] = useState([]);
    const [approvedListData, setApprovedListData] = useState([]);

    const handleSubmit = async (
        values: any,
        {setErrors, setSubmitting}: any
    ) => {
        try {
            const payload = {
             //   supp_req_id: values.search_term,
                status: values.status,
                supp_req_id:''
               // date: dayjs(values.date).format("DD-MMM-YY")
            };
            // setLoading(true)
            const resp = await api.post("supplier/pendingList", undefined, payload);
            if (resp.status) {
                setLoading(false)
                setPendingListData(resp.data?? [])
            } else {
                setLoading(false)
                setErrors({submit: resp.message});
                setSubmitting(false);
                dispatch({
                    type: "OPEN_SNACK_TOAST",
                    payload: {title: "Server Error", message: resp.message},
                });
            }

        } catch (error: any) {
            setErrors({submit: error.message});
            setSubmitting(false);
        }
    };

    const handleApprovedSubmit = async (
        values: any,
        {setErrors, setSubmitting}: any
    ) => {
        try {
            const payload = {
                supp_code: values.search_term,
            };
            // setLoading(true)
            const resp = await api.post("supplier/actualList", undefined, payload);
            if (resp.status) {
                setLoading(false)
                setApprovedListData(resp.data.Rc?? [])
            } else {
                setLoading(false)
                setErrors({submit: resp.message});
                setSubmitting(false);
                dispatch({
                    type: "OPEN_SNACK_TOAST",
                    payload: {title: "Server Error", message: resp.message},
                });
            }
        } catch (error: any) {
            setErrors({submit: error.message});
            setSubmitting(false);
        }
    };
    return (
        <>
            <MainCard title="Supplier List">
                <Tabs defaultValue="gallery">
                <Tabs.List>
                <Tabs.Tab value="pending">Pending</Tabs.Tab>
                <Tabs.Tab value="approved" >Approved</Tabs.Tab>

                </Tabs.List>

                <Tabs.Panel value="pending" pt="xs">

                <Formik
                    initialValues={{
                        search_term: "",
                        status: "O",
                        date: new Date(),
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                      //  search_term: Yup.string().required('Please Enter Search'),
                        status: Yup.string().required('Please Select Status'),
                      //  date: Yup.string().required('Please Select Date'),
                    })}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <Grid sx={{marginTop: '30px'}}>
                                <Grid.Col xs={12} md={3}>
                                    <TextFieldInput formik={formik} id="search_term" placeholder={'Search'}/>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={3}>

                                        <SelectDropdown
                                            formik={formik}
                                            id="status"

                                            options={[
                                                {value: 'O', label: 'Pending'},
                                                // {value: 'A', label: 'Actual'},
                                            ]}
                                        />
                                </Grid.Col>


                                {/*<Grid.Col xs={12} md={2}>*/}

                                    {/*<DatePicker*/}
                                        {/*value={formik.values.date}*/}
                                        {/*onChange={(newDate: Date) => {*/}
                                            {/*formik.setFieldValue("date", newDate);*/}
                                        {/*}}*/}
                                        {/*clearable={false}*/}
                                        {/*rightSection={<IconCalendar size={18} color="#25338e"/>}*/}
                                        {/*zIndex={500}*/}
                                    {/*/>*/}
                                {/*</Grid.Col>*/}

                                <Grid.Col xs={12} md={2} sx={{marginTop: '-25px'}}>
                                    <Group>
                                        <Group mt="xl">

                                            <Button type="submit" fullWidth> Show </Button>

                                            {/*{errors.submit && (*/}
                                                {/*<Box sx={{mt: 2}}>*/}
                                                    {/*<Text*/}
                                                        {/*size="sm"*/}
                                                        {/*sx={(theme) => ({*/}
                                                            {/*color: theme.other.danger,*/}
                                                        {/*})}*/}
                                                    {/*>*/}
                                                        {/*{errors.submit}*/}
                                                    {/*</Text>*/}
                                                {/*</Box>*/}
                                            {/*)}*/}
                                        </Group>
                                    </Group>
                                </Grid.Col>
                                <Grid.Col xs={12} md={2}>

                                </Grid.Col>
                            </Grid>
                        </Form>
                    )}
                </Formik>

                {isLoading && <ReportCardLoader/>}

                <PendingList pendingListData={pendingListData}/>
                    </Tabs.Panel>

                    <Tabs.Panel value="approved" pt="xs">
                        <Formik
                            initialValues={{
                                search_term: "",
                                submit: null,
                            }}
                            validationSchema={Yup.object().shape({
                                 search_term: Yup.string().required('Please Enter Search'),
                            })}
                            onSubmit={handleApprovedSubmit}
                        >
                            {(formik) => (
                                <Form>
                                    <Grid sx={{marginTop: '30px'}}>
                                        <Grid.Col xs={12} md={3}>
                                            <TextFieldInput formik={formik} id="search_term" placeholder={'Search'}/>
                                        </Grid.Col>
                                        <Grid.Col xs={12} md={2} sx={{marginTop: '-25px'}}>
                                            <Group>
                                                <Group mt="xl">

                                                    <Button type="submit" fullWidth> Show </Button>

                                                    {/*{errors.submit && (*/}
                                                    {/*<Box sx={{mt: 2}}>*/}
                                                    {/*<Text*/}
                                                    {/*size="sm"*/}
                                                    {/*sx={(theme) => ({*/}
                                                    {/*color: theme.other.danger,*/}
                                                    {/*})}*/}
                                                    {/*>*/}
                                                    {/*{errors.submit}*/}
                                                    {/*</Text>*/}
                                                    {/*</Box>*/}
                                                    {/*)}*/}
                                                </Group>
                                            </Group>
                                        </Grid.Col>
                                        <Grid.Col xs={12} md={2}>

                                        </Grid.Col>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                        <ApprovedList approvedListData={approvedListData}/>


                    </Tabs.Panel>


                </Tabs>
            </MainCard>

        </>
    );
};

export default Wps;

import { Box,  Stack, Text } from "@mantine/core";
import React from "react";
import { TUserProfile } from "../../typings";
import InfoRow from "./InfoRow";

const UserDetails = ({ userProfile }: { userProfile: TUserProfile }) => {
  const {
    QID,
    QID_EXP,
    VISA_TYPE,
    PASSPORT,
    PASSPORT_EXP,
    EMP_GSM,
    EMP_EMAIL1,
    EMP_ADDRS1,
    EMP_ADDRS2,
    EMP_ADDRS3,
  } = userProfile.P_EmpOfficalInfo[0];

  const {
    EMP_BIRTH_DT,
    COUNTRY_NAME,
    EMP_GSM: personalNumber,
    HEALTH_CARD_NO,
    HEALTH_CARD_EXP,
    EMP_NATIVE_ADDRS1,
    EMP_NATIVE_ADDRS2,
    EMP_NATIVE_ADDRS3,
  } = userProfile.P_EmpPersonalInfo[0];

  const { BANK_NAME, EMP_BANK_ACNT_NO } = userProfile.P_EmpBank[0];

  const vaccines = userProfile.P_EmpVaccine.map(
    (vaccine) => vaccine.VACCINE_DTL ?? ""
  );

  return (
    <Stack>
      <Box>
        <Text
          sx={(theme) => ({ color: theme.colors.gray[7] })}
          size="lg"
          my="sm"
        >
          Official Information
        </Text>
        <InfoRow
          title="Qatar ID"
          values={[QID ?? "-"]}
          badgeValue={{
            color: "green",
            value: QID_EXP ?? "-",
          }}
        />
        <InfoRow title="Visa Type" values={[VISA_TYPE ?? "-"]} />
        <InfoRow
          title="Passport No"
          values={[PASSPORT ?? "-"]}
          badgeValue={{
            color: "orange",
            value: PASSPORT_EXP ?? "-",
          }}
        />
        <InfoRow title="Mobile Number" values={[EMP_GSM ?? "-"]} />
        <InfoRow title="Email" values={[EMP_EMAIL1 ?? "-"]} />
        <InfoRow
          title="Residential Address"
          values={[EMP_ADDRS1 ?? "-", EMP_ADDRS2 ?? "-", EMP_ADDRS3 ?? "-"]}

        />
      </Box>
      <Box>
        <Text
          sx={(theme) => ({ color: theme.colors.gray[7] })}
          size="lg"
          my="sm"
        >
          Personal Information
        </Text>
        <InfoRow title="Date of Birth" values={[EMP_BIRTH_DT ?? "-"]} />
        <InfoRow title="Nationality" values={[COUNTRY_NAME ?? "-"]} />
        <InfoRow title="Mobile Number" values={[personalNumber ?? "-"]} />
        <InfoRow
          title="Insurance No"
          values={["-"]}
          badgeValue={{
            color: "green",
            value: "-",
            // value: PASSPORT_EXP
            //   ? dayjs(PASSPORT_EXP).format("DD-MMM-YYYY")
            //   : "-",
          }}
        />
        <InfoRow
          title="Health Card No"
          values={[HEALTH_CARD_NO ?? "-"]}
          badgeValue={{
            color: "orange",
            value: HEALTH_CARD_EXP ?? "-",
          }}
        />
        <InfoRow title="Vaccine" values={vaccines} />
        <InfoRow
          title="Home Address"
          values={[
            EMP_NATIVE_ADDRS1 ?? "-",
            EMP_NATIVE_ADDRS2 ?? "-",
            EMP_NATIVE_ADDRS3 ?? "-",
          ]}
        />
      </Box>
      <Box>
        <Text
          sx={(theme) => ({ color: theme.colors.gray[7] })}
          size="lg"
          my="sm"
        >
          Assets
        </Text>
        {userProfile.P_EmpAssets.map((asset) => (
          <InfoRow
            key={`${asset.ASSET_CODE}-${asset.ASSET_CR_DT}`}
            title={asset.ASSET_CODE ?? ""}
            values={[asset.ASSET_DESCRIPTION ?? "-"]}
            footerValue={asset.ASSET_CR_DT ?? "-"}
          />
        ))}
      </Box>
      <Box>
        <Text
          sx={(theme) => ({ color: theme.colors.gray[7] })}
          size="lg"
          my="sm"
        >
          Education
        </Text>
        {userProfile.P_EmpEdu.map((edu) => (
          <React.Fragment key={`${edu.EED_DOC_CODE}-${edu.EMP_CODE}`}>
            <InfoRow title="Qualification" values={[edu.CRSE_NAME ?? "-"]} />
            <InfoRow title="Field of Study" values={[edu.CS_SUB_NAME ?? "-"]} />
          </React.Fragment>
        ))}
      </Box>
      <Box>
        <Text
          sx={(theme) => ({ color: theme.colors.gray[7] })}
          size="lg"
          my="sm"
        >
          Bank Details
        </Text>
        <InfoRow title="Bank Name" values={[BANK_NAME ?? "-"]} />
        <InfoRow title="Account No" values={[EMP_BANK_ACNT_NO ?? "-"]} />
      </Box>
    </Stack>
  );
};

export default UserDetails;

type TParamValue = Record<string, string | number>;

export const stringifyParamValues = (
  paramValues: Record<string, TParamValue>,
  paramType: string
) => {
  const nonEmptyValues = Object.values(paramValues).filter((value) =>
    Object.values(value).some((val) => val !== "")
  );
  const P_Json_Data = JSON.stringify({
    [paramType]: nonEmptyValues,
  });
  return P_Json_Data;
};
export const stringifyArrayParamValues = (
  paramValues: TParamValue[],
  paramType: string
) => {
  const nonEmptyValues = paramValues.filter((value) =>
    Object.values(value).some((val) => val !== "")
  );
  const P_Json_Data = JSON.stringify({
    [paramType]: nonEmptyValues,
  });
  return P_Json_Data;
};

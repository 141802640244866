import {
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  Switch,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconFile, IconReportAnalytics } from "@tabler/icons";
import { useEffect, useState } from "react";
import { TReportCheckedColumns } from "../../typings";

type TProps = {
  isModalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tableColumns: TReportCheckedColumns;
  handleGenerateReport: ({
    checkedColumns,
    selectedDocs,
  }: {
    checkedColumns: TReportCheckedColumns;
    selectedDocs: {
      pdf: boolean;
      excel: boolean;
    };
  }) => void;
};

const OptionsToPrintReportModal = ({
  isModalOpen,
  setModalOpen,
  tableColumns,
  handleGenerateReport,
}: TProps) => {
  const [selectedDocs, setSelectedDocs] = useState({
    pdf: true,
    excel: true,
  });
  const [checkedColumns, setCheckedColumns] = useState<TReportCheckedColumns>(
    {}
  );

  useEffect(() => {
    if (Object.keys(tableColumns).length) setCheckedColumns(tableColumns);
  }, [setCheckedColumns, tableColumns]);

  const handleSelectColumn = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const { checked } = e.currentTarget;
    setCheckedColumns((prev) => ({
      ...prev,
      [name]: { ...prev[name], isChecked: checked },
    }));
  };

  const handleSelectDoc = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: "excel" | "pdf"
  ) => {
    const { checked } = e.currentTarget;
    setSelectedDocs((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const selectColumnsToPrint = (
    <>
      <Text size="sm" mb="md">
        Select columns to print
      </Text>
      <Grid gutter={35}>
        {Object.entries(checkedColumns).map(([name, value]) => (
          <Grid.Col xs={12} md={6} key={name}>
            <Group position="apart">
              <Group>
                <ThemeIcon>
                  <IconReportAnalytics size={20} />
                </ThemeIcon>
                <Text>{value.label}</Text>
              </Group>
              <Switch
                checked={value.isChecked as boolean}
                onChange={(e) => {
                  handleSelectColumn(e, name);
                }}
                aria-label={name}
              />
            </Group>
          </Grid.Col>
        ))}
      </Grid>
    </>
  );

  const selectDocTypesToExport = (
    <>
      <Text size="sm" mb="md">
        Select doc types to export
      </Text>
      <Grid gutter={35}>
        <Grid.Col xs={12} md={6}>
          <Group position="apart">
            <Group>
              <ThemeIcon>
                <IconFile size={20} />
              </ThemeIcon>
              <Text>PDF</Text>
            </Group>
            <Switch
              checked={selectedDocs.pdf}
              onChange={(e) => handleSelectDoc(e, "pdf")}
              aria-label="pdf"
            />
          </Group>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Group position="apart">
            <Group>
              <ThemeIcon>
                <IconFile size={20} />
              </ThemeIcon>
              <Text>Excel</Text>
            </Group>
            <Switch
              checked={selectedDocs.excel}
              onChange={(e) => handleSelectDoc(e, "excel")}
              aria-label="excel"
            />
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setModalOpen(false)}
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
      size="50%"
      zIndex={500}
    >
      {selectColumnsToPrint}
      <Divider my="xl" />
      {selectDocTypesToExport}
      <Button
        mt="xl"
        disabled={
          (!selectedDocs.pdf && !selectedDocs.excel) ||
          Object.values(checkedColumns).every((value) => !value.isChecked)
        }
        onClick={() => {
          const isAllColumnsNotChecked = Object.values(checkedColumns).every(
            (value) => !value.isChecked
          );
          if (isAllColumnsNotChecked) return;
          handleGenerateReport({ selectedDocs, checkedColumns });
          setModalOpen(false);
        }}
      >
        Export
      </Button>
    </Modal>
  );
};

export default OptionsToPrintReportModal;

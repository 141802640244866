import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import {
  TReportTypeValues,
  TInputField,
  TReportTypeConfig,
  TWizardData,
} from "../../typings";
import {
  reportTypeInputFields,
  uiSettingsInputFieldValues,
} from "../../Formik/FormFields";
import { api } from "../../../../../api";
import { Alert, Box, Button, Grid, Group, Text } from "@mantine/core";
import TextFieldInput from "../../../../../components/TextFieldInput";
import SelectDropdown from "../../../../../components/SelectDropdown";
import MainCard from "../../../../../components/Cards/MainCard";
import {
  IconDeviceFloppy,
  IconCornerDownRight,
  IconCircleX,
  IconAlertCircle,
} from "@tabler/icons";
import { showCustomNotification } from "../../../../../helper/customNotification";
import {
  generateRandomString,
  isOracleError,
} from "../../../../../helper/common";
import { useQueryClient } from "@tanstack/react-query";
import ProcInput from "./ProcInput";
import { TProcParameter } from "..";

type TProps = {
  authUser: any;
  next: () => void;
  reportInitialValues: TReportTypeValues;
  setWizardData: React.Dispatch<React.SetStateAction<TWizardData>>;
  handleWizardClose: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedReportType: TReportTypeValues | undefined;
  setSelectedReportType: React.Dispatch<
    React.SetStateAction<TReportTypeValues | undefined>
  >;
  existingReportNames: string[];
  reportProcedureName: string;
  setReportProcedureName: React.Dispatch<React.SetStateAction<string>>;
  isProcParamsLoading: boolean;
  argParameters: TProcParameter[] | undefined;
  wizardData: TWizardData;
  fetchedProcedure: string;
  setFetchedProcedure: React.Dispatch<React.SetStateAction<string>>;
  procArgChange: string;
  setProcArgChanged: React.Dispatch<React.SetStateAction<string>>;
};

const StepReportType = ({
  authUser,
  next,
  reportInitialValues,
  setWizardData,
  handleWizardClose,
  setLoading,
  selectedReportType,
  setSelectedReportType,
  existingReportNames,
  reportProcedureName,
  setReportProcedureName,
  isProcParamsLoading,
  argParameters,
  wizardData,
  fetchedProcedure,
  setFetchedProcedure,
  procArgChange,
  setProcArgChanged,
}: TProps) => {
  const [clearProcParamsField, triggerClearProcParamsField] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const updateParamsWithProcedure = (reportProc: string) => {
    //update reportParams with argumentParams
    const repParams = wizardData.reportParams;
    const procParamsArray = reportProc.match(/(?<=:).*?(?=,|\))/g);
    const repParamsArray = repParams ? Object.values(repParams) : [];
    if (procParamsArray) {
      const paramsToUpdate = procParamsArray.reduce((acc, curr) => {
        const isParamExist = repParamsArray.some(
          (p) => p.param.toUpperCase() === curr.toUpperCase()
        );
        if (!isParamExist) {
          const argData = argParameters?.find((p) => p.ARGUMENT_NAME === curr);
          const id = generateRandomString(6);
          let newParam = { ...uiSettingsInputFieldValues, param: curr };
          if (argData) {
            newParam = {
              ...newParam,
              type: argData.IN_OUT,
              data_type: argData.DATA_TYPE,
              order: argData.SLNO,
            };
          }

          return { ...acc, [id]: newParam };
        }
        return acc;
      }, {});
      const existingParamsWithId = repParamsArray.reduce((acc, curr) => {
        if (curr.param_id === "" && !procParamsArray.includes(curr.param)) {
          return acc;
        }
        const id = generateRandomString(6);
        return { ...acc, [id]: curr };
      }, {});
      setWizardData((prev) => ({
        ...prev,
        reportParams: {
          ...existingParamsWithId,
          ...paramsToUpdate,
        },
      }));
    }
  };

  const handleAddReport = async (
    values: TReportTypeValues,
    { setSubmitting }: any
  ) => {
    try {
      setLoading(true);
      if (selectedReportType) {
        const payload = {
          P_Cr_User: authUser.user,
          reportId: values.reportId,
          reportName: values.reportName,
          reportStatus: values.reportStatus,
          reportProcedure: values.reportProcedure,
        };
        const response = await api.post(
          "customReport/update",
          undefined,
          payload
        );
        // console.log(response);
        if (!response.status) {
          throw new Error(response.message);
        }
        const updatedReport = {
          ...selectedReportType,
          reportName: values.reportName,
          reportStatus: values.reportStatus,
          reportProcedure: values.reportProcedure,
        };
        setWizardData((prev) => ({
          ...prev,
          reportType: {
            ...selectedReportType,
            reportName: values.reportName,
            reportStatus: values.reportStatus,
            reportProcedure: values.reportProcedure,
          },
        }));
        setSelectedReportType(updatedReport);
        queryClient.setQueryData<TReportTypeValues[]>(
          ["uiConfig", "reportList"],
          (oldData) => {
            if (!oldData) return [updatedReport];
            const repIndex = oldData?.findIndex(
              (rep) => rep.reportId === selectedReportType?.reportId
            );
            if (repIndex === -1) return [updatedReport];
            return [
              ...oldData.slice(0, repIndex),
              updatedReport,
              ...oldData.slice(repIndex + 1),
            ];
          }
        );
        // setLoading(false);
      } else {
        const payload = {
          P_Cr_User: authUser.user,
          P_Rep_Name: values.reportName,
          P_Rep_Sp: values.reportProcedure,
          P_Status: values.reportStatus,
        };
        const response = await api.post("customReport/new", undefined, payload);
        // console.log(response);
        if (!response.status) {
          throw new Error(response.message);
        }
        const reportType = {
          reportId: response.data.P_Rep_Id,
          reportName: values.reportName,
          reportStatus: values.reportStatus,
          reportProcedure: values.reportProcedure,
        };
        setWizardData((prev) => ({
          ...prev,
          reportType,
        }));
        queryClient.setQueryData<TReportTypeValues[]>(
          ["uiConfig", "reportList"],
          (oldData) => {
            if (!oldData) return [reportType];
            return [...oldData, reportType];
          }
        );
        queryClient.setQueryData<TReportTypeConfig>(
          ["uiConfig", "reportType", reportType.reportId],
          () => {
            return {
              reportParams: [],
              reportSubParams: null,
              reportOutParams: [],
            };
          }
        );
        setSelectedReportType(reportType);
      }
      setProcArgChanged("");
      updateParamsWithProcedure(values.reportProcedure);
      // else {
      //   const argData = argParameters?.reduce((acc, curr) => {
      //     const id = generateRandomString(6);
      //     const newParam = {
      //       ...uiSettingsInputFieldValues,
      //       param: curr.ARGUMENT_NAME,
      //       type: curr.IN_OUT,
      //       data_type: curr.DATA_TYPE,
      //       order: curr.SLNO,
      //     };
      //     return { ...acc, [id]: newParam };
      //   }, {});
      //   console.log("noParams",wizardData)
      //   console.log("noParams",argData)
      //   setWizardData((prev) => ({
      //     ...prev,
      //     reportParams: {
      //       ...prev.reportParams,
      //       ...argData,
      //     },
      //   }));
      // }
      queryClient.cancelQueries(["reportGeneration-types"]);
      queryClient.invalidateQueries(["reportGeneration-types"]);
      setSubmitting(false);
      next();
    } catch (error: any) {
      // console.log(error);
      if (isOracleError(error.message)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Oracle Error",
            message: error.message,
            type: "ORACLE_ERROR",
          },
        });
      } else {
        showCustomNotification({
          title: "Error",
          message: error.message,
          notifyType: "ERROR",
        });
      }
      setLoading(false);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setFetchedProcedure(wizardData.reportType.reportProcedure);
    setReportProcedureName("");
    triggerClearProcParamsField((prev) => !prev);
  }, [
    setFetchedProcedure,
    setReportProcedureName,
    wizardData.reportType.reportProcedure,
  ]);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const formGrid = (
    inputField: TInputField,
    formik: FormikProps<TReportTypeValues>
  ) => {
    switch (inputField.fieldType) {
      case "TXT": {
        return (
          <Grid.Col key={inputField.fieldName} xs={12}>
            <TextFieldInput
              id={inputField.fieldName}
              label={inputField.fieldLabel}
              formik={formik}
              disabled={formik.isSubmitting}
            />
          </Grid.Col>
        );
      }
      case "DRDWN": {
        return (
          <Grid.Col key={inputField.fieldName} xs={12}>
            <SelectDropdown
              id={inputField.fieldName}
              label={inputField.fieldLabel}
              options={inputField.fieldOptions}
              formik={formik}
              disabled={formik.isSubmitting}
              isClearable={false}
            />
          </Grid.Col>
        );
      }
      case "PROC_COMPONENT": {
        return (
          <Grid.Col key={inputField.fieldName} xs={12}>
            {/* <MainCard> */}
            <Grid gutter="sm">
              <ProcInput
                formik={formik}
                fieldName={inputField.fieldName}
                clearFields={clearProcParamsField}
                initialProc={fetchedProcedure}
                // initialProc={
                //   fetchedProcedure || reportInitialValues.reportProcedure
                // }
                setFetchedProcedure={setFetchedProcedure}
                reportProcedureName={reportProcedureName}
                setReportProcedureName={setReportProcedureName}
                isProcParamsLoading={isProcParamsLoading}
              />
            </Grid>
            {/* </MainCard> */}
          </Grid.Col>
        );
      }
      default:
        return (
          <Grid.Col key={inputField.fieldName} xs={12}>
            <MainCard>FORM GRID ERROR</MainCard>
          </Grid.Col>
        );
    }
  };

  const reportTypeValidationSchema = Yup.object().shape({
    reportName: Yup.string()
      .required("Required")
      .notOneOf(existingReportNames, "Report name already exist"),
    reportProcedure: Yup.string().required("Required"),
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Formik
        enableReinitialize={true}
        initialValues={reportInitialValues}
        validationSchema={reportTypeValidationSchema}
        onSubmit={handleAddReport}
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid gutter="sm">
              <Grid.Col xs={12} my={2} px={2}>
                <Text align="center">
                  Here you can enter the details of report type.
                </Text>
              </Grid.Col>
              {selectedReportType && (
                <Grid.Col
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    variant="outline"
                    onClick={() => {
                      updateParamsWithProcedure(
                        wizardData.reportType.reportProcedure
                      );
                      setReportProcedureName("");
                      setFetchedProcedure(
                        wizardData.reportType.reportProcedure
                      );
                      next();
                    }}
                  >
                    Skip
                  </Button>
                </Grid.Col>
              )}
              {selectedReportType && procArgChange && (
                <Grid.Col xs={12} my={2} px={2}>
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    title="Change in Procedure found."
                    color="yellow"
                  >
                    <Box>
                      <Text>Old procedure :</Text>
                      <Text sx={{ color: "red" }}>
                        {wizardData.reportType.reportProcedure}
                      </Text>
                    </Box>
                    <Box>
                      <Text>New procedure :</Text>
                      <Text sx={{ color: "red" }}>{procArgChange}</Text>
                    </Box>
                    <Text>
                      Procedure from the database does not match the procedure
                      saved with current report type. To avoid conflict please
                      click Save & Next.
                    </Text>
                  </Alert>
                </Grid.Col>
              )}
              {reportTypeInputFields.map((inputField) =>
                formGrid(inputField, formik)
              )}
              <Grid.Col xs={12}>
                <Group spacing="sm" position="right">
                  <Button
                    variant="outline"
                    fullWidth
                    sx={{ maxWidth: "13rem" }}
                    leftIcon={<IconCornerDownRight />}
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.resetForm();
                      setFetchedProcedure(
                        wizardData.reportType.reportProcedure
                      );
                      setReportProcedureName("");
                      triggerClearProcParamsField((prev) => !prev);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outline"
                    fullWidth
                    sx={{ maxWidth: "13rem" }}
                    leftIcon={<IconCircleX />}
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.resetForm();
                      triggerClearProcParamsField((prev) => !prev);
                      handleWizardClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={formik.isSubmitting}
                    leftIcon={<IconDeviceFloppy />}
                    variant="filled"
                    fullWidth
                    sx={{ maxWidth: "13rem" }}
                    type="submit"
                  >
                    Save & Next
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default StepReportType;

import { api } from "../../api";
import {ValidatedResponseProps } from "./types";

export async function uploadExcelForValidation(request: FormData) {
  try {
      return await Promise.resolve(  api.post("finance/upload/excel", undefined, request));
  } catch (error) {
    return error;
  }
}

export async function getValidatedData(
  fileRefId: string
): Promise<ValidatedResponseProps> {
  try {
      return await Promise.resolve(   api.get(`finance/${fileRefId}`));

  } catch (error) {
    throw error;
  }
}

export async function uploadFinancialEntry(refId: string) {
  try {
      return await Promise.resolve( api.post(`finance/${refId}`, undefined, {}));
  } catch (error) {
    return error;
  }
}

import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {api} from "../../../../api";
import {Loader, TextInput, useMantineTheme} from "@mantine/core";
import {IconLetterCase} from "@tabler/icons";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {TReportControls, TReportInitialValues} from "../../typings";
import {FormikErrors, FormikTouched} from "formik";
import {showCustomNotification} from "../../../../helper/customNotification";
import {isOracleError} from "../../../../helper/common";

type TProps = {
    value: string;
    label: string;
    reportCtrl: TReportControls;
    authUser: any;
    formValues: TReportInitialValues;
    reportControls: TReportControls[];
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void;
    errors: FormikErrors<TReportInitialValues>;
    touched: FormikTouched<TReportInitialValues>;
    setInitialValues: React.Dispatch<React.SetStateAction<TReportInitialValues>>;
};

const Textfield = ({
                       value,
                       label,
                       reportCtrl,
                       authUser,
                       formValues,
                       reportControls,
                       setFieldValue,
                       errors,
                       touched,
                       setInitialValues,
                   }: TProps) => {
    // const [field, meta] = useField(reportCtrlUpper);
    // const [isLoading, setLoading] = useState(false);
    const [queryId, setQueryId] = useState<Record<string, { label: string; value: string }>>({});
    const dispatch = useDispatch();
    const theme = useMantineTheme();

    const queryClient = useQueryClient();
    const reportCtrlUpper = (reportCtrl.PRAM_NAME + "").toUpperCase();

    const getDynamicTextValue = async () => {
        // if (!reportCtrl.BIND_PARAM)
        //   throw new Error(`Bind parameter of ${reportCtrl.LABEL_TXT} is empty`);
        let ProcParams = {};
        let parsedBindParam = [];
        if (reportCtrl.BIND_PARAM) {
            parsedBindParam = JSON.parse(reportCtrl.BIND_PARAM);
            for (const el of parsedBindParam) {
                const paramUpper = (el.param + "").toUpperCase();
                if (el.type === "IN") {
                    // if (paramUpper === "P_COMP_CODE") {
                    //     ProcParams = {...ProcParams, P_COMP_CODE: authUser.company_code};
                    //     continue;
                    // }
                    // console.log( authUser)
                   // console.log( authUser.def_group)
                    if (paramUpper === "P_COMP_GROUP") {
                        ProcParams = {...ProcParams, P_COMP_GROUP: authUser.def_group};
                        continue;
                    }


                    if (paramUpper === "P_USER") {
                        ProcParams = {...ProcParams, P_USER: authUser.user};
                        continue;
                    }
                    if (paramUpper !== reportCtrlUpper)
                        ProcParams = {
                            ...ProcParams,
                            [paramUpper]: formValues[paramUpper].value,
                        };
                    else ProcParams = {...ProcParams, [reportCtrlUpper]: null};
                }
            }
        }
        const payload = {
            proc: reportCtrl.BIND_VALUE,
            params: parsedBindParam,
            param_values: ProcParams,
        };
        const response = await api.post(
            "report/custom/options",
            undefined,
            payload
        );
        if (!response.status) {
            throw new Error(response.message);
        }
        if (queryId?.parent?.value === "noParent") {
            setInitialValues((prev: any) => ({
                ...prev,
                [reportCtrlUpper]: {
                    value: response.data,
                    label: response.data,
                },
            }));
        } else {
            setFieldValue(reportCtrlUpper, {
                value: response.data,
                label: response.data,
            });
        }
        return response.data;
    };

    const {
        isFetching,
        // data: dynamicValue,
        refetch,
    } = useQuery<string, Error>(
        ["reportGeneration", "dynamicControlValues", reportCtrlUpper, queryId],
        getDynamicTextValue,
        {
            enabled: !!Object.keys(queryId).length,
            staleTime: 0,
            // cacheTime: 0,

            onError: (error) => {
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: error.message },
                // });
                if (isOracleError(error.message)) {
                    dispatch({
                        type: "OPEN_ALERT_MODAL",
                        payload: {
                            title: "Oracle Error",
                            message: error.message,
                            type: "ORACLE_ERROR",
                        },
                    });
                } else {
                    showCustomNotification({
                        title: "Error",
                        message: `Error occured in ${reportCtrl.LABEL_TXT}: mostly due to incorrect data provided. ${error.message}`,
                        notifyType: "ERROR",
                    });
                }
            },
        }
    );

    const [parentControls, setParentControls] = useState<any>({});
    // if dynamic and have parent then set parentControls, or getDynamicValue
    useEffect(() => {
        if (reportCtrl.BIND_TYPE === "STATIC") {
            // setFieldValue(reportCtrlUpper, {
            //   value: reportCtrl.BIND_VALUE,
            //   label: reportCtrl.BIND_VALUE,
            // });
            setInitialValues((prev: any) => ({
                ...prev,
                [reportCtrlUpper]: {
                    value: reportCtrl.BIND_VALUE ? reportCtrl.BIND_VALUE : "",
                    label: reportCtrl.BIND_VALUE ? reportCtrl.BIND_VALUE : "",
                },
            }));
            return;
        }
        if (reportCtrl.PARENT_PARAM_ID) {
            const parentParamIds = JSON.parse(reportCtrl.PARENT_PARAM_ID);
            parentParamIds.forEach((id: any) => {
                for (const ctrl of reportControls) {
                    if (ctrl.PRAM_ID === id) {
                        const pramNameUpper = (ctrl.PRAM_NAME + "").toUpperCase();
                        setParentControls((prev: any) => ({
                            ...prev,
                            [pramNameUpper]: formValues[pramNameUpper],
                        }));
                        break;
                    }
                }
            });
            return;
        }
        // getDynamicTextValue(true);
        setQueryId({parent: {label: "noParent", value: "noParent"}});
    }, []);

    // for dynamic when parent value change we need to update children
    useEffect(() => {
        if (reportCtrl.BIND_TYPE === "DYNAMIC") {
            // no need to call proc if there's parent dropDown and it's not selected
            if (reportCtrl.PARENT_PARAM_ID) {
                let newParentControls = {};
                for (const parent in parentControls) {
                    const upperParent = (parent + "").toUpperCase();
                    newParentControls = {
                        ...newParentControls,
                        [upperParent]: formValues[upperParent],
                    };
                }
                if (
                    JSON.stringify(newParentControls) === JSON.stringify(parentControls)
                )
                    return;
                else setParentControls(newParentControls);
                setFieldValue(reportCtrlUpper, {
                    value: null,
                    label: null,
                });
                let ctrlParents: Record<string, { label: string; value: string }> = {};
                for (const parent in newParentControls) {
                    const parentFound = Object.entries(formValues).find(
                        (value) =>
                            (value[0] + "").toUpperCase() === (parent + "").toUpperCase()
                    ) as [string, { label: string; value: string }];
                    if (!parentFound || !(parentFound[1] && parentFound[1].value)) {
                        // setFieldValue(reportCtrlUpper, {
                        //   value: null,
                        //   label: null,
                        // });
                        queryClient.cancelQueries([
                            "reportGeneration",
                            "dynamicControlValues",
                            reportCtrlUpper,
                        ]);
                        return;
                    }
                    ctrlParents = {...ctrlParents, [parentFound[0]]: parentFound[1]};
                }
                // getDynamicTextValue();
                setQueryId(ctrlParents);
                if (!isFetching) refetch();
            }
        }
    }, [formValues]);

    return (
        <TextInput
            label={label}
            value={value}
            onChange={(event) =>
                setFieldValue(reportCtrlUpper, {
                    value: event.currentTarget.value,
                    label: event.currentTarget.value,
                })
            }
            error={
                !!(touched[reportCtrlUpper] && errors[reportCtrlUpper]) &&
                errors[reportCtrlUpper]?.value
            }
            rightSection={isFetching ? <Loader size="sm" variant="dots"/> : null}
            icon={<IconLetterCase size={20} color={theme.colors.ffcBrand[6]}/>}
        />
    );
};

export default Textfield;

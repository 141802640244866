import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { ActionIcon, Box, Button, Stack, Text, TextInput } from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons";
import { api } from "../../../api";

type SubmitType = {
  username: string;
  password: string;
  submit: boolean | null;
};

type TProps = { urlParam: string };

const AuthForm = ({ urlParam }: TProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (
    values: SubmitType,
    { setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      const payload = {
        username: values.username,
        password: values.password,
      };
      const resp = await api.post("auth/login", undefined, payload);
      if (resp.status) {
        dispatch({
          type: "LOGIN",
          payload: resp.data,
        });
        if (urlParam) {
          navigate(`/downloads${urlParam}`);
        } else navigate("/");
      }
      setStatus({ success: false });
      setErrors({ submit: resp.message });
      setSubmitting(false);
    } catch (error: any) {

      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          username: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().max(255).required("Username is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          try {
            handleLogin(values, { setErrors, setStatus, setSubmitting });
          } catch (err: any) {
            // console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form>
            <Stack align="center">
              <TextInput
                placeholder="Your username"
                label="Username"
                error={
                  !!(touched.username && errors.username) && errors.username
                }
                required
                value={values.username}
                onChange={(e) =>
                  setFieldValue("username", e.currentTarget.value)
                }
                onBlur={handleBlur}
                sx={{ width: "100%" }}
                disabled={isSubmitting}
                // icon={<IconUser color={theme.colors.ffcBrand[6]} />}
              />
              <TextInput
                placeholder="Your password"
                label="Password"
                error={
                  !!(touched.password && errors.password) && errors.password
                }
                required
                value={values.password}
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("password", e.currentTarget.value)
                }
                type={showPassword ? "text" : "password"}
                rightSection={
                  <ActionIcon
                    disabled={isSubmitting}
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <IconEye /> : <IconEyeOff />}
                  </ActionIcon>
                }
                sx={{ width: "100%" }}
                disabled={isSubmitting}
                // icon={<IconLock color={theme.colors.ffcBrand[6]} />}
              />
              <Button
                fullWidth
                type="submit"
                loading={isSubmitting}
                loaderPosition="right"
              >
                Login
              </Button>
              {errors.submit && (
                <Box sx={{ mt: 2 }}>
                  <Text
                    size="sm"
                    sx={(theme) => ({
                      color: theme.other.danger,
                    })}
                  >
                    {errors.submit}
                  </Text>
                </Box>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AuthForm;

type TInitialState = {
  reports: Record<
    string,
    { id: string; reportName: string; reportDetails: string; message: string }
  >;
  inProgressCount: number;
};
const initialState: TInitialState = {
  reports: {},
  inProgressCount: 0,
};

const reportGenerationReducer = (state = initialState, action:any ={}) => {
  switch (action.type) {
    case "START":
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.id]: {
            ...action.payload,
            status: "inProgress",
          },
        },
        inProgressCount: state.inProgressCount + 1,
      };
    case "SUCCESS":
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.id]: {
            ...action.payload,
            status: "success",
          },
        },
        inProgressCount: state.inProgressCount - 1,
      };
    case "FAILED":
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.id]: {
            ...action.payload,
            status: "error",
          },
        },
        inProgressCount: state.inProgressCount - 1,
      };
    default:
      return state;
  }
};

export default reportGenerationReducer;

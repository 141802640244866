import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Drawer,
  Group,
  ThemeIcon,
  Text,
  Stack,
  Indicator,
  ActionIcon,
} from "@mantine/core";
import { IconClipboardX,  IconReportSearch } from "@tabler/icons";
import HistoryListItem from "./HistoryListItem";

export type TReport = {
  id: string;
  reportName: string;
  reportDetails: string;
  status: "success" | "error" | "inProgress";
  // inProgressCount: number;
  message: string;
};

type TReportState = {
  reports: Record<string, TReport>;
  inProgressCount: number;
};

const GenerationHistory = () => {
  const { reports, inProgressCount }: TReportState = useSelector(
    (state: any) => state.reportGeneration
  );
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Indicator
        disabled={!inProgressCount}
        sx={{ position: "fixed", bottom: 30, right: 30, zIndex: 500 }}
      >
        <ActionIcon
          onClick={() => setOpened(true)}
          // sx={{ position: "fixed", bottom: 30, right: 30, zIndex: 200 }}
          variant="light"
          color="indigo"
          size="lg"
        >
          <IconReportSearch size={30} />
        </ActionIcon>
      </Indicator>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        title="Report Generations"
        padding="md"
        size="xl"
        position="right"
        zIndex={500}
      >
        <Stack sx={{ maxHeight: "90vh", overflow: "auto" }}>
          {!!Object.keys(reports).length ? (
            Object.entries(reports).map(([id, report]) => (
              <HistoryListItem report={report} key={id} />
            ))
          ) : (
            <Group>
              <ThemeIcon variant="light">
                <IconClipboardX />
              </ThemeIcon>
              <Text>No reports generated</Text>
            </Group>
          )}
        </Stack>
      </Drawer>
    </>
  );
};

export default GenerationHistory;

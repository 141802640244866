import {
  TDependentFieldsInputFieldValues,
  TInputField,
  TUiSettingsInputFieldValues,
} from "../typings";

// Report Type
export const reportTypeInputFields: TInputField[] = [
  {
    fieldType: "TXT",
    fieldLabel: "Report Name",
    fieldName: "reportName",
    fieldOptions: null,
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Report Status",
    fieldName: "reportStatus",
    fieldOptions: [
      { label: "Open", value: "O" },
      { label: "Lock", value: "L" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "PROC_COMPONENT",
    fieldLabel: "Report Procedure",
    fieldName: "reportProcedure",
    fieldOptions: null,
    isReadOnly: false,
  },
];

// Ui Settings (Params)
export const uiSettingsInputFields: TInputField[] = [
  {
    fieldType: "DRDWN",
    fieldLabel: "Control Type",
    fieldName: "control_id",
    fieldOptions: null,
    isReadOnly: false,
  },
  {
    fieldType: "TXT",
    fieldLabel: "Field Name",
    fieldName: "param",
    fieldOptions: null,
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Type",
    fieldName: "type",
    fieldOptions: [
      { label: "IN", value: "IN" },
      { label: "OUT", value: "OUT" },
      { label: "IN OUT", value: "IN OUT" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Data Type",
    fieldName: "data_type",
    fieldOptions: [
      { label: "CHAR", value: "CHAR" },
      { label: "NCHAR", value: "NCHAR" },
      { label: "VARCHAR", value: "VARCHAR" },
      { label: "VARCHAR2", value: "VARCHAR2" },
      { label: "NVARCHAR2", value: "NVARCHAR2" },
      { label: "SYS_REFCURSOR", value: "SYS_REFCURSOR" },
      { label: "NUMBER", value: "NUMBER" },
      { label: "DATE", value: "DATE" },
      { label: "CLOB", value: "CLOB" },
      { label: "NCLOB", value: "NCLOB" },
      { label: "LONG", value: "LONG" },
      { label: "BLOB", value: "BLOB" },
      { label: "BFILE", value: "BFILE" },
      { label: "RAW", value: "RAW" },
      { label: "LONG RAW", value: "LONG RAW" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "TXT",
    fieldLabel: "Field Label",
    fieldName: "label",
    fieldOptions: null,
    isReadOnly: false,
  },
  {
    fieldType: "TXT",
    fieldLabel: "Order Number",
    fieldName: "order",
    fieldOptions: null,
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Field Value Type",
    fieldName: "value_type",
    fieldOptions: [
      { label: "STATIC", value: "STATIC" },
      { label: "DYNAMIC", value: "DYNAMIC" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "TXT_AREA",
    fieldLabel: "Field Value Preview",
    fieldName: "parm_val",
    fieldOptions: null,
    isReadOnly: true,
  },
  // {
  //   fieldType: "TXT_AREA",
  //   fieldLabel: "Param Val Json",
  //   fieldName: "parm_val_json",
  // },
  {
    fieldType: "DRDWN",
    fieldLabel: "Is Visible in Ui ?",
    fieldName: "ui_visible",
    fieldOptions: [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Is Mandatory Field ?",
    fieldName: "mandatory_field",
    fieldOptions: [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ],
    isReadOnly: false,
  },
];
export const uiSettingsInputFieldValues: TUiSettingsInputFieldValues = {
  param_id: "",
  control_id: "",
  param: "",
  type: "",
  data_type: "",
  label: "",
  order: "",
  value_type: "",
  parm_val: "",
  // parm_val_json: "",
  ui_visible: "",
  mandatory_field: "",
};

// Dependent Fields (Sub Params)
export const dependentFieldsInputFields: TInputField[] = [
  {
    fieldType: "TXT",
    fieldLabel: "Dependent Field",
    fieldName: "sparam",
    fieldOptions: null,
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Parameter Type",
    fieldName: "stype",
    fieldOptions: [
      { label: "IN", value: "IN" },
      { label: "OUT", value: "OUT" },
      { label: "IN OUT", value: "IN OUT" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Data Type",
    fieldName: "sdata_type",
    fieldOptions: [
      { label: "CHAR", value: "CHAR" },
      { label: "NCHAR", value: "NCHAR" },
      { label: "VARCHAR", value: "VARCHAR" },
      { label: "VARCHAR2", value: "VARCHAR2" },
      { label: "NVARCHAR2", value: "NVARCHAR2" },
      { label: "SYS_REFCURSOR", value: "SYS_REFCURSOR" },
      { label: "NUMBER", value: "NUMBER" },
      { label: "DATE", value: "DATE" },
      { label: "CLOB", value: "CLOB" },
      { label: "NCLOB", value: "NCLOB" },
      { label: "LONG", value: "LONG" },
      { label: "BLOB", value: "BLOB" },
      { label: "BFILE", value: "BFILE" },
      { label: "RAW", value: "RAW" },
      { label: "LONG RAW", value: "LONG RAW" },
    ],
    isReadOnly: false,
  },
];

export const dependentFieldsInputFieldValues: TDependentFieldsInputFieldValues =
  {
    sparam_id: "",
    sparam: "",
    stype: "",
    sdata_type: "",
  };

// Report Header (Out Params)
export const reportHeaderInputFields: TInputField[] = [
  {
    fieldType: "TXT",
    fieldLabel: "Header Name",
    fieldName: "oparam",
    fieldOptions: [],
    isReadOnly: false,
  },
  {
    fieldType: "DRDWN",
    fieldLabel: "Out Data Type",
    fieldName: "odata_type",
    fieldOptions: [
      { label: "CHAR", value: "CHAR" },
      { label: "NCHAR", value: "NCHAR" },
      { label: "VARCHAR", value: "VARCHAR" },
      { label: "VARCHAR2", value: "VARCHAR2" },
      { label: "NVARCHAR2", value: "NVARCHAR2" },
      { label: "SYS_REFCURSOR", value: "SYS_REFCURSOR" },
      { label: "NUMBER", value: "NUMBER" },
      { label: "DATE", value: "DATE" },
      { label: "CLOB", value: "CLOB" },
      { label: "NCLOB", value: "NCLOB" },
      { label: "LONG", value: "LONG" },
      { label: "BLOB", value: "BLOB" },
      { label: "BFILE", value: "BFILE" },
      { label: "RAW", value: "RAW" },
      { label: "LONG RAW", value: "LONG RAW" },
    ],
    isReadOnly: false,
  },
  {
    fieldType: "TXT",
    fieldLabel: "Header Label",
    fieldName: "olabel",
    fieldOptions: [],
    isReadOnly: false,
  },
  {
    fieldType: "TXT",
    fieldLabel: "Header Order",
    fieldName: "oorder",
    fieldOptions: [],
    isReadOnly: false,
  },
];

export const reportHeaderInputFieldValues = {
  oparam_id: "",
  oparam: "",
  odata_type: "",
  olabel: "",
  oorder: "",
};

// export const initialFormikValues = {
//   addReport: {
//     reportName: "",
//     reportStatus: "O",
//     reportProcedure: "",
//     reportParams: "",
//   },
// reportParams: {
//   randomKeyr3n5: paramsInputFieldValue,
// },
// reportSubParams: {
//   randomKeyr4n6: subParamsInputFieldValue,
// },
//   reportOutParams: {
//     randomKeyr5n7: outParamsInputFieldValue,
//   },
// };

import { useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { api } from "../../../api";
import { generateRandomString } from "../../../helper/common";
import { Text, Loader, Paper, Group, Box, ThemeIcon } from "@mantine/core";
import dayjs from "dayjs";
import { IconCircleCheck } from "@tabler/icons";

type TProps = {
  doc: any;
  // setOngoingExportCount: any; // for badge count
};

const GenerationMenuItem = ({ doc }: TProps) => {
  const { authUser }: RootStateOrAny = useSelector((state: any) => state.auth);
  const [isLoadingSingleReport, setLoadingSingleReport] =
    useState<boolean>(false); // for loading icon
  const reportRef = useRef<Record<string, any>>();
  const dispatch = useDispatch();

  const getProgressDetails = () => {
    const id = generateRandomString(6);
    const labels = Object.values(JSON.parse(doc.CRLOG_DISP_PARAM));
    const progressDetails = {
      id,
      reportName: `${doc.CREP_NAME} (from report logs)`,
      reportDetails: labels.join(" | "),
      message: "",
    };
    return progressDetails;
  };

  const exportPdfByGeneratingReport = async () => {
    try {
      // setOngoingExportCount((prev: number) => prev + 1);
      setLoadingSingleReport(true);
      const progressDetails = getProgressDetails();
      reportRef.current = progressDetails;
      dispatch({ type: "START", payload: progressDetails });
      toast(`Started to generate ${progressDetails.reportName}`, {
        icon: "📄",
        duration: 6000,
        style: {
          border: "1px solid #252b83",
          padding: "10px",
          backgroundColor: "#252b83",
          color: "#FFFAEE",
        },
      });
      let bind_values = {
        // P_Comp_Code: authUser.company_code,
        // P_Usr_Id: authUser.user,
        ...JSON.parse(doc.CRLOG_BIND_PARAM),
      };
      let generateReportPayload: any = {
        rep_id: doc.CREP_ID,
        // query: doc.REP_SP,
        // bind_params: doc.REP_PARAM,
        bind_values,
        reportName: doc.CREP_NAME,
        logReportParams: doc,
      };

      const result = await api.post(
        "report/log/export/pdf",
        undefined,
        generateReportPayload
      );
      if (result.status) {
        const fileName = result.fileLink.split("/").pop();
        fetch(result.fileLink).then((resp) => {
          resp.blob().then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          });
        });
        dispatch({ type: "SUCCESS", payload: progressDetails });
        setLoadingSingleReport(false);
        // setOngoingExportCount((prev: number) => prev - 1);
        return;
      }
      dispatch({
        type: "FAILED",
        payload: { progressDetails, message: result.message },
      });
      // else console.error(result);
      setLoadingSingleReport(false);
      // setOngoingExportCount((prev: number) => prev - 1);
    } catch (error: any) {
      dispatch({
        type: "FAILED",
        payload: { ...reportRef.current, message: error.message },
      });
      // console.error("log pdf export by report generate error - ", error);
      setLoadingSingleReport(false);
      // setOngoingExportCount((prev: number) => prev - 1);
    }
  };

  const exportExcelByGeneratingReport = async () => {
    try {
      setLoadingSingleReport(true);
      // setOngoingExportCount((prev: number) => prev + 1);
      const progressDetails = getProgressDetails();
      reportRef.current = progressDetails;
      dispatch({ type: "START", payload: progressDetails });
      toast(`Started to generate ${progressDetails.reportName}`, {
        icon: "📄",
        duration: 6000,
        style: {
          border: "1px solid #252b83",
          padding: "10px",
          backgroundColor: "#252b83",
          color: "#FFFAEE",
        },
      });
      let bind_values = {
        // P_Comp_Code: authUser.company_code,
        // P_Usr_Id: authUser.user,
        ...JSON.parse(doc.CRLOG_BIND_PARAM),
      };
      let generateReportPayload: any = {
        rep_id: doc.CREP_ID,
        // query: doc.REP_SP,
        // bind_params: doc.REP_PARAM,
        bind_values,
        reportName: doc.CREP_NAME,
        logReportParams: doc,
      };

      const result = await api.post(
        "report/log/export/excel",
        undefined,
        generateReportPayload
      );
      if (result.status) {
        const fileName = result.fileLink.split("/").pop();
        fetch(result.fileLink).then((resp) => {
          resp.blob().then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          });
        });
        dispatch({ type: "SUCCESS", payload: progressDetails });
        setLoadingSingleReport(false);
        // setOngoingExportCount((prev: number) => prev - 1);
        return;
      }
      dispatch({
        type: "FAILED",
        payload: { progressDetails, message: result.message },
      });
      // else console.error(result);
      setLoadingSingleReport(false);
      // setOngoingExportCount((prev: number) => prev - 1);
    } catch (error: any) {
      dispatch({
        type: "FAILED",
        payload: { ...reportRef.current, message: error.message },
      });
      // console.error("log excel export by report generate error - ", error);
      setLoadingSingleReport(false);
      // setOngoingExportCount((prev: number) => prev - 1);
    }
  };

  const getPdfFromS3 = (reportParams: any) => {
    fetch(reportParams.CRLOG_FILE_PATH).then((resp) => {
      if (reportParams.CRLOG_FILE_PATH && resp.ok) {
        const progressDetails = getProgressDetails();
        dispatch({ type: "START", payload: progressDetails });
        // toast(`Started to generate ${progressDetails.reportName}`, {
        //   icon: "📄",
        //   style: {
        //     border: "1px solid #252b83",
        //     padding: "10px",
        //     color: "#252b83",
        //   },
        // });
        resp.blob().then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let pdfFileName = reportParams.CRLOG_FILE_PATH.substring(
            reportParams.CRLOG_FILE_PATH.lastIndexOf("/") + 1
          );
          link.download = pdfFileName;
          link.click();
        });
        dispatch({ type: "SUCCESS", payload: progressDetails });
      } else exportPdfByGeneratingReport();
    });
  };

  const getExcelFromS3 = (reportParams: any) => {
    fetch(reportParams.CRLOG_EXCEL_PATH).then((resp) => {
      if (reportParams.CRLOG_EXCEL_PATH && resp.ok) {
        const progressDetails = getProgressDetails();
        dispatch({ type: "START", payload: progressDetails });
        // toast(`Started to generate ${progressDetails.reportName}`, {
        //   icon: "📄",
        //   style: {
        //     border: "1px solid #252b83",
        //     padding: "10px",
        //     color: "#252b83",
        //   },
        // });
        resp.blob().then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let pdfFileName = reportParams.CRLOG_EXCEL_PATH.substring(
            reportParams.CRLOG_EXCEL_PATH.lastIndexOf("/") + 1
          );
          link.download = pdfFileName;
          link.click();
        });
        dispatch({ type: "SUCCESS", payload: progressDetails });
      } else exportExcelByGeneratingReport();
    });
  };

  useEffect(() => {
    if (doc.isPdf) getPdfFromS3(doc);
    else getExcelFromS3(doc);
  }, []);

  return (
    <Paper>
      <Group>
        {isLoadingSingleReport ? (
          <Loader />
        ) : (
          <ThemeIcon variant="outline" radius="xl" color="green">
            <IconCircleCheck />
          </ThemeIcon>
        )}

        <Box>
          <Text>{doc.CREP_NAME}</Text>
          <Text size="sm">{`${doc.isPdf ? "Pdf" : "Excel"} of ${dayjs(
            doc.CRLOG_CR_DT
          ).format("DD-MMM-YYYY")} by ${doc.CRLOG_CR_USER}`}</Text>
        </Box>
      </Group>
    </Paper>
  );
};

export default GenerationMenuItem;

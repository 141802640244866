import {IconUser} from "@tabler/icons";

const profile = {
    id: "profile",
    title: "Profile",
    type: "item",
    url: "/profile",
    // icon: IconUser,
    icon: "IconUser",
    children: [],
};

export default profile;

import { Dialog, Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { useDispatch } from "react-redux";

const ErrorAlert = ({
  isOpen,
  title,
  message,
}: {
  isOpen: boolean;
  title: string;
  message: string;
}) => {
  const dispatch = useDispatch();

  return (
    <Dialog
      opened={isOpen}
      size="xl"
      radius="md"
      position={{ top: 10, right: 20 }}
      withBorder={false}
      zIndex={501}
    >
      <Alert
        icon={<IconAlertCircle size={24} />}
        title={title}
        color="red"
        variant="filled"
        onClose={() => dispatch({ type: "CLOSE_SNACK_TOAST" })}
        withCloseButton
      >
        {message}
      </Alert>
    </Dialog>
  );
};

export default ErrorAlert;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type menuState = {
  menuState: [];
};

const initialState: menuState = {
  menuState: []
};

export const menuStateSlice = createSlice({
  name: "menuState",
  initialState,
  reducers: {
      setMenuState: (state, action: PayloadAction<any>) => {
      state.menuState = action.payload;
    }
  }
});

export const {
  setMenuState
} = menuStateSlice.actions;

export default menuStateSlice.reducer;
import { Textarea } from "@mantine/core";

const TextAreaInput = ({
  id,
  formik,
  ...props
}: {
  id: string;
  formik: any;
  label?: string;
  disabled?: boolean;
  // setValue: any;
}) => {
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue(event.target.value);
  // };

  return (
    <Textarea
      id={id}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={!!(formik.touched[id] && formik.errors[id]) && formik.errors[id]}
      autosize
      minRows={2}
      {...props}
    />
  );
};

export default TextAreaInput;

import { ActionIcon, Textarea, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useMemo } from "react";
import { TInputField } from "../../typings";

const ParamTextarea = ({
  id,
  // paramKey,
  inputField,
  formik,
  handleModalOpen,
  setValidateParam,
  ...props
}: {
  id: string;
  // paramKey: string;
  inputField: TInputField;
  handleModalOpen?: any;
  setValidateParam?: any;
  formik: any;
}) => {
  const queryClient = useQueryClient();
  const dtpckrId = useMemo(() => {
    const controls:
      | { label: string; value: string; code: string }[]
      | undefined = queryClient.getQueryData(["uiConfig", "controlOptions"]);
    const control = controls?.find((ctrl) => ctrl.code === "DT_PCKR");
    return control?.value ?? "";
  }, [queryClient]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // const valueFormik = `${paramKey}.${inputField.fieldName}`;
    // formik.setFieldValue(valueFormik, event.target.value);
    formik.setFieldValue(inputField.fieldName, event.currentTarget.value);
  };

  return (
    <Textarea
      id={id}
      maxRows={3}
      label={inputField.fieldLabel}
      value={
        formik.values.control_id + "" === dtpckrId + "" &&
        formik.values[inputField.fieldName]
          ? dayjs(formik.values[inputField.fieldName]).format("DD-MMM-YYYY")
          : formik.values[inputField.fieldName]
      }
      onChange={handleChange}
      disabled={formik.isSubmitting}
      readOnly={inputField.isReadOnly}
      onBlur={() => {
        formik.setFieldTouched(inputField.fieldName);
        if (
          inputField.fieldName === "param" ||
          inputField.fieldName === "sparam" ||
          inputField.fieldName === "oparam" ||
          inputField.fieldName === "order" ||
          inputField.fieldName === "oorder"
        )
          setValidateParam((prev: boolean) => !prev);
      }}
      error={
        !!(
          formik.touched &&
          formik.touched[inputField.fieldName] &&
          formik.errors &&
          formik.errors[inputField.fieldName]
        ) && formik.errors[inputField.fieldName]
      }
      rightSection={
        // parm_val is a field in Ui Settings (Params)
        inputField.fieldName === "parm_val" ? (
          <Tooltip label="Edit">
            <ActionIcon size="md" onClick={() => handleModalOpen()}>
              <IconEdit fontSize="medium" />
            </ActionIcon>
          </Tooltip>
        ) : undefined
      }
      {...props}
    />
    // <TextField
    //   fullWidth
    //   id={id}
    //   label={inputField.fieldLabel}
    //   value={formik.values[paramKey][inputField.fieldName]}
    //   onChange={handleChange}
    //   disabled={formik.isSubmitting}
    //   onBlur={() => {
    //     formik.setFieldTouched(`${paramKey}.${inputField.fieldName}`);
    //   }}
    //   helperText={
    //     formik.touched &&
    //     formik.touched[paramKey] &&
    //     formik.touched[paramKey][inputField.fieldName] &&
    //     formik.errors &&
    //     formik.errors[paramKey] &&
    //     formik.errors[paramKey][inputField.fieldName]
    //   }
    //   error={
    //     !!(
    //       formik.touched &&
    //       formik.touched[paramKey] &&
    //       formik.touched[paramKey][inputField.fieldName] &&
    //       formik.errors &&
    //       formik.errors[paramKey] &&
    //       formik.errors[paramKey][inputField.fieldName]
    //     )
    //   }
    //   {...textFieldConfig}
    //   {...props}
    // />
  );
};

export default ParamTextarea;

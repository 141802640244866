import {
    Box,
    Button,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    Text,
    Title,
} from "@mantine/core";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useEffect, useState} from "react";

import MainCard from "../../../components/Cards/MainCard";
import FilesDropZone from "../../../components/FileDropZone/FileDropZone";
import {showCustomNotification} from "../../../helper/customNotification";
import {
    getValidatedData,
    uploadExcelForValidation,
    uploadFinancialEntry,
} from "../queries";
import {
    ExcelUploadResponseProps,
    uploadResponseProps
} from "../types";
import {SpreadsheetView} from "./ExcelViewer";
import styles from "../style.module.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {IconFileDownload} from "@tabler/icons";
import {api} from "../../../api";
import FinanceDetails from "./Details";
import ReportCardLoader from "../../../components/ReportCardLoader";

var fileSaver = require('file-saver');
import {Link} from "react-router-dom";

function UploadFinancialEntry() {
    const [fileRefId, setFileRef] = useState<string | null>(null);
    const [jsonData, setJsonData] = useState<any[]>([]);
    const [hasInvalidData, setInvalid] = useState<boolean>(false);
    const [finDetails, setFinDetails] = useState<any | null>([]);
    const [finHead, setFinHead] = useState<string | null>(null);
    const [downloadLink, setDownloadLink] = useState<string | null>(null);
    const [isLoading1, setLoading1] = useState(false);
    const url:any = process.env.REACT_APP_BASE_URL + "/documents/FinanceEntrySample.xlsx"

    function refreshState() {
        setFileRef(null);
        setJsonData([]);
        setInvalid(false);
    }

    const {mutate: mutUploadExcel, isLoading} = useMutation(
        uploadExcelForValidation,
        {
            onError: (error: Error) => {
                showCustomNotification({
                    title: "Error",
                    message: "failed to upload excel",
                    notifyType: "ERROR",
                });
            },
            onSuccess: (data: ExcelUploadResponseProps) => {
                setFileRef(data.fileRefId);
            },
        }
    );
    const {mutate: mutUploadFinancialEntry, isLoading: isLoadingUpload} =
        useMutation(uploadFinancialEntry, {
            onError: (error: Error) => {
                showCustomNotification({
                    title: "Error",
                    message: "failed to upload financial entry",
                    notifyType: "ERROR",
                });
            },
            onSuccess: (data: uploadResponseProps) => {
                // showCustomNotification({
                //   title: "Success",
                //   message: data.P_Out_Msg,
                //   notifyType: "SUCCESS",
                // });
                getFinanceDetails(data.P_DvH_Id)
                if (data.P_Msg_Status === '1') {
                    showCustomNotification({
                        title: "Success",
                        message: data.P_Out_Msg,
                        notifyType: "SUCCESS",
                    });
                } else {
                    showCustomNotification({
                        title: "Error",
                        message: data.P_Out_Msg,
                        notifyType: "ERROR",
                    });
                }
                refreshState();
            },
        });


    const {isFetching: isLoadingValidatedData, refetch} = useQuery(
        ["excel", "validated"],
        () => getValidatedData(fileRefId ? fileRefId : ""),
        {
            enabled: false,
            onSuccess(data) {
                setJsonData(data.P_Out_Data);
                setInvalid(
                    data.P_Out_Data.filter((row: any) => row["Error Messages"]).length > 0
                );
            },
            onError(err) {
                console.log("error : ", err);
                showCustomNotification({
                    title: "Server Error",
                    message: "Failed to validate input data",
                    notifyType: "ERROR",
                });
            },
        }
    );

    const getFinanceDetails: any = async (financeId: any) => {
        try {
            setLoading1(true)
            const response = await api.post(`finance/getDetails`, undefined, {finance_id: financeId})
            if (response.status) {
                setLoading1(false)
                setFinHead(response.data.details.P_Header[0])
                setFinDetails(response.data.details.P_Detail)
                setDownloadLink(response.data.downloadLink)
            }
        } catch (error: any) {
            return console.error(error.message);
        }
    };

    useEffect(() => {
        if (fileRefId) refetch();
    }, [fileRefId]);

    function readExcelFile(file: File) {
        if (file) {
            const formData = new FormData();
            formData.append("finance_data", file);
            mutUploadExcel(formData);
        }
    }

    return (
        <MainCard title="Upload Financial entry" titleOrder={2}>
            <LoadingOverlay
                loader={
                    <div
                        style={{
                            display: "flex",
                            flex: 2,
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Loader/>
                        {isLoading || isLoadingUpload
                            ? "Loading..."
                            : isLoadingValidatedData
                                ? "Validating uploaded data..."
                                : null}
                    </div>
                }
                visible={isLoading || isLoadingValidatedData || isLoadingUpload}
            />
            <Group spacing="xl" pb="xl" position="apart" align="start">
                <Box sx={{width: "35rem"}}>
                    <FilesDropZone
                        loading={false}
                        multiple={false}
                        onDrop={(files) => readExcelFile(files[0])}
                        // onReject={(files) =>
                        //   openModal({
                        //     centered: true,
                        //     children: (
                        //       <SimpleCustomAlert
                        //         type="error"
                        //         title="Upload failed"
                        //         message={files[0].errors[0].message}
                        //         handleClose={() => closeAllModals()}
                        //       />
                        //     ),
                        //   })
                        // }
                    />
                    <a href={url}  download="FinanceEntrySample">

                        <Button size='sm'
                                variant="subtle"
                                leftIcon={<IconFileDownload size="1rem"/>}>
                            Download Sample File
                        </Button>
                    </a>


                </Box>
            </Group>

            {jsonData.length && fileRefId ? (
                <Group spacing={"xl"} pb="xl">
                    <Box
                        sx={{display: "flex", flexGrow: 1, justifyContent: "flex-start"}}
                    >
                        <Grid>
                            <Grid.Col xl={12}>
                                <Title order={4}>Excel Validated Data Preview</Title>
                            </Grid.Col>
                            {hasInvalidData ? (
                                <Grid.Col xl={12}>
                                    <Text className={styles.errorBlink}>
                                        Choosen file has invalid data, please correct it and
                                        re-upload <WarningAmberIcon/>
                                    </Text>
                                </Grid.Col>
                            ) : null}
                        </Grid>
                    </Box>
                    <Box sx={{width: "100%", overflow: "scroll", minHeight: 240}}>
                        <SpreadsheetView data={jsonData}/>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexGrow: 1,
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <Button
                            onClick={() => mutUploadFinancialEntry(fileRefId)}
                            disabled={!fileRefId || !jsonData.length || hasInvalidData}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Group>
            ) : null}

            {isLoading1 && <ReportCardLoader/>}

            {finHead && finDetails.length ? (
                <FinanceDetails finHead={finHead} finDetails={finDetails} downloadLink={downloadLink}/>
            ) : null}

        </MainCard>


    );
}

export default UploadFinancialEntry;

import {api} from "../../../api";
import {isOracleError} from "../../../helper/common";
import {showCustomNotification} from "../../../helper/customNotification";
import {ValidatedResponseProps} from "./Controls/FileUploadDetails/types";

export const getReportTypes = async () => {
    const response = await api.post("report/custom/list/null", undefined, {});
    if (!response.status) throw new Error(response.message);
    return response.data;
};

export const getReportParams = async (report: any) => {
    const response = await api.post(
        `report/custom/params/${report.REP_ID}`,
        undefined,
        {}
    );
    if (!response.status) throw new Error(response.message);
    return response.data;
};

export async function uploadExcelForValidation(request: FormData) {
    try {
        return await Promise.resolve(api.post("report/importExcel", undefined, request));
    } catch (error) {
        return error;
    }
}


export async function getValidatedData(
    fileRefId: string
): Promise<ValidatedResponseProps> {
    try {
        return await Promise.resolve(api.get(`report/${fileRefId}`));
    } catch (error) {
        throw error;
    }
}

// export async function uploadFinancialEntry(refId: string) {
//     try {
//         return api.post(`report/${refId}`, undefined, {});
//     } catch (error) {
//         return error;
//     }
// }

export const generateReport = async ({
                                         data,
                                         authUser,
                                         checkedColumns,
                                         selectedDocs,
                                         dispatch,
                                         progressDetails,
                                         queryClient,
                                     }: any) => {
    try {

        const reportDetails = {...data.payload, mail_id: authUser.email_id};
        //   console.log(5555);

        //    console.log(data);

        //todo: below object is created as per old api call...need to optimise

        // Object.entries(data.stockValues).map(function (stock:any) {
        //
        //   console.log(stock)
        //
        // }

        const payloadToGenerateAndMailReport = {
            payload: {
                stockValues: data.stockValues,
                reportDetails,
            },
            checkedColumns,
            selectedDocs,
        };

        const labels = Object.values(data.stockValues).map((val: any) => val.label);
        // const labels1 = []


        progressDetails = {
            ...progressDetails,
            reportName: reportDetails.reportName,
            reportDetails: labels.join(" | "),
        };

        dispatch({type: "START", payload: progressDetails});

        // toast(`Started to generate ${progressDetails.reportName}`, {
        //   icon: "📄",
        //   duration: 6000,
        //   style: {
        //     border: "1px solid #252b83",
        //     padding: "10px",
        //     backgroundColor: "#252b83",
        //     color: "#FFFAEE",
        //   },
        // });

        const response = await api.post(
            "report/export",
            undefined,
            payloadToGenerateAndMailReport
        );
        if (!response.status) throw new Error(response.message);
        if (!response.result.length)
            throw new Error("Something went wrong... document is not found");


        // using old api...thats why reduce function is used here...
        const generatedDocs = response.result.reduce(
            (
                acc: Record<"excel" | "pdf", string>,
                curr: { fileType: "excel" | "pdf"; fileLink: string }
            ) => ({...acc, [curr.fileType]: curr.fileLink}),
            {}
        );
        dispatch({type: "SUCCESS", payload: progressDetails});
        queryClient.cancelQueries(["reportLogs"]);
        queryClient.invalidateQueries(["reportLogs"]);
        const generatedData = {docs: generatedDocs, generatedTime: new Date()};
        return generatedData;
    } catch (error: any) {
        dispatch({type: "FAILED", payload: progressDetails});
        // dispatch({
        //   type: "OPEN_SNACK_TOAST",
        //   payload: { title: "Server Error", message: error.message },
        // });
        if (isOracleError(error.message)) {
            dispatch({
                type: "OPEN_ALERT_MODAL",
                payload: {
                    title: "Oracle Error",
                    message: error.message,
                    type: "ORACLE_ERROR",
                },
            });
        } else {
            showCustomNotification({
                title: "Error",
                message: error.message,
                notifyType: "ERROR",
            });
        }
        throw new Error(error.message);
    }
};

import {Button, Divider, Grid, Modal, Text, Title} from "@mantine/core";
import {IconCalendar, IconClock} from "@tabler/icons";
import {Formik} from "formik";
import {DatePicker, TimeInput} from "@mantine/dates";
import TextAreaInput from "../../components/TextareaInput";
import dayjs from "dayjs";
import SelectDropdown from "../../components/SelectDropdown";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {createTimesheet, getTaskList} from "./queries";
import {ProjectProps, TaskProps, TimesheetProps} from "./types";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {showCustomNotification} from "../../helper/customNotification";

type TProps = {
    isModalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddTask = ({isModalOpen, setModalOpen}: TProps) => {
    const [options, setOptions] = useState<{ label: string; value: any }[]>([]);
    // const [taskOptions, setTaskOption] = useState<
    //   { label: string; value: any }[]
    // >([]);

    const {authUser} = useSelector((state: any) => state.auth);
    const queryClient = useQueryClient();


    const { isLoading } = useQuery<TaskProps[], Error>(
        ["timesheet", "taskId"],
        getTaskList,
        {
            onError: (error: Error) => {
                console.log(error);
            },
            onSuccess(data) {
                const options = data.map((it) => ({
                    value: it.ETSK_ID,
                    label: it.ETSK_NAME,
                }));
                setOptions(options);
            },
        }
    );

    // useEffect(() => {
    //     getTaskList();
    // });
    
    const {mutate: MutCreateTimesheet} = useMutation(createTimesheet, {
        onError: (error: Error) => {
            console.log("error : ", error);
            showCustomNotification({
                title: "Failed",
                message: error.message,
                notifyType: "ERROR",
            });
        },
        onSuccess: (data: any) => {
            console.log(data);
            showCustomNotification({
                title: "Success",
                message: data.P_Out_Msg,
                notifyType: "SUCCESS",
            });
            queryClient.invalidateQueries(["timesheet", "list"]);
            setModalOpen(false);
        },
    });

    const initialValues = {
        //  project: null,
        taskId: null,
        taskDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        description: "",
    };

    // async function handleProjectChange(projectId: string | null) {
    //   if (projectId) {
    //     const tasks: TaskProps[] = await getTaskList();
    //     setTaskOption(
    //       tasks.map((item) => ({ value: item.TSK_ID, label: item.TSK_NAME }))
    //     );
    //   }
    // }

    return (
        <Modal
            opened={isModalOpen}
            onClose={() => setModalOpen(false)}
            centered
            closeOnEscape={false}
            closeOnClickOutside={false}
            // size="50%"
            zIndex={500}
        >
            <Title align="center" order={4}>
                Add a New Task
            </Title>
            <Divider my="lg"/>
            <Formik
                initialValues={initialValues}
                validate={(values) => {
                    let errors: any = {};
                    const taskTimeDiff = dayjs(values.endTime).diff(
                        dayjs(values.startTime),
                        "minute"
                    );
                    // if (!values.project) errors = { ...errors, project: "is required" };
                    if (!values.taskId) errors = {...errors, task: "is required"};
                    if (taskTimeDiff < 0)
                        errors = {
                            ...errors,
                            endTime: "end time must be after start time",
                        };

                    return errors;
                }}
                onSubmit={(values) => {
                    const formValues: TimesheetProps = {
                        taskId: values.taskId,
                        taskDate: dayjs(values.taskDate).format("DD-MMM-YY") ,
                        startTime: dayjs( values.taskDate).format("DD-MMM-YY")+' '+dayjs(values.startTime).format("H:mm:ss"),
                        endTime: dayjs(values.taskDate).format("DD-MMM-YY")+' ' +dayjs(values.endTime).format("H:mm:ss"),
                        description: values.description,

                        P_Tsut_Perc: 100,
                        user: authUser.user,
                        P_Comp_code: authUser.company_code,
                        P_Divn_code: authUser.division_code,
                    };
                    MutCreateTimesheet({
                        emp_code: authUser.user_emp_code,
                        request: formValues,
                    });
                    console.log("submit values : ", formValues);
                }}
            >
                {(formik) => (
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <Grid>
                            {/*<Grid.Col xs={12} md={4}>*/}
                            {/*<Text>Project</Text>*/}
                            {/*</Grid.Col>*/}
                            {/*<Grid.Col xs={12} md={8}>*/}
                            {/*<SelectDropdown*/}
                            {/*formik={formik}*/}
                            {/*id="project"*/}
                            {/*options={options}*/}
                            {/*onChange={(value) => {*/}
                            {/*formik.setFieldValue("project", value);*/}
                            {/*handleProjectChange(value);*/}
                            {/*}}*/}
                            {/*/>*/}
                            {/*</Grid.Col>*/}
                            <Grid.Col xs={12} md={4}>
                                <Text>Task</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={8}>
                                <SelectDropdown
                                    formik={formik}
                                    id="taskId"
                                    options={options}
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Text>Date</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={8}>
                                <DatePicker
                                    value={formik.values.taskDate}
                                    onChange={(newDate: Date) => {
                                        formik.setFieldValue("taskDate", newDate);
                                    }}
                                    clearable={false}
                                    rightSection={<IconCalendar size={18} color="#25338e"/>}
                                    zIndex={500}
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Text>Start Time</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={8}>
                                <TimeInput
                                    value={formik.values.startTime}
                                    onChange={(value) => {
                                        formik.setFieldValue("startTime", value);
                                    }}
                                    format="24"
                                    rightSection={<IconClock size={16}/>}
                                    sx={(theme) => ({
                                        color: theme.colors[theme.primaryColor][6],
                                    })}
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Text>End Time</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={8}>
                                <TimeInput
                                    value={formik.values.endTime}
                                    onChange={(value) => formik.setFieldValue("endTime", value)}
                                    format="24"
                                    rightSection={<IconClock size={16}/>}
                                    sx={(theme) => ({
                                        color: theme.colors[theme.primaryColor][6],
                                    })}
                                    error={
                                        !!(
                                            (formik.touched.startTime || formik.touched.endTime) &&
                                            formik.errors.endTime
                                        ) && formik.errors.endTime
                                    }
                                />
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Text>Description</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={8}>
                                <TextAreaInput formik={formik} id="description"/>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}></Grid.Col>
                            <Grid.Col xs={12} md={8}>
                                <Button type="submit" fullWidth>
                                    Add New Task
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default AddTask;

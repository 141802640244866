import {Grid, Group, Modal, Text, Box} from "@mantine/core";
import {
    Pagination,
    Paper,
    Table
} from "@mantine/core";
import styles from "./style.module.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
    IconChevronDown,
    IconChevronUp
} from "@tabler/icons";
import {useState} from "react";

type Order = "asc" | "desc";
type TableColumnHeadersProps = {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
};

type HeadCell = {
    id: string;
    label: string;
    // align: "left" | "center" | "right" | "justify" | "inherit" | undefined;
    hidden: boolean;
};

type TControl = {
    CRUIC_ID: string | number;
    CRUIC_NAME: string;
    CRUIC_CODE: string;
};

const headCells: HeadCell[] = [
    {
        id: "ITEM_CODE",
        label: "Item Code",
        // align: "left",
        hidden: false,
    },

    {
        id: "ITEM_NAME",
        label: "Item Name",
        // align: "left",
        hidden: false,
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const FileDetails = ({
                             isModalOpen,
                             handleModalClose,
                             jsonData,
                             hasInvalidData,

                         }: any) => {

    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("CRUIC_NAME");
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jsonData.length) : 0;

    const totalPages = Math.ceil(jsonData.length / rowsPerPage);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string }
    ) => number {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const TableColumnHeaders = (props: TableColumnHeadersProps) => {
        const {order, orderBy, onRequestSort} = props;

        const createSortHandler =
            (property: any) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <thead>
            <tr
                style={{
                    backgroundColor: "#2e439e",
                    // color: "#404040",
                }}
            >
                {headCells.map((headCell) => (
                    <th
                        key={headCell.id}
                        align="left"
                        style={{color: "#EAE9E7"}}
                        // align={headCell.numeric ? "right" : "left"}
                    >
                        <Group spacing="xs" onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {order === "desc" ? (
                                <IconChevronDown
                                    style={{
                                        visibility: orderBy === headCell.id ? "visible" : "hidden",
                                    }}
                                />
                            ) : (
                                <IconChevronUp
                                    style={{
                                        visibility: orderBy === headCell.id ? "visible" : "hidden",
                                    }}
                                />
                            )}
                        </Group>
                    </th>
                ))}
                <th></th>
            </tr>
            </thead>
        );
    };

    return (
        <Modal
            opened={isModalOpen}
            onClose={() => {
                handleModalClose();
            }}
            centered
            closeOnEscape={false}
            closeOnClickOutside={false}
            // size="50%"
            size="auto"
            zIndex={500}
            // title={modalTitle}
        >
            {/*<Title order={3} align="center" mb={5}>*/}
            {/*mhkjh*/}
            {/*</Title>*/}
            {/*<MainCard title="Validate Uploaded Data" titleOrder={2}>*/}
                <Group spacing={"xl"} pb="xl">
                    <Box
                        sx={{display: "flex", flexGrow: 1, justifyContent: "flex-start"}}
                    >
                        <Grid>
                            {/*<Grid.Col xl={12}>*/}
                            {/*<Title order={4}>Excel Validated Data Preview</Title>*/}
                            {/*</Grid.Col>*/}
                            {hasInvalidData ? (
                                <Grid.Col xl={12}>
                                    <Text className={styles.errorBlink}>
                                        Choosen file has invalid data, please correct it and
                                        re-upload <WarningAmberIcon/>
                                    </Text>
                                </Grid.Col>
                            ) : null}
                        </Grid>
                    </Box>
                    <Box sx={{width: "100%"}}>
                        {jsonData && (
                            <Paper sx={{width: "100%", mb: 2}}>
                                <Table
                                    mb={10}
                                    striped
                                    horizontalSpacing="md"
                                    verticalSpacing="sm"
                                >
                                    <TableColumnHeaders
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={jsonData.length}
                                    />
                                    <tbody>
                                    {jsonData
                                        .slice()
                                        .sort(getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any) => {
                                            return (
                                                <tr style={{paddingTop: 5, paddingBottom: 5}}
                                                    key={`logTable-${row.GTRL_ID}`}
                                                >
                                                    {/* <td align="left">{row.CRUIC_ID}</td> */}
                                                    <td align="left">{row.ITEM_CODE}</td>
                                                    <td align="left">{row.ITEM_NAME}</td>
                                                </tr>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <tr style={{height: 20 * emptyRows}}>
                                            <td colSpan={5}/>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Pagination
                                    position="center"
                                    page={page + 1}
                                    onChange={(page: number) => setPage(page - 1)}
                                    withEdges
                                    total={totalPages}
                                />
                            </Paper>
                        )}
                    </Box>
                </Group>
            {/*</MainCard>*/}
        </Modal>
    );
};

export default FileDetails;

import { Divider, Grid, Group, Image, Text } from "@mantine/core";
import dayjs from "dayjs";
import { TUserUploadedDoc } from ".";
import MainCard from "../../../components/Cards/MainCard";
import DocUpload from "./DocUpload";

const DocCard = ({
  doc,
  empCode,
  empCompCode,
}: {
  doc: TUserUploadedDoc;
  empCode: string;
  empCompCode: string;
}) => {
  const DocInfo = () => {
    return (
      <Grid>
        <Grid.Col xs={12}>
          <Group>
            <Text>Document No :</Text>
            <Text weight="bold">{doc.DOC_NO}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Doc Type Code :</Text>
            <Text weight="bold">{doc.DOC_TYPE_CODE}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Employee Code :</Text>
            <Text weight="bold">{doc.DOC_EMP_CODE}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Doc Type Name :</Text>
            <Text weight="bold">{doc.DOC_TYPE_NAME}</Text>
          </Group>
        </Grid.Col>

        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Employee Name :</Text>
            <Text weight="bold">{doc.EMP_NAME}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Doc ISS Date :</Text>
            <Text weight="bold">
              {dayjs(doc.DOC_ISS_DT).format("DD/MM/YYYY")}
            </Text>
          </Group>
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Doc ISS Place :</Text>
            <Text weight="bold">{doc.DOC_ISS_PLACE}</Text>
          </Group>
        </Grid.Col>

        <Grid.Col xs={12} md={6}>
          <Group>
            <Text>Doc Expiry Date :</Text>
            <Text weight="bold">
              {dayjs(doc.DOC_EXP_DT).format("DD/MM/YYYY")}
            </Text>
          </Group>
        </Grid.Col>
      </Grid>
    );
  };

  return (
    <MainCard sx={{ position: "relative" }}>
      {/* <LoadingOverlay visible={isLoading} /> */}
      <Grid>
        <Grid.Col xs={8} md={9} sx={{ display: "flex", alignItems: "center" }}>
          <DocInfo />
        </Grid.Col>
        <Grid.Col xs={4} md={3}>
          <div style={{ width: 200, marginLeft: "auto" }}>
            <Image
              fit="contain"
              height={200}
              width={200}
              radius="md"
              src={doc.DOC_URL ?? undefined}
              alt="Document preview"
              withPlaceholder
            />
          </div>
        </Grid.Col>
      </Grid>
      <Divider my="sm" label="Update document" labelPosition="center" />
      <DocUpload
        docData={{
          DOC_EMP_CODE: doc.DOC_EMP_CODE,
          DOC_TYPE_CODE: doc.DOC_TYPE_CODE,
          DOC_NO: doc.DOC_NO,
          DOC_EXP_DT: doc.DOC_EXP_DT,
        }}
        empCode={empCode}
        empCompCode={empCompCode}
      />
    </MainCard>
  );
};

export default DocCard;

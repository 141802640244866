import * as Yup from "yup";
import {
  dependentFieldsInputFieldValues,
  reportHeaderInputFieldValues,
  uiSettingsInputFieldValues,
} from "./FormFields";
import {
  TDependentFieldsInputFieldValues,
  TReportHeaderInputFieldValues,
  TReportTypeValues,
  TUiSettingsInputFieldValues,
} from "../typings";

// Report Type
// export const reportTypeValidationSchema = Yup.object().shape({
//   reportName: Yup.string().required("Required"),
//   reportProcedure: Yup.string().required("Required"),
// });

export const reportTypeInitialValues: TReportTypeValues = {
  reportId: 0,
  reportName: "",
  reportStatus: "O",
  reportProcedure: "",
};

// Ui Settings (Params)
// export const uiSettingsValidationSchema = (
//   values: TUiSettingsInputFieldValues
// ) => {
//   // const schema = Object.keys(values).reduce(
//   //   (schema, key) => ({
//   //     ...schema,
//   //     [key]: Yup.object().shape({
//   //       order: Yup.number()
//   //         .typeError("order must be a number")
//   //         .required("Required"),
//   //     }),
//   //   }),
//   //   {}
//   // );
//   // return Yup.object().shape(schema);
//   let errors: any = {};
//   // Object.keys(values).forEach((key) => {
//   //   if (!/^\d+$/.test(values[key].order.toString())) {
//   //     errors = {
//   //       ...errors,
//   //       [key]: { ...errors[key], order: "order must a number" },
//   //     };
//   //   }
//   // });
//   if (!/^\d+$/.test(values.order.toString())) {
//     errors = {
//       ...errors,
//       order: "order must a number",
//     };
//   }
//   return errors;
// };
// export const uiSettingsValidationSchema = Yup.object().shape({
//   control_id: Yup.number()
//     .typeError("must specify a number")
//     .required("Required"),
//   param: Yup.string().required("Required"),
//   type: Yup.string().required("Required"),
//   data_type: Yup.string().required("Required"),
//   label: Yup.string().required("Required"),
//   order: Yup.number().typeError("must specify a number").required("Required"),
//   value_type: Yup.string().required("Required"),
//   parm_val: Yup.string().required("Required"),
//   // parm_val_json: Yup.string().required("Required"),
//   ui_visible: Yup.string().required("Required"),
//   mandatory_field: Yup.string().required("Required"),
// });

export const uiSettingsInitialValues: Record<
  string,
  TUiSettingsInputFieldValues
> = {
  randomKey48rn: uiSettingsInputFieldValues,
};

// Dependent Fields (Sub Params)
// export const dependentFieldsValidationSchema = Yup.object().shape({
//   sparam: Yup.string().required("Required"),
//   stype: Yup.string().required("Required"),
//   sdata_type: Yup.string().required("Required"),
// });

export const dependentFieldsInitialValues: Record<
  string,
  TDependentFieldsInputFieldValues
> = {
  randomKey63sp: dependentFieldsInputFieldValues,
};

// Report Header (Out Params)
// export const reportHeaderValidationSchema = (
//   values: Record<string, TReportHeaderInputFieldValues>
// ) => {
//   let errors: any = {};
//   Object.keys(values).forEach((key) => {
//     if (!/^\d+$/.test(values[key].oorder.toString())) {
//       errors = {
//         ...errors,
//         [key]: { ...errors[key], oorder: "order must a number" },
//       };
//     }
//   });
//   return errors;
// };
// export const reportHeaderValidationSchema = Yup.object().shape({
//   oparam: Yup.string().required("Required"),
//   odata_type: Yup.string().required("Required"),
//   olabel: Yup.string().required("Required"),
//   oorder: Yup.number().typeError("must specify a number").required("Required"),
// });

export const reportHeaderInitialValues: Record<
  string,
  TReportHeaderInputFieldValues
> = {
  randomKey72rh: reportHeaderInputFieldValues,
};

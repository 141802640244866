import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconClipboardCheck } from "@tabler/icons";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../../config";

const DownloadFromMail = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const param = new URLSearchParams(search).get("pulse");
  const [opened, setOpened] = useState(true);

  const handleDownload = () => {
    if (param) {
      // saveAs(`${config.AWS_S3_FRONT_BASE_URL}/${param}.pdf`, param);
      // navigate("/");
      fetch(`${config.AWS_S3_FRONT_BASE_URL}/${param}.pdf`).then((resp) => {
        resp.blob().then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = param;
          link.click();
          setOpened(false);
          navigate("/", { replace: true });
        });
      });
    }
  };

  useEffect(() => {
    if (!param) navigate("/", { replace: true });
  }, [navigate, param]);

  return (
    <div>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          navigate("/", { replace: true });
        }}
        centered
        closeOnEscape={false}
        closeOnClickOutside={false}
        size="50%"
        zIndex={500}
      >
        <Grid
          gutter="md"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
          }}
        >
          <Grid.Col
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: 15,
            }}
          >
            <ThemeIcon variant="outline" radius="xl" color="green">
              <IconClipboardCheck fontSize="large" style={{ fontSize: 70 }} />
            </ThemeIcon>
            <Box>
              <Title order={1}>Success</Title>
              <Title order={3}>
                You are authorized to download the requested file.
              </Title>
            </Box>
          </Grid.Col>

          <Divider />
          <Grid.Col
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button variant="filled" size="md" onClick={handleDownload}>
              Download
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </div>
  );
};

export default DownloadFromMail;

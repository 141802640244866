import { createStyles, keyframes } from "@mantine/core";

const download = keyframes({
  from: {
    transform: "translateY(-20px)",
  },
  to: {
    transform: "translateY(20px)",
    opacity: 0,
  },
});

const useStyles = createStyles(() => ({
  CustomArrow: {
    position: "absolute",
    left: "0",
    right: "0",
    margin: "auto",
    // zIndex: '5',
    top: "10px",
    width: "12px",
    height: "8px",
    background: "#2e439e",
    display: "inline-block",
    "&:after": {
      content: '""',
      position: "absolute",
      left: "-9px",
      top: "100%",
      border: "15px solid transparent",
      borderTopColor: "#2e439e",
    },
    animation: `${download} 1s linear infinite`,
  },
  CustomPaper: {
    position: "relative",
    background: "#f1f1f1",
    height: "25px",
    width: "20px",
    display: "block",
    paddingTop: "5px",
    borderRadius: "3px",

    "&:before": {
      content: '""',
      position: "absolute",
      top: "0",
      right: "0",
      borderWidth: "0 10px 10px 0",
      borderStyle: "solid",
      borderColor: "#a4a6a7 #fff",
    },
  },
  CustomeLine: {
    background: "#e2e2e2",
    width: "80%",
    height: "3px",
    display: "block",
    margin: "3px auto",
    position: "relative",
    overflow: "hidden",
    "&:after": {
      content: '""',
      background: "#2c3033",
      width: "0",
      height: "4px",
      position: "absolute",
      top: "0",
      left: "0",
    },
  },
}));

const DownloadAnimation = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.CustomPaper}>
      <span className={classes.CustomeLine}></span>
      <span className={classes.CustomeLine}></span>
      <span className={classes.CustomeLine}></span>
      <span className={classes.CustomArrow}></span>
    </div>
  );
};

export default DownloadAnimation;

import {
  Loader,
  // Autocomplete,
  // AutocompleteItem,
  // Text,
  Select,
  useMantineTheme,
} from "@mantine/core";
import { IconClipboardList } from "@tabler/icons";
// import { forwardRef } from "react";

type TProps = {
  title: string;
  value: string | null;
  onChange: (value: string) => void;
  dataArray: Record<string, any>[];
  dataLabel: string;
  dataValue: string;
  isLoading: boolean;
  // onItemSubmit: (dataItem: AutocompleteItem) => void; // dataItem={data,value}
};

const AutocompleteCustom = ({
  title,
  onChange,
  value,
  dataArray,
  dataLabel,
  dataValue,
  isLoading,
}: // onItemSubmit,
TProps) => {
  const theme = useMantineTheme();
  // const data = dataArray.map((item) => ({
  //   data: item,
  //   value: item[dataLabel],
  // }));
  // const AutoCompleteItem = forwardRef<HTMLDivElement, any>(
  //   ({ value, ...others }, ref) => (
  //     <div ref={ref} {...others}>
  //       <Text>{value}</Text>
  //     </div>
  //   )
  // );

  const data = dataArray.map((item) => ({
    value: item[dataValue] + "",
    label: item[dataLabel],
  }));

  return (
    <Select
      label={title}
      placeholder="Select"
      nothingFound="No options available"
      data={data}
      icon={<IconClipboardList size={20} color={theme.colors.ffcBrand[6]} />}
      zIndex={500}
      searchable
      allowDeselect={false}
      maxDropdownHeight={300}
      // rightSection={isFetching ? <Loader size="sm" variant="dots" /> : null}
      value={value ?? null}
      onChange={onChange}
      rightSection={isLoading ? <Loader size="sm" variant="dots" /> : null}
      disabled={isLoading}
    />
    // <Autocomplete
    //   label={title}
    //   placeholder="Select"
    //   nothingFound="No options available"
    //   onItemSubmit={onItemSubmit}
    //   itemComponent={AutoCompleteItem}
    //   data={data}
    //   icon={<IconClipboardList size={20} color={theme.colors.ffcBrand[6]} />}
    //   zIndex={500}
    // />
  );
};

export default AutocompleteCustom;

import { Box, Card, Title } from "@mantine/core";

const MainCard = ({
  children,
  title,
  height = 800,
  maxWidth = "100%",
  titleOrder = 3,
  maxHeight,
  sx,
}: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: maxWidth,
        ...sx,
      }}
    >
      <Card
        sx={{ minHeight: height, maxHeight }}
        withBorder
        shadow="sm"
        radius="md"
      >
        {title && (
          <Card.Section withBorder inheritPadding py="lg" mb="sm">
            <Title order={titleOrder}>{title}</Title>
          </Card.Section>
        )}
        {children}
      </Card>
    </Box>
  );
};

export default MainCard;

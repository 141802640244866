import {
  Box,
  Button,
  FileButton,
  Group,
  LoadingOverlay,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useRef } from "react";
import { IconFile, IconFileUpload } from "@tabler/icons";
import { Form, Formik } from "formik";
import { isOracleError } from "../../../helper/common";
import { showCustomNotification } from "../../../helper/customNotification";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadUserDocument } from "./queries";
import { TUserUploadedDoc } from ".";

type TProps = {
  docData: {
    DOC_EMP_CODE: string;
    DOC_TYPE_CODE: string;
    DOC_NO: string;
    DOC_EXP_DT: Date;
  };
  empCode: string;
  empCompCode: string;
};

// type TResponseSuccess = {
//   status: true;
//   data: string;
// };

// type TResponseError = {
//   status: false;
//   message: string;
// };

// type TResponse = TResponseSuccess | TResponseError;

type TFormValues = {
  doc: File | null;
  day: string;
  month: string;
  year: string;
};

const DocUpload = ({ docData, empCode, empCompCode }: TProps) => {
  const theme = useMantineTheme();
  const {
    authUser: { user },
  }: RootStateOrAny = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const resetFileRef = useRef<() => void>(null);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(uploadUserDocument, {
    onSuccess: (resUrl, props) => {
      try {
        props.resetForm();
        resetFileRef?.current?.();
      } catch {}
      queryClient.setQueryData<TUserUploadedDoc[]>(
        ["userDocuments", empCode, empCompCode],
        (oldData) => {
          return oldData?.map((data) =>
            data.DOC_NO === docData.DOC_NO &&
            data.DOC_TYPE_CODE === docData.DOC_TYPE_CODE
              ? { ...data, DOC_URL: resUrl }
              : data
          );
        }
      );
    },
    onError(error: any) {
      console.log(error);
      if (isOracleError(error.message)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Oracle Error",
            message: error.message,
            type: "ORACLE_ERROR",
          },
        });
      } else {
        showCustomNotification({
          title: "Error",
          message: error.message,
          notifyType: "ERROR",
        });
      }
    },
  });

  const initialValues: TFormValues = {
    doc: null,
    day: "",
    month: "",
    year: "",
  };
  const inputRefs = useRef<{
    month: HTMLInputElement | null;
    year: HTMLInputElement | null;
  }>({
    month: null,
    year: null,
  });

  const validateForm = (values: TFormValues) => {
    const errors: any = {};
    // date format MM/DD/YYYY is for validation only
    const expiryDate = values.month + "/" + values.day + "/" + values.year;
    const currentDocDate = dayjs(docData.DOC_EXP_DT);
    if (!values.doc) {
      errors.doc = "select document to upload";
    } else if (
      !["image/png", "image/jpg", "image/jpeg"].includes(values.doc.type)
    ) {
      errors.doc = "only images are allowed";
    }
    if (!dayjs(expiryDate, "MM/DD/YYYY", true).isValid()) {
      errors.year = "enter a valid date";
    } else if (currentDocDate.diff(expiryDate, "d") !== 0) {
      errors.year = "document expiry date does not match";
    }

    return errors;
  };

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        // validateOnChange={true}
        onSubmit={async (values, { resetForm }) => {
          try {
            if (!values.doc) return;
            const formValues = {
              P_Emp_Code: docData.DOC_EMP_CODE,
              P_Doc_Type: docData.DOC_TYPE_CODE,
              P_Doc_No: docData.DOC_NO,
              P_User: user,
            };
            const formData = new FormData();
            formData.append("formValues", JSON.stringify(formValues));
            formData.append("docToUpload", values.doc);
            mutate({ formData, resetForm });
          } catch (error: any) {
            console.log(error);
          }
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Group position="left" align="end" spacing="xl">
              <div>
                <FileButton
                  resetRef={resetFileRef}
                  onChange={(file) => setFieldValue("doc", file)}
                  accept="image/png,image/jpg,image/jpeg"
                >
                  {(props) => (
                    <Button {...props} leftIcon={<IconFile size={20} />}>
                      {values.doc?.name ?? "Select Doc"}
                    </Button>
                  )}
                </FileButton>

                {errors.doc && (
                  <Text sx={{ color: theme.colors.red }}>{errors.doc}</Text>
                )}
              </div>

              <div>
                <Text size="sm">Date of expiry (DD/MM/YYYY)</Text>
                <span
                  style={{
                    border:
                      errors.day && touched.day
                        ? "1px solid #cf2229"
                        : "1px solid #bbbbbb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    name="day"
                    placeholder="DD"
                    maxLength={2}
                    value={values.day}
                    onChange={(event) => {
                      if (isNaN(Number(event.target.value))) return;
                      setFieldValue("day", event.target.value);
                      if (
                        event.target.value.length > 1 &&
                        !values.year &&
                        inputRefs.current.month
                      ) {
                        inputRefs.current.month.focus();
                      }
                    }}
                    style={{
                      border: 0,
                      outlineStyle: "none",
                      width: "3.5rem",
                    }}
                    id="day"
                    onBlur={handleBlur}
                  />
                  <span>/</span>
                  <input
                    ref={(element) => (inputRefs.current.month = element)}
                    type="text"
                    name="month"
                    placeholder="MM"
                    maxLength={2}
                    value={values.month}
                    onChange={(event) => {
                      if (isNaN(Number(event.target.value))) return;
                      setFieldValue("month", event.target.value);
                      if (
                        event.target.value.length > 1 &&
                        !values.year &&
                        inputRefs.current.year
                      ) {
                        // validateForm();
                        inputRefs.current.year.focus();
                      }
                    }}
                    id="month"
                    onBlur={handleBlur}
                    style={{
                      border: 0,
                      outlineStyle: "none",
                      width: "3.5rem",
                    }}
                  />
                  <span>/</span>
                  <input
                    ref={(element) => (inputRefs.current.year = element)}
                    type="text"
                    name="year"
                    placeholder="YYYY"
                    maxLength={4}
                    value={values.year}
                    onChange={(event) => {
                      if (isNaN(Number(event.target.value))) return;
                      setFieldValue("year", event.target.value);
                      // validateForm();
                    }}
                    id="year"
                    onBlur={handleBlur}
                    style={{
                      border: 0,
                      outlineStyle: "none",
                      width: "3.5rem",
                    }}
                  />
                </span>
                {touched.year && errors.year && (
                  <Text sx={{ color: theme.colors.red }}>{errors.year}</Text>
                )}
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                <Button type="submit" leftIcon={<IconFileUpload size={20} />}>
                  Upload
                </Button>
              </Box>
            </Group>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DocUpload;

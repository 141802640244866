import { Select } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { TInputField } from "../../typings";

const ParamSelect = ({
  id,
  // paramKey,
  inputField,
  formik,
  handleModalOpen,
  setValidateParam,
}: {
  id: string;
  // paramKey: string;
  inputField: TInputField;
  formik: any;
  handleModalOpen?: any;
  setValidateParam?: any;
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const existingControls = ["DT_PCKR", "DRWN", "S_DRWN", "TXT","FL_UPLOAD"];
  const options = useMemo(() => {
    const controls:
      | { label: string; value: string; code: string }[]
      | undefined = queryClient.getQueryData(["uiConfig", "controlOptions"]);
    const control = controls?.find((ctrl) => ctrl.code === "DT_PCKR");
    if (!(inputField.fieldName === "value_type")) {
      return inputField.fieldOptions;
    }
    if (!formik.values.control_id) return [];

    if (formik.values.control_id + "" === control?.value + "") {
      return [
        { label: "STATIC", value: "STATIC" },
        { label: "CURRENT", value: "CURRENT" },
      ];
    }
    return [
      { label: "STATIC", value: "STATIC" },
      { label: "DYNAMIC", value: "DYNAMIC" },
    ];
  }, [
    formik.values.control_id,
    inputField.fieldName,
    inputField.fieldOptions,
    queryClient,
  ]);

  // const handleChange = (event: SelectChangeEvent) // select component event
  const handleChange = (value: string | null) => {
    // * Clear parm_val if value_type or control_id is changed -- Ui Settings(Params)
    if (inputField.fieldName === "value_type") {
      if (value !== formik.values[inputField.fieldName] + "") {
        formik.setFieldValue("parm_val", "");
      }
      if (value && value !== "CURRENT") {
        handleModalOpen(value);
      }
    } else if (inputField.fieldName === "control_id") {
      const controls:
        | { label: string; value: string; code: string }[]
        | undefined = queryClient.getQueryData(["uiConfig", "controlOptions"]);
      const control = controls?.find((ctrl) => ctrl.value === value);
      if (value && control && !existingControls.includes(control.code)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Alert",
            message:
              "The control selected is not able to access at the moment. It is likely to be recently created control. Please contact the administrator to add this control to your application.",
            type: "WARNING",
          },
        });
        return;
      }
      formik.setFieldValue("parm_val", "");
      formik.setFieldValue("value_type", "");
    }

    formik.setFieldValue(inputField.fieldName, value);
  };
  return (
    <Select
      id={id}
      data={options ?? []}
      value={
        formik.values[inputField.fieldName]
          ? formik.values[inputField.fieldName] + ""
          : null
      }
      label={inputField.fieldLabel}
      nothingFound="No options"
      onChange={handleChange}
      onBlur={() => {
        formik.setFieldTouched(inputField.fieldName);
        if (
          inputField.fieldName === "param" ||
          inputField.fieldName === "sparam" ||
          inputField.fieldName === "oparam" ||
          inputField.fieldName === "order" ||
          inputField.fieldName === "oorder"
        )
          setValidateParam((prev: boolean) => !prev);
      }}
      error={
        !!(
          formik.touched &&
          formik.touched[inputField.fieldName] &&
          formik.errors &&
          formik.errors[inputField.fieldName]
        ) && formik.errors[inputField.fieldName]
      }
    />
    // <FormControl fullWidth>
    //   <InputLabel id={`${id}-label`}>{inputField.fieldLabel}</InputLabel>
    //   <Select
    //     labelId={`${id}-label`}
    //     id={id}
    //     value={formik.values[inputField.fieldName]}
    //     label={inputField.fieldLabel}
    //     onChange={handleChange}
    //   >
    //     {inputField.fieldOptions?.length ? (
    //       inputField.fieldOptions?.map((option) => (
    //         <MenuItem key={option.value} value={option.value}>
    //           {option.label}
    //         </MenuItem>
    //       ))
    //     ) : (
    //       <MenuItem value="">No options available</MenuItem>
    //     )}
    //   </Select>
    //   <FormHelperText>
    //     {formik.errors && formik.errors[inputField.fieldName]}
    //   </FormHelperText>
    //   {/* <Select
    //     labelId={`${id}-label`}
    //     id={id}
    //     value={formik.values[paramKey][inputField.fieldName]}
    //     label={inputField.fieldLabel}
    //     onChange={handleChange}
    //   >
    //     {inputField.fieldOptions?.length ? (
    //       inputField.fieldOptions?.map((option) => (
    //         <MenuItem key={option.value} value={option.value}>
    //           {option.label}
    //         </MenuItem>
    //       ))
    //     ) : (
    //       <MenuItem value="">No options available</MenuItem>
    //     )}
    //   </Select> */}
    // </FormControl>
  );
};

export default ParamSelect;

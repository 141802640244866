import { LoadingOverlay } from "@mantine/core";

const Loader = ({ isLoading }: { isLoading: boolean }) => (
  <LoadingOverlay
    visible={isLoading}
    loaderProps={{ size: "xl" }}
    overlayOpacity={0.7}
    overlayColor="#000"
    zIndex={2000}
  />
);

export default Loader;

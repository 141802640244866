import {
  ThemeIcon,
  UnstyledButton,
  Group,
  useMantineTheme,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TNavMenu } from "../../../menu-items";
import IconRetrieve from "../../../components/MenuIcons/IconRetrieve"

const NavMenuItem = ({
  menuItem,
  level,
}: {
  menuItem: TNavMenu;
  level: number;
}) => {
  const theme = useMantineTheme();
  const Icon = menuItem.icon;
  const location = useLocation();

  return (
    <UnstyledButton
      component={Link}
      to={menuItem.url}
      sx={{
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        // borderRadius: theme.radius.sm,
        color: theme.white,
        paddingLeft: `${level * theme.spacing.sm}px`,
        backgroundColor:
          location.pathname === menuItem.url
            ? theme.colors.indigo[6]
            : theme.colors.ffcBrand[7],
        "&:hover": {
          backgroundColor: theme.colors.indigo[8],
        },
        "&:active": {
          backgroundColor: theme.colors.indigo[7],
        },
      }}
    >
      <Group>
        <ThemeIcon>
          {/*<Icon size={20} color={theme.white} />*/}
            <IconRetrieve icon={menuItem.icon}  />
        </ThemeIcon>
        <div style={{ display: "inline", fontSize: 14 }}>{menuItem.title}</div>
      </Group>
    </UnstyledButton>
  );
};

export default NavMenuItem;

import { Avatar, Group, Stack, Text, Title } from "@mantine/core";
type TProps = {
  name: string | null;
  designation: string | null;
  department: string | null;
  joinedDate: string | null;
  imageUrl: string | null;
};

const UserImageHeader = ({
  name,
  designation,
  department,
  joinedDate,
  imageUrl,
}: TProps) => {
  return (
    <Group
      spacing={50}
      sx={(theme) => ({
        width: "100%",
        color: theme.colors[theme.primaryColor][6],
      })}
      my="lg"
    >
      <Avatar
        sx={{
          width: 150,
          height: 150,
          borderRadius: "50%",
        }}
        alt="Profile Image"
        src={imageUrl}
        size="xl"
      >
        {name?.substring(0, 1)}
      </Avatar>
      <Stack spacing={2} sx={{ flex: 1 }}>
        <Title order={2} weight="bold">
          {name ?? ""}
        </Title>
        <Text
          size="xl"
          sx={(theme) => ({
            color: theme.colors[theme.primaryColor][6],
          })}
        >
          {designation ?? ""}
        </Text>
        <Text
          size="sm"
          sx={(theme) => ({
            color: theme.colors[theme.primaryColor][6],
          })}
        >
          {`${department} - Since ${joinedDate}`}
        </Text>
      </Stack>
    </Group>
  );
};

export default UserImageHeader;

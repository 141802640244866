import toast from "react-hot-toast";
import { api } from "../../../api";
import { generateRandomString } from "../../../helper/common";

export const getReportLogs = async (user: string) => {
  const payload = {
    P_User: user,
    P_Rep_Id: null,
    P_Disp_Param: null,
    P_Bind_Param: null,
    P_File_Path: null,
    P_Excel_Path: null,
  };

  const response = await api.post("report/log", undefined, payload);
  if (!response.status) throw new Error(response.message);

  return response.data;
};

const getProgressDetails = (doc: any) => {
  const id = generateRandomString(6);
  const labels = Object.values(JSON.parse(doc.CRLOG_DISP_PARAM));
  const progressDetails = {
    id,
    reportName: `${doc.CREP_NAME} (from report logs)`,
    reportDetails: labels.join(" | "),
    message: "",
  };
  return progressDetails;
};

export const downloadLink = async (fileLink: string) => {
  const fileName = fileLink.split("/").pop();
  fetch(fileLink).then((resp) => {
    resp.blob().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName || "document";
      link.click();
    });
  });
};

const downloadDocByGeneratingReport = async ({
  doc,
  dispatch,
  authUser,
  progressDetails,
}: any) => {
  try {
    dispatch({ type: "START", payload: progressDetails });
    toast(`Started to generate ${progressDetails.reportName}`, {
      icon: "📄",
      duration: 6000,
      style: {
        border: "1px solid #252b83",
        padding: "10px",
        backgroundColor: "#252b83",
        color: "#FFFAEE",
      },
    });
    let bind_values = {
      P_Comp_Code: authUser.company_code,
      P_Usr_Id: authUser.user,
      ...JSON.parse(doc.CRLOG_BIND_PARAM),
    };
    let generateReportPayload: any = {
      rep_id: doc.CREP_ID,
      // query: doc.REP_SP,
      // bind_params: doc.REP_PARAM,
      bind_values,
      reportName: doc.CREP_NAME,
      logReportParams: doc,
    };
    const docType = doc.isPdf ? "pdf" : "excel";
    const result = await api.post(
      `report/log/export/${docType}`,
      undefined,
      generateReportPayload
    );
    if (!result.status) throw new Error(result.message);

    await downloadLink(result.fileLink);
    dispatch({ type: "SUCCESS", payload: progressDetails });
    return result.fileLink;
  } catch (error: any) {
    dispatch({
      type: "FAILED",
      payload: { progressDetails, message: error.message },
    });
    return error;
    // console.error("log pdf export by report generate error - ", error);
  }
};

export const downloadDoc = async ({ doc, dispatch, authUser }: any) => {
  let docFileLink = doc.isPdf ? doc.CRLOG_FILE_PATH : doc.CRLOG_EXCEL_PATH;
  const progressDetails = getProgressDetails(doc);
  const cacheLink = fetch(docFileLink).then(async (resp) => {
    if (docFileLink && resp.ok) {
      dispatch({ type: "START", payload: progressDetails });
      // toast(`Started to generate ${progressDetails.reportName}`, {
      //   icon: "📄",
      //   style: {
      //     border: "1px solid #252b83",
      //     padding: "10px",
      //     color: "#252b83",
      //   },
      // });
      resp.blob().then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        const docFileName = docFileLink.substring(
          docFileLink.lastIndexOf("/") + 1
        );
        link.download = docFileName;
        link.click();
      });
      dispatch({ type: "SUCCESS", payload: progressDetails });
      return docFileLink;
    }
    const fileLink = await downloadDocByGeneratingReport({
      doc,
      dispatch,
      authUser,
      progressDetails,
    });
    if (fileLink instanceof Error) throw new Error(fileLink.message);
    return fileLink;
  });
  return cacheLink;
};

import {MantineThemeOverride} from "@mantine/core";

export const customMantineTheme: MantineThemeOverride = {
  fontFamily: "Roboto, sans-serif",
  headings: { fontFamily: "Roboto, sans-serif" },
  colors: {
    ffcBrand: [
      "#e9edff",
      "#c1c8f3",
      "#99a4e5",
      "#717fda",
      "#4a5bcf",
      "#3041b5",
      "#25338e",
      "#1a2466",
      "#0d163f",
      "#03071a",
    ],
  },
  primaryColor: "ffcBrand",
  other: {
    secondaryColor: "teal",
    activeColor: "cyan",
    textColor: ["#101113"],
    success: ["#157347"],
    info: ["#31d2f2"],
    warning: ["#ffca2c"],
    danger: ["#dc3545"],
  },
  components: {
    Button: {
      styles: {
        root: {
          padding: "0 40px",
        },
      },
    },
    Title: {
      styles: (theme) => ({
        root: {
          color: theme.colors.ffcBrand[7],
        },
      }),
    },
    Text: {
      styles: (theme) => ({
        root: {
          color: theme.colors.ffcBrand[6],
        },
      }),
    },
    Select: {
      styles: (theme) => ({
        label: {
          color: theme.colors.ffcBrand[6],
        },
        input: {
          color: theme.colors.ffcBrand[6],
        },
      }),
    },
    TimeInput: {
      styles: (theme) => ({
        label: {
          color: theme.colors.ffcBrand[6],
        },
        input: {
          color: theme.colors.ffcBrand[6],
        },
      }),
    },
    Autocomplete: {
      styles: (theme) => ({
        label: {
          color: theme.colors.ffcBrand[6],
        },
        input: {
          color: theme.colors.ffcBrand[6],
        },
      }),
    },
    DatePicker: {
      styles: (theme) => ({
        label: {
          color: theme.colors.ffcBrand[6],
        },
        input: {
          color: theme.colors.ffcBrand[6],
        },
      }),
    },
    Menu: {
      styles: (theme) => ({
        itemLabel: {
          color: theme.colors.ffcBrand[6],
        },
        itemIcon: {
          color: theme.colors.ffcBrand[6],
        },
      }),
    },
  },
};

// components example //
// {
//     Title: {
//       styles: (theme) => ({
//         root: {
//           color: theme.colors.ffcBrand[7],
//         },
//       }),
//     },
//     Text: {
//       styles: (theme) => ({
//         root: {
//           color: theme.colors.ffcBrand[6],
//         },
//       }),
//     },

//     Button: {
//       styles: (theme, params: ButtonStylesParams) => ({
//         root: {
//           height: 42,
//           padding: "0 30px",
//           backgroundColor:
//             params.variant === "filled"
//               ? theme.colors[params.color || theme.primaryColor][9]
//               : undefined,
//         },
//         filled: {
//           color: theme.colors[params.color || theme.primaryColor][1],
//           "&:hover": {
//             backgroundColor: theme.fn.darken(
//               theme.colors[params.color || theme.primaryColor][1],
//               0.5
//             ),
//           },
//         },
//         outline: {
//           "&:hover": {
//             backgroundColor:
//               theme.colorScheme === "dark"
//                 ? theme.colors.dark[8]
//                 : theme.colors.gray[0],
//           },
//         },
//       }),
//     },

//     Badge: {
//       styles: {
//         root: { borderWidth: 2 },
//       },
//     },
//}

import { useEffect, useMemo, useState } from "react";
import React from "react";
import dayjs from "dayjs";

import {
    Box,
    Button,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    Text, Card, Tabs, Stack,
    Title, Paper
} from "@mantine/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';
import InfoRow from "../../../components/InfoRow/InfoRow";
import { Route, Link, Routes, useParams } from 'react-router-dom';
import MainCard from "../../../components/Cards/MainCard";
import { api } from "../../../api";

const SupplierView = () => {
    const params = useParams();
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [pendingListData, setPendingListData] = useState<any>('');
    const [pendingDocs, setPendingDocs] = useState<any>([]);
    const [pendingContacts, setPendingContacts] = useState<any>([]);

    const getPendingList = async (id: any) => {
        try {
            setLoading(true)
            const payload = {
                status: 'O',
                supp_req_id: id
            };
            const response = await api.post("supplier/pendingList", undefined, payload);
            if (response.status == true) {
                setLoading(false)
                setPendingListData(response.data[0]);
            }
        } catch (error: any) {
            // console.log(error);
        }
    };

    const getPendingDocs = async (id: any) => {
        try {
            setLoading(true)
            const payload = {
                supp_req_id: id
            };
            const response = await api.post("supplier/PendingDocs", undefined, payload);
            if (response.status == true) {
                setLoading(false)
                setPendingDocs(response.data);
            }
        } catch (error: any) {
            // console.log(error);
        }
    };

    const getPendingContacts = async (id: any) => {
        try {
            setLoading(true)
            const payload = {
                supp_req_id: id
            };
            const response = await api.post("supplier/PendingContacts", undefined, payload);
            if (response.status == true) {
                setLoading(false)
                setPendingContacts(response.data);
            }
        } catch (error: any) {
            // console.log(error);
        }
    };
    const handleDownload = (path: any) => {
        const fileName: any = path.split("/").pop();
        fetch(path).then((resp) => {
            resp.blob().then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            });
        });
        return;
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [

            // {
            //     accessorKey: 'SUPPRDOC_ID',
            //     header: ' Id',
            //     enableHiding: true,
            // },
            {
                accessorKey: 'SUPPRDOC_DOC_NO',
                header: ' DOC NO',
            },
            {
                accessorKey: 'SUPPRDOC_DOC_TYPE',
                header: 'Doc Type',
            },

            {
                accessorKey: 'File',
                header: 'File',
                Cell: ({ cell, table, row }) => {
                    var SUPPRDOC_DOC_DESC: any = row.original['SUPPRDOC_DOC_DESC'] ? row.original['SUPPRDOC_DOC_DESC'] : ''
                    return (<div>
                        {SUPPRDOC_DOC_DESC && (
                            <Button onClick={(value: any) => {
                                handleDownload(SUPPRDOC_DOC_DESC)
                            }}>Download</Button>)}

                    </div>)
                },
            },
        ],
        [],
    );


    const columns1 = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'SUPPRD_REPRESENTATIVE',
                header: 'Name',

            },
            // {
            //     accessorKey: 'SUPPRD_ID',
            //     header: ' Id',
            //     enableHiding: true,
            // },

            {
                accessorKey: 'Status',
                header: 'Phone',
                Cell: ({ cell, table, row }) => {
                    var Tel: any = row.original['SUPPRD_TEL_NO'] ? row.original['SUPPRD_TEL_NO'] : ''
                    var Mobile: any = row.original['Local SUPPRD_MOB_NO'] ? row.original['SUPPRD_MOB_NO'] : ''
                    var Fax: any = row.original['Local SUPPRD_FAX_NO'] ? row.original['SUPPRD_FAX_NO'] : ''
                    return (<div>
                        {Tel && (
                            <Text fw={500}> T: {Tel}</Text>)}
                        {Mobile && (
                            <Text fw={500}>m: {Mobile}</Text>)}
                        {Fax && (
                            <Text fw={500}>F: {Fax}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'SUPPRD_REP_DESIG',
                header: 'Role',
            },

            {
                accessorKey: 'SUPPRD_EMAIL_ID',
                header: 'Email',
            },
        ],
        [],
    );

    useEffect(() => {
        window.scrollTo(0, 0)
        //   setLoading(true)
        getPendingList(id)
        getPendingDocs(id)
        getPendingContacts(id)
    }, []);

    return (
        <>
            <MainCard title="Supplier View">
                <Card shadow="sm" radius="md" withBorder mt={'30px'}>


                    <Tabs defaultValue="gallery">
                        <Tabs.List>
                            <Tabs.Tab value="gallery">Details</Tabs.Tab>
                            <Tabs.Tab value="messages" >Contacts & Docs</Tabs.Tab>
                            {/*<Tabs.Tab value="settings">Settings</Tabs.Tab>*/}
                        </Tabs.List>

                        <Tabs.Panel value="gallery" pt="xs">


                            <Grid>
                                <Grid.Col xs={12} md={10}>

                                </Grid.Col>

                                <Grid.Col xs={12} md={2}>
                                    <Button type="submit" sx={{ textAlign: 'right' }}>
                                        {pendingListData.SUPPRH_STATUS ? pendingListData.SUPPRH_STATUS : 'Pending'}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                            <Grid sx={{ marginTop: '10px' }}>


                                <Grid.Col xs={12} md={4}>
                                    <Paper px="xl" py={5} shadow="sm" radius="md" sx={{ height: "100%" }}>
                                        <Stack>
                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                                                    size="lg"
                                                    my="sm"
                                                >
                                                    Business Information
                                                </Title>


                                                <InfoRow title="Number"
                                                    values={[pendingListData.SUPPRH_NO ? pendingListData.SUPPRH_NO : "-"]} />
                                                <InfoRow title="Name"
                                                    values={[pendingListData.SUPPRH_SUPP_NAME ? pendingListData.SUPPRH_SUPP_NAME : "-"]} />
                                                <InfoRow title="Supplier Type"
                                                    values={[pendingListData.SUPPRH_SUPP_TYPE ? pendingListData.SUPPRH_SUPP_TYPE : "-"]} />
                                                <InfoRow title="Username"
                                                    values={[pendingListData.SUPPRH_USER ? pendingListData.SUPPRH_USER : "-"]} />
                                                <InfoRow title="Password"
                                                    values={[pendingListData.SUPPRH_PWD ? pendingListData.SUPPRH_PWD : "-"]} />
                                                <InfoRow title="Sponsor"
                                                    values={[pendingListData.SUPPPRH_SPONSOR ? pendingListData.SUPPPRH_SPONSOR : "-"]} />
                                                <InfoRow title="Pay Mode"
                                                    values={[pendingListData.SUPPRH_PAY_MODE ? pendingListData.SUPPRH_PAY_MODE : "-"]} />
                                                <InfoRow title="Cr No Exp Date"
                                                    values={[pendingListData.SUPPRH_CRNO_EXP_DT ? dayjs(pendingListData.SUPPRH_CRNO_EXP_DT).format("DD-MMM-YY") : "-"]} />
                                                <InfoRow title="Lic No Exp Date"
                                                    values={[pendingListData.SUPPRH_LICNO_EXP_DT ? dayjs(pendingListData.SUPPRH_LICNO_EXP_DT).format("DD-MMM-YY") : "-"]} />
                                                <InfoRow title="Lic No"
                                                    values={[pendingListData.SUPPRH_LIC_NO ? pendingListData.SUPPRH_LIC_NO : "-"]} />
                                                <InfoRow title="Email"
                                                    values={[pendingListData.SUPPRH_EMAIL_ID ? pendingListData.SUPPRH_EMAIL_ID : "-"]} />
                                                <InfoRow title="Telephone"
                                                    values={[pendingListData.SUPPRH_SUPP_TEL1 ? pendingListData.SUPPRH_SUPP_TEL1 : "-"]} />

                                                <InfoRow title="Telephone" values={[pendingListData.SUPPRH_SUPP_TEL2 ? pendingListData.SUPPRH_SUPP_TEL2 : "-"]} />
                                                <InfoRow title="Cr Number" values={[pendingListData.SUPPRH_SUPP_CR_NO ? pendingListData.SUPPRH_SUPP_CR_NO : "-"]} />
                                                <InfoRow title="Sponsor Id" values={[pendingListData.SUPPRH_DOC_SPONSOR_ID ? pendingListData.SUPPRH_DOC_SPONSOR_ID : "-"]} />
                                            </Box>

                                            <Box>
                                                <Title sx={(theme) => ({ color: theme.colors.gray[7] })} size="lg" my="sm">Address </Title>
                                                <InfoRow title="Address"
                                                    values={[pendingListData.SUPPRH_SUPP_ADDRESS1 ? pendingListData.SUPPRH_SUPP_ADDRESS1 : "", pendingListData.SUPPRH_SUPP_ADDRESS2 ? pendingListData.SUPPRH_SUPP_ADDRESS2 : ""]}
                                                />
                                                <InfoRow title="City" values={[pendingListData.SUPPRH_SUPP_CITY ? pendingListData.SUPPRH_SUPP_CITY : "-"]} />
                                                <InfoRow title="Area" values={[pendingListData.SUPPRH_SUPP_AREA_CODE ? pendingListData.SUPPRH_SUPP_AREA_CODE : "-"]} />
                                                <InfoRow title="City" values={[pendingListData.SUPPRH_CITY ? pendingListData.SUPPRH_CITY : "-"]} />

                                            </Box>


                                        </Stack>
                                    </Paper>
                                </Grid.Col>

                                <Grid.Col xs={12} md={4}>
                                    <Paper px="xl" py={5} shadow="sm" radius="md" sx={{ height: "100%" }}>
                                        <Stack>
                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })} size="lg"
                                                    my="sm"
                                                >
                                                    Bank Details
                                                </Title>
                                                <InfoRow title="Ac Name"
                                                    values={[pendingListData.SUPPRH_BANK_NAME ? pendingListData.SUPPRH_BANK_NAME : "-"]} />
                                                <InfoRow title="Account No"
                                                    values={[pendingListData.SUPPRH_AC_NO ? pendingListData.SUPPRH_AC_NO : "-"]} />
                                                <InfoRow title="Branch"
                                                    values={[pendingListData.SUPPRH_BRANCH_NAME ? pendingListData.SUPPRH_BRANCH_NAME : "-"]} />
                                                <InfoRow title="Code"
                                                    values={[pendingListData.SUPPRH_BANK_CODE ? pendingListData.SUPPRH_BANK_CODE : "-"]} />
                                                <InfoRow title="Iban No"
                                                    values={[pendingListData.SUPPRH_BANK_IBAN ? pendingListData.SUPPRH_BANK_IBAN : "-"]} />
                                                <InfoRow title="Acc Type"
                                                    values={[pendingListData.SUPPRH_INTER_BANK ? pendingListData.SUPPRH_INTER_BANK : "-"]} />
                                                <InfoRow title="Curr Code"
                                                    values={[pendingListData.SUPPRH_CURR_CODE ? pendingListData.SUPPRH_CURR_CODE : "-"]} />
                                                <InfoRow title="Swift Code"
                                                    values={[pendingListData.SUPPRH_SWIFT_CODE ? pendingListData.SUPPRH_SWIFT_CODE : "-"]} />

                                                <InfoRow
                                                    title="Address"
                                                    values={[pendingListData.SUPPRH_BANK_ADDRESS1 ? pendingListData.SUPPRH_BANK_ADDRESS1 : "-",
                                                    pendingListData.SUPPRH_BANK_ADDRESS2 ? pendingListData.SUPPRH_BANK_ADDRESS2 : "-",
                                                    pendingListData.SUPPRH_BANK_ADDRESS3 ? pendingListData.SUPPRH_BANK_ADDRESS3 : "-"]}
                                                />

                                                <InfoRow title="Country"
                                                    values={[pendingListData.SUPPRH_COUNTRY ? pendingListData.SUPPRH_COUNTRY : "-"]} />
                                            </Box>

                                        </Stack>
                                    </Paper>

                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                    <Paper px="xl" py={5} shadow="sm" radius="md" sx={{ height: "100%" }}>
                                        <Stack>


                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                                                    size="lg"
                                                    my="sm"
                                                >
                                                    Basic Terms of the Agreement
                                                </Title>
                                                <InfoRow title="Cr No"
                                                    values={[pendingListData.SUPPRH_CR_NO ? pendingListData.SUPPRH_CR_NO : "-"]} />
                                                {/*<InfoRow title="Created User" values={[pendingListData.SUPPRH_CR_USER ?pendingListData.SUPPRH_CR_USER: "-"]} />*/}
                                                {/*<InfoRow title="Created Date" values={[pendingListData.SUPPRH_CR_DT ? dayjs( pendingListData.SUPPRH_CR_DT).format("DD-MMM-YY") : "-"]} />*/}
                                                {/*<InfoRow title="Edit User" values={[pendingListData.SUPPRH_EDT_USER ?pendingListData.SUPPRH_EDT_USER: "-"]} />*/}
                                                {/*<InfoRow title="Edit Date" values={[pendingListData.SUPPRH_EDT_DT ? dayjs( pendingListData.SUPPRH_EDT_DT).format("DD-MMM-YY") : "-"]} />*/}
                                                <InfoRow title="Cr Days"
                                                    values={[pendingListData.SUPPRH_CR_DAYS ? pendingListData.SUPPRH_CR_DAYS : "-"]} />
                                                <InfoRow title="Cr Limit"
                                                    values={[pendingListData.SUPPRH_CR_LIMIT ? pendingListData.SUPPRH_CR_LIMIT : "-"]} />
                                                {/* <InfoRow title="Rebate"
                                            values={[pendingListData.SUPPRH_REBATE ? pendingListData.SUPPRH_REBATE : "-"]} /> */}
                                                <InfoRow title="Cr Terms"
                                                    values={[pendingListData.SUPPRH_CR_TERMS ? pendingListData.SUPPRH_CR_TERMS : "-"]} />
                                                <InfoRow title="Other Terms"
                                                    values={[pendingListData.SUPPRH_OTH_TERMS ? pendingListData.SUPPRH_OTH_TERMS : "-"]} />

                                                <InfoRow title="Cr Amt"
                                                    values={[pendingListData.SUPPRH_CR_AMT ? pendingListData.SUPPRH_CR_AMT : "-"]} />

                                                <InfoRow title="Created Date" values={[pendingListData.SUPPRH_CR_DT ? dayjs(pendingListData.SUPPRH_CR_DT).format("DD-MMM-YY") : "-"]} />

                                                <InfoRow title="Status" values={[pendingListData.SUPPRH_STATUS ? pendingListData.SUPPRH_STATUS : "-"]} />

                                                {/* <InfoRow title="Order Amt"
                                            values={[pendingListData.SUPPRH_ORD_AMT ? pendingListData.SUPPRH_ORD_AMT : "-"]} /> */}

                                            </Box>


                                        </Stack>
                                    </Paper>
                                </Grid.Col>


                            </Grid>


                        </Tabs.Panel>

                        <Tabs.Panel value="messages" pt="xs">


                            <Grid>
                                <Grid.Col xs={12} md={10}>

                                </Grid.Col>

                                <Grid.Col xs={12} md={2}>
                                    <Button type="submit" sx={{ textAlign: 'right' }}>
                                        {pendingListData.SUPPRH_STATUS ? pendingListData.SUPPRH_STATUS : 'Pending'}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                            <Grid sx={{ marginTop: '10px' }}>



                                <Grid.Col xs={12} md={6}>



                                    <Box>
                                        <Title
                                            sx={(theme) => ({ color: theme.colors.gray[7] })}
                                            size="lg"
                                            my="sm"
                                        >
                                            Contacts
                                        </Title>

                                        {pendingContacts && (


                                            <ThemeProvider theme={createTheme({})}>
                                                <MaterialReactTable
                                                    muiTableBodyProps={{
                                                        sx: (theme) => ({
                                                            '& tr:nth-of-type(odd)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                            '& tr:nth-of-type(even)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                        }),
                                                    }}

                                                    muiTableHeadCellProps={{
                                                        sx: (theme) => ({
                                                            backgroundColor: '#fff', color: 'green', fontSize: '14px'
                                                        }),
                                                    }}
                                                    columns={columns1}
                                                    data={pendingContacts}
                                                    enableColumnActions={false}
                                                    enableColumnFilters={false}
                                                    enableSorting={false}
                                                    enableTopToolbar={false}
                                                    enableBottomToolbar={false}
                                                    muiTableBodyRowProps={{ hover: false }}
                                                    initialState={{
                                                        pagination: { pageIndex: 0, pageSize: 10 },
                                                        columnVisibility: { redemption_id: false }
                                                    }}
                                                />
                                            </ThemeProvider>


                                        )}


                                    </Box>

                                    <Box mt={'50px'}>
                                        <Title
                                            sx={(theme) => ({ color: theme.colors.gray[7] })}
                                            size="lg"
                                            my="sm"
                                        >
                                            Documents
                                        </Title>
                                        {pendingDocs && (



                                            <ThemeProvider theme={createTheme({})}>
                                                <MaterialReactTable
                                                    muiTableBodyProps={{
                                                        sx: (theme) => ({
                                                            '& tr:nth-of-type(odd)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                            '& tr:nth-of-type(even)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                        }),
                                                    }}

                                                    muiTableHeadCellProps={{
                                                        sx: (theme) => ({
                                                            backgroundColor: '#fff', color: 'green', fontSize: '14px'
                                                        }),
                                                    }}
                                                    columns={columns}
                                                    data={pendingDocs}
                                                    enableColumnActions={false}
                                                    enableColumnFilters={false}
                                                    enableSorting={false}
                                                    enableBottomToolbar={false}
                                                    enableTopToolbar={false}
                                                    muiTableBodyRowProps={{ hover: false }}
                                                    initialState={{

                                                        columnVisibility: { redemption_id: false }
                                                    }}
                                                />
                                            </ThemeProvider>


                                        )}


                                    </Box>


                                </Grid.Col>

                            </Grid>

                        </Tabs.Panel>
                    </Tabs>

                </Card>
            </MainCard>
        </>
    );
};

export default SupplierView;

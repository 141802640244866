import {combineReducers} from "redux";

// reducer import
import authReducer from "./authReducer";
import reportGenerationReducer from "./reportGenerationReducer";
import snackbarReducer from "./snackbarReducer";
import menuStateSlice from "./menuStateSlice";

const reducer = combineReducers({
    auth: authReducer,
    reportGeneration: reportGenerationReducer,
    snackbarState: snackbarReducer,
    menuState: menuStateSlice

});

export default reducer;

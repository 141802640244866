import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TDependentFieldsInputFieldValues,
  TOption,
  TReportTypeConfig,
  TReportTypeValues,
  TWizardData,
} from "../../typings";
import {
  dependentFieldsInputFields,
  dependentFieldsInputFieldValues,
} from "../../Formik/FormFields";
import { stringifyArrayParamValues, stringifyParamValues } from "../../helper";
import { api } from "../../../../../api";
import { isOracleError} from "../../../../../helper/common";
import {
  ActionIcon,
  Button,
  Grid,
  Select,
  Text,
  Textarea,
  Tooltip,
  Paper,
  Group,
} from "@mantine/core";
import ParamInputCard from "../../components/ParamInputCard";
import {
  IconArrowLeft,
  IconCirclePlus,
  IconDeviceFloppy,
  IconCornerDownRight,
  IconCircleX,
  IconTrash,
} from "@tabler/icons";
import { showCustomNotification } from "../../../../../helper/customNotification";
import { useQueryClient } from "@tanstack/react-query";

/**
 * * Component to add Dependent Fields (Sub Params)
 */

type TProps = {
  authUser: any;
  next: () => void;
  prev: () => void;
  handleWizardClose: () => void;
  selectedReportType: TReportTypeValues | undefined;
  wizardData: TWizardData;
  setWizardData: React.Dispatch<React.SetStateAction<TWizardData>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const StepDependentFields = ({
  authUser,
  next,
  prev,
  handleWizardClose,
  wizardData,
  setWizardData,
  setLoading,
  selectedReportType,
}: TProps) => {
  const [subParamJsonValue, setSubParamJsonValue] = useState<string>("");
  const [selectedParam, setSelectedParam] = useState<string | null>(null);
  const [paramOptions, setParamOptions] = useState<TOption[]>([]);

  const [subParamsToDelete, setSubParamsToDelete] = useState<
    Record<string, Record<string, TDependentFieldsInputFieldValues>>
  >({});
  const [paramCards, setParamCards] = useState<
    Record<string, TDependentFieldsInputFieldValues>
  >({});
  const [paramCardValues, setParamCardValues] = useState<
    Record<string, TDependentFieldsInputFieldValues>
  >({});
  const [resetValue, setResetValue] = useState({});
  const [triggerFieldTouch, setTriggerFieldTouch] = useState(false);
  const [isFormValid, setIsFormValid] = useState<Record<string, boolean>>({});
  const [validateParam, setValidateParam] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  //  Generate final Param Json Value from user inputs excluding empty fields
  useEffect(() => {
    const Json_Data = stringifyParamValues(paramCardValues, "SubParams");
    setSubParamJsonValue(Json_Data);
  }, [paramCardValues]);

  const handleAddSubParams = async () => {
    try {
      setLoading(true);
      if (selectedReportType) {
        let newSubParams: {
          [paramId: string]: TDependentFieldsInputFieldValues[];
        } = {};
        if (wizardData.reportSubParams)
          newSubParams = { ...wizardData.reportSubParams };
        let subParamsKeyValue = null;
        if (selectedParam) {
          if (
            Object.values(isFormValid).some((valid) => !valid) &&
            Object.keys(paramCards).length !== 1
          ) {
            setTriggerFieldTouch(true);
            setLoading(false);
            return;
          } else if (Object.values(isFormValid).every((valid) => valid)) {
            newSubParams = {
              ...newSubParams,
              [selectedParam]: Object.values(paramCardValues),
            };
          }
        }
        if (Object.keys(newSubParams).length) {
          subParamsKeyValue = Object.fromEntries(
            Object.entries(newSubParams).map(([paramId, subParams]) => {
              const jsonData = stringifyArrayParamValues(
                subParams,
                "SubParams"
              );
              return [paramId, jsonData];
            })
          );
        }

        const deleteSubParams = Object.fromEntries(
          Object.entries(subParamsToDelete).map(([paramId, subParam]) => {
            const subParamValues = Object.values(subParam);
            const jsonData = stringifyArrayParamValues(
              subParamValues,
              "SubParams"
            );
            return [paramId, jsonData];
          })
        );

        // const paramIdName = Object.values(wizardData.reportParams).reduce(
        //   (acc, curr) => ({ ...acc, [curr.param]: curr.param_id }),
        //   {}
        // );
        const payload = {
          P_Cr_User: authUser.user,
          reportSubParams: subParamsKeyValue,
          deleteSubParams,
          // paramIdName,
        };
        const response = await api.post(
          "customReport/subParams/create",
          undefined,
          payload
        );
        // console.log(response);
        if (!response.status) {
          throw new Error(response.message);
        }
        queryClient.setQueryData<TReportTypeConfig>(
          ["uiConfig", "reportType", selectedReportType.reportId],
          (oldData) => {
            if (!oldData)
              return {
                reportParams: [],
                reportSubParams: response.result,
                reportOutParams: [],
              };
            return {
              ...oldData,
              reportSubParams: response.result,
            };
          }
        );
        setWizardData((prev) => ({
          ...prev,
          reportSubParams: response.result,
        }));
      }
      next();
    } catch (error: any) {
      // console.log(error);
      if (isOracleError(error.message)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Oracle Error",
            message: error.message,
            type: "ORACLE_ERROR",
          },
        });
      } else {
        showCustomNotification({
          title: "Error",
          message: error.message,
          notifyType: "ERROR",
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (wizardData.reportParams) {
      const paramLabelValues = Object.values(wizardData.reportParams).reduce(
        (acc, param) =>
          param.value_type === "DYNAMIC"
            ? [...acc, { label: param.label, value: param.param_id + "" }]
            : [...acc],
        [] as TOption[]
      );
      // console.log(paramLabelValues);
      setParamOptions(paramLabelValues);
    }
  }, [wizardData.reportParams]);

  useEffect(() => {
    setLoading(true);
    setParamCardValues({});
    if (selectedParam && paramOptions.length) {
      const reportParams =
        wizardData.reportParams && Object.values(wizardData.reportParams);
      // get params from procedure
      const procedure = reportParams?.find(
        (param) => param.param_id + "" === selectedParam
      )?.parm_val;
      // regex to get procedure params - procName(:param1,:param2,:param3)
      const paramArray = procedure?.match(/(?<=:).*?(?=,|\))/g);

      /* Fill existing subParams in inputFields, if SelectedParam has empty subParams then add empty inputField */
      let subParams = {};
      if (
        wizardData.reportSubParams &&
        wizardData.reportSubParams[selectedParam]
      ) {
        subParams = wizardData.reportSubParams[selectedParam].reduce(
          (acc, curr) => {
            const paramIndex = paramArray?.findIndex(
              (parm) => parm.toUpperCase() === curr.sparam.toUpperCase()
            );
            if (paramIndex !== undefined && paramIndex !== -1) {
              paramArray?.splice(paramIndex, 1);
            }
            const id = curr.sparam.toUpperCase();
            return { ...acc, [id]: curr };
          },
          {}
        );
      }
      if (paramArray) {
        subParams = paramArray.reduce(
          (acc, curr) => {
            let subDataType = "";
            let subParamType = "";
            const param = reportParams?.find(
              (p) => p.param.toUpperCase() === curr.toUpperCase()
            );
            if (param) {
              subDataType = param.data_type.toUpperCase();
              subParamType = param.type.toUpperCase();
            }
            const id = curr.toUpperCase();
            const paramField = {
              ...dependentFieldsInputFieldValues,
              sparam: curr,
              stype: subParamType,
              sdata_type: subDataType,
            };
            return { ...acc, [id]: paramField };
          },
          { ...subParams }
        );
      }

      const errorValues = Object.keys(subParams).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      );
      setIsFormValid(errorValues);
      setParamCards(subParams);
      setResetValue(subParams);
    } else {
      setParamCards({});
      setIsFormValid({});
      setResetValue({});
    }
    setLoading(false);
  }, [
    paramOptions,
    selectedParam,
    setLoading,
    wizardData.reportParams,
    wizardData.reportSubParams,
  ]);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const availableSubParamsInParamProc = useMemo(() => {
    const reportParams =
      wizardData.reportParams && Object.values(wizardData.reportParams);
    // get params from procedure
    const procedure = reportParams?.find(
      (param) => param.param_id + "" === selectedParam
    )?.parm_val;
    // regex to get procedure params - procName(:param1,:param2,:param3)
    const paramArray = procedure?.match(/(?<=:).*?(?=,|\))/g);
    return paramArray?.map((value) => value.toUpperCase());
  }, [selectedParam, wizardData.reportParams]);

  const dependentFieldsValidationSchema = useCallback(
    (values: TDependentFieldsInputFieldValues) => {
      let errors: any = {};

      if (!values.sparam) errors = { ...errors, sparam: "Required" };
      else if (
        availableSubParamsInParamProc &&
        !availableSubParamsInParamProc.includes(values.sparam.toUpperCase())
      )
        errors = { ...errors, sparam: "does not exist in param procedure" };
      else {
        const paramValues = Object.values(paramCardValues).filter(
          (val) => val.sparam === values.sparam
        );
        if (paramValues.length > 1)
          errors = { ...errors, sparam: "must be unique" };
      }

      if (!values.sdata_type) errors = { ...errors, sdata_type: "Required" };
      if (!values.stype) errors = { ...errors, stype: "Required" };

      return errors;
    },
    [availableSubParamsInParamProc, validateParam]
  );

  return (
    <Grid gutter="sm">
      <Grid.Col xs={12} px={2}>
        <Text align="center">
          Here you can enter the dependent fields (parent id's) of each control
          field of the report type which is shown in the Ui.
        </Text>
      </Grid.Col>
      <Grid.Col xs={12} sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="outline" onClick={next}>
          Skip
        </Button>
      </Grid.Col>
      <Grid.Col xs={12} md={6}>
        <Select
          data={paramOptions ?? []}
          value={selectedParam ? selectedParam + "" : null}
          label="Field"
          onChange={(value) => {
            if (selectedParam) {
              const nonEmptyParams = Object.values(paramCardValues).filter(
                (value) =>
                  Object.entries(value).every(([key, val]) =>
                    key !== "sparam_id" ? val !== "" : true
                  )
              );
              setWizardData((prev) => ({
                ...prev,
                reportSubParams: {
                  ...prev.reportSubParams,
                  [selectedParam]: nonEmptyParams,
                },
              }));
              // if (
              //   Object.values(isFormValid).some((valid) => !valid) &&
              //   Object.keys(paramCards).length !== 1
              // ) {
              //   setTriggerFieldTouch(true);
              //   return;
              // } else if (Object.values(isFormValid).every((valid) => valid))
              //   setWizardData((prev) => ({
              //     ...prev,
              //     reportSubParams: {
              //       ...prev.reportSubParams,
              //       [selectedParam]: Object.values(paramCardValues),
              //     },
              //   }));
            }
            setParamCards({});
            setSelectedParam(value);
          }}
          clearable
          nothingFound="No options"
          maxDropdownHeight={280}
        />
      </Grid.Col>
      <Grid.Col xs={12}>
        {/* <Text size="sm" sx={{ display: "flex", justifyContent: "end" }}>
          *dependant fields are optional
        </Text> */}
        {Object.keys(paramCards).length > 0 && (
          <Paper shadow="sm" p="md">
            <Grid gutter="sm">
              <Grid.Col xs={12}></Grid.Col>
              {Object.keys(paramCards).length > 0 &&
                Object.keys(paramCards).map((paramKey) => (
                  <Grid.Col xs={12} key={paramKey}>
                    <Grid>
                      <ParamInputCard
                        setParamCardValues={setParamCardValues}
                        paramCardValues={paramCards[paramKey]}
                        paramKey={paramKey}
                        validation={dependentFieldsValidationSchema}
                        inputFields={dependentFieldsInputFields}
                        paramType="SubParams"
                        setIsFormValid={setIsFormValid}
                        triggerFieldTouch={triggerFieldTouch}
                        setTriggerFieldTouch={setTriggerFieldTouch}
                        validateParam={validateParam}
                        setValidateParam={setValidateParam}
                      />
                      <Grid.Col
                        xs={1}
                        sx={{
                          display: "flex",
                          alignItems: "end",
                        }}
                        pb="md"
                      >
                        <Tooltip label="Delete field">
                          <ActionIcon
                            size="md"
                            onClick={() => {
                              const { [paramKey]: param, ...newParams } =
                                paramCards;
                              const { [paramKey]: paramVal, ...newParamVals } =
                                paramCardValues;
                              if (
                                paramCards[paramKey].sparam_id !== "" &&
                                selectedParam
                              ) {
                                setSubParamsToDelete((prev) => ({
                                  ...prev,
                                  [selectedParam]: {
                                    ...prev[selectedParam],
                                    [paramKey]: param,
                                  },
                                }));
                              }
                              setParamCards(newParams);
                              setParamCardValues(newParamVals);
                              const {
                                [paramKey]: paramFormValid,
                                ...newParamFormValid
                              } = isFormValid;
                              setIsFormValid(newParamFormValid);
                            }}
                            // disabled={
                            //   Object.keys(paramCards).length === 1 ||
                            //   !!paramCards[paramKey].sparam_id
                            // }
                          >
                            <IconTrash fontSize="medium" />
                          </ActionIcon>
                        </Tooltip>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                ))}
              {/* {selectedParam && (
                <Grid.Col
                  xs={0.5}
                  sx={{ display: "flex", alignItems: "end" }}
                  pb="sm"
                >
                  <Tooltip label="Add field">
                    <ActionIcon
                      size="md"
                      onClick={() => {
                        const id = generateRandomString(6);
                        setParamCards((prev) => ({
                          ...prev,
                          [id]: dependentFieldsInputFieldValues,
                        }));
                        setIsFormValid((prev) => ({
                          ...prev,
                          [id]: false,
                        }));
                      }}
                    >
                      <IconCirclePlus fontSize="medium" />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              )} */}
            </Grid>
          </Paper>
        )}
      </Grid.Col>

      <Grid.Col xs={12}>
        <Textarea
          id="read-only-params-textfield"
          value={subParamJsonValue}
          label="Preview in Json format"
          maxRows={5}
          readOnly
        />
      </Grid.Col>
      {/* <Grid.Col xs={1} display="flex" justifyContent="center">
              <IconButton
                size="large"
                color="primary"
                aria-label="refresh field"
                onClick={() => {
                  const Json_Data = stringifyParamValues(
                    paramCardValues,
                    "SubParams"
                  );
                  setSubParamJsonValue(Json_Data);
                }}
              >
                <RefreshIcon fontSize="inherit" />
              </IconButton>
            </Grid.Col> */}
      <Grid.Col xs={12} mt={1}>
        <Group spacing="sm" position="right">
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconArrowLeft />}
            onClick={() => {
              // if (selectedParam) {
              //   if (
              //     Object.values(isFormValid).some((valid) => !valid) &&
              //     Object.keys(paramCards).length !== 1
              //   ) {
              //     setTriggerFieldTouch(true);
              //     setLoading(false);
              //     return;
              //   } else if (Object.values(isFormValid).every((valid) => valid))
              //     setWizardData((prev) => ({
              //       ...prev,
              //       reportSubParams: {
              //         ...prev.reportSubParams,
              //         [selectedParam]: Object.values(paramCardValues),
              //       },
              //     }));
              // }
              prev();
            }}
          >
            Back
          </Button>
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconCornerDownRight />}
            onClick={() => {
              setParamCards({});
              const errorValues = Object.keys(resetValue).reduce(
                (acc, key) => ({ ...acc, [key]: false }),
                {}
              );
              setSubParamsToDelete({});
              setIsFormValid(errorValues);
              setTimeout(() => setParamCards(resetValue), 0);
            }}
          >
            Reset
          </Button>
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconCircleX />}
            onClick={() => {
              handleWizardClose();
            }}
          >
            Cancel
          </Button>
          <Button
            leftIcon={<IconDeviceFloppy />}
            variant="filled"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            onClick={handleAddSubParams}
          >
            Save & Next
          </Button>
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default StepDependentFields;

import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  Grid,
  Group,
  Modal,
  Text,
  Title,
} from "@mantine/core";
import ProcedureInput from "../../components/ProcedureInput";
import {
  IconDeviceFloppy,
  IconCornerDownRight,
  IconCircleX,
  IconAlertCircle,
} from "@tabler/icons";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { TProcParameter } from "..";
import { getReportProcParameters } from "../../queries";
import { isOracleError } from "../../../../../helper/common";
import { showCustomNotification } from "../../../../../helper/customNotification";
import { useDispatch } from "react-redux";
import WizardLoader from "../Loader";

const DynamicValueModal = ({
  isModalOpen,
  paramKey,
  cardFormik,
  handleModalClose,
}: any) => {
  const [clearProcField, triggerClearProcField] = useState<boolean>(false);
  const [procArgChange, setProcArgChanged] = useState("");
  const [reportProcedureName, setReportProcedureName] = useState("");
  const [fetchedProcedure, setFetchedProcedure] = useState("");
  const [initialValues, setInitialValues] = useState({ parm_val: "" });
  const matches = useMediaQuery("(min-width: 1200px)");
  const dispatch = useDispatch();

  const { isFetching: isProcParamsLoading, data: argParameters } = useQuery<
    TProcParameter[],
    Error
  >(
    ["uiConfig", "reportProcedureParameters", reportProcedureName],
    () => getReportProcParameters(reportProcedureName),
    {
      enabled: !!reportProcedureName,

      onError: (error) => {
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  useEffect(() => {
    setFetchedProcedure(cardFormik.values.parm_val);
    setReportProcedureName("");
  }, [cardFormik.values.parm_val, setFetchedProcedure, setReportProcedureName]);

  useEffect(() => {
    if (!argParameters) return;
    // if (!argParameters.length) {
    //   setFetchedProcedure("");
    // } else {
    const paramValue = argParameters.map((value) => `:${value.ARGUMENT_NAME}`);
    const previewString = `${reportProcedureName}(${paramValue})`;
    setFetchedProcedure(previewString);
    // }
  }, [argParameters, reportProcedureName]);

  useEffect(() => {
    if (!reportProcedureName) {
      setProcArgChanged("");
      return;
    }
    if (!isProcParamsLoading && argParameters) {
      const currentProc = cardFormik.values.parm_val;
      if (!currentProc) return;
      const currentParamArray = currentProc.match(/(?<=:).*?(?=,|\))/g);
      if (!currentParamArray) return;
      const currentProcName = currentProc.substring(
        0,
        currentProc.indexOf("(")
      );
      const isProcNameSame =
        reportProcedureName.toUpperCase() === currentProcName.toUpperCase();
      const isParamSame = currentParamArray.every((value: any) =>
        argParameters.some(
          (arg) => arg.ARGUMENT_NAME.toUpperCase() === value.toUpperCase()
        )
      );
      if (
        !isProcNameSame ||
        currentParamArray.length !== argParameters.length ||
        !isParamSame
      ) {
        const newParams = argParameters.map(
          (value) => `:${value.ARGUMENT_NAME}`
        );
        const newProcedure = `${reportProcedureName}(${newParams})`;
        setProcArgChanged(newProcedure);
      } else {
        setProcArgChanged("");
      }
    }
  }, [
    argParameters,
    isProcParamsLoading,
    reportProcedureName,
    cardFormik.values.parm_val,
  ]);

  useEffect(() => {
    if (paramKey) {
      setInitialValues({
        parm_val: cardFormik.values.parm_val,
      });
    }
  }, [cardFormik.values.parm_val, paramKey]);

  return (
    <Modal
      opened={isModalOpen}
      onClose={handleModalClose}
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
      size={matches ? "50%" : "100%"}
      zIndex={500}
    >
      <WizardLoader
        isLoading={false}
        isProcParamsLoading={isProcParamsLoading}
        isParamsLoading={false}
      />
      <Title order={3} align="center" mb={3}>
        Enter Procedure
      </Title>
      {procArgChange && (
        <Grid.Col xs={12} my={2} px={2}>
          <Alert
            icon={<IconAlertCircle size={16} />}
            title="Change in Procedure found."
            color="yellow"
          >
            <Box>
              <Text>Old procedure :</Text>
              <Text sx={{ color: "red" }}>{initialValues.parm_val}</Text>
            </Box>
            <Box>
              <Text>New procedure :</Text>
              <Text sx={{ color: "red" }}>{procArgChange}</Text>
            </Box>
            <Text>
              Procedure from the database does not match the procedure saved
              with current report type. To avoid conflict please click Save &
              Next.
            </Text>
          </Alert>
        </Grid.Col>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          parm_val: Yup.string().required("Required"),
        })}
        onSubmit={({ parm_val }) => {
          cardFormik.setFieldValue("parm_val", parm_val);
          handleModalClose();
        }}
      >
        {(procFormik) => (
          <form noValidate onSubmit={procFormik.handleSubmit}>
            <Grid gutter="sm">
              <ProcedureInput
                formik={procFormik}
                fieldName="parm_val"
                clearFields={clearProcField}
                // initialProc={cardFormik.values.parm_val}
                initialProc={fetchedProcedure}
                setFetchedProcedure={setFetchedProcedure}
                reportProcedureName={reportProcedureName}
                setReportProcedureName={setReportProcedureName}
                isProcParamsLoading={isProcParamsLoading}
              />
              <Grid.Col xs={12} mt={1}>
                <Group spacing="xs" position="apart">
                  {/* <Grid.Col xs={4}> */}
                  <Button
                    // fullWidth
                    variant="outline"
                    leftIcon={<IconCircleX />}
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                  {/* </Grid.Col> */}
                  {/* <Grid.Col
                xs={4}
                sx={{ display: "flex", justifyContent: "center" }}
              > */}
                  <Button
                    variant="outline"
                    leftIcon={<IconCornerDownRight />}
                    onClick={() => {
                      setFetchedProcedure(cardFormik.values.parm_val);
                      setReportProcedureName("");
                      triggerClearProcField((prev) => !prev);
                    }}
                  >
                    Reset
                  </Button>
                  {/* </Grid.Col> */}
                  {/* <Grid.Col xs={4}> */}
                  <Button
                    leftIcon={<IconDeviceFloppy />}
                    variant="filled"
                    // fullWidth
                    type="submit"
                  >
                    Add
                  </Button>
                  {/* </Grid.Col> */}
                </Group>
              </Grid.Col>
            </Grid>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default DynamicValueModal;

import { api } from "../../api";
import {ValidatedResponseProps} from "../finance/types";

export const getDashboardData = async () => {
  try {
      return await Promise.resolve( api.get(`dashboard`));
  } catch (error) {
    return error;
  }
};

import {FormikErrors, FormikTouched} from "formik";
import Dropdown from "./Dropdown";
import Textfield from "./Textfield";
import {TReportControls, TReportInitialValues} from "../../typings";
import DateField from "./DateField";
import FileUpload from "./FileUpload";
type TProps = {
    reportCtrl: TReportControls;
    authUser: any;
    formValues: TReportInitialValues;
    reportControls: TReportControls[];
    errors: FormikErrors<TReportInitialValues>;
    touched: FormikTouched<TReportInitialValues>;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void;
    setInitialValues: React.Dispatch<React.SetStateAction<TReportInitialValues>>;
};

const Controls = ({
                      reportCtrl,
                      authUser,
                      formValues,
                      reportControls,
                      errors,
                      touched,
                      setFieldValue,
                      setInitialValues,
                  }: TProps) => {



    const reportCtrlNameUpper = (reportCtrl.PRAM_NAME + "").toUpperCase();
    switch (reportCtrl.CONTROL_CODE) {
        case "S_DRWN":
        case "DRWN":
            return (
                <Dropdown
                    label={reportCtrl.LABEL_TXT}
                    reportCtrl={reportCtrl}
                    authUser={authUser}
                    formValues={formValues}
                    reportControls={reportControls}
                    setFieldValue={setFieldValue}
                    isSearchable={reportCtrl.CONTROL_CODE === "S_DRWN"}
                    errors={errors}
                    touched={touched}
                />
            );
        case "TXT":
            return (
                <Textfield
                    value={formValues[reportCtrlNameUpper].value as string}
                    label={reportCtrl.LABEL_TXT}
                    reportCtrl={reportCtrl}
                    authUser={authUser}
                    formValues={formValues}
                    reportControls={reportControls}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setInitialValues={setInitialValues}
                />
            );
        case "FL_UPLOAD":
            return (

                <FileUpload
                    value={formValues[reportCtrlNameUpper].value as string}
                    label={reportCtrl.LABEL_TXT}
                    reportCtrl={reportCtrl}
                    authUser={authUser}
                    formValues={formValues}
                    reportControls={reportControls}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setInitialValues={setInitialValues}
                />
            );
        case "DT_PCKR":
            return (
                <DateField
                    reportCtrl={reportCtrl}
                    formValues={formValues}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setInitialValues={setInitialValues}
                />
            );

        default:
            return null;
    }
};

export default Controls;

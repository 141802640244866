import {Stack,useMantineTheme} from "@mantine/core";
import {Dropzone, DropzoneProps, MIME_TYPES} from "@mantine/dropzone";
import {RiCloseFill, RiUploadCloud2Fill} from "react-icons/ri";

const FilesDropZoneSmall = (props: Partial<DropzoneProps>) => {
    const theme = useMantineTheme();
    return (
        <Dropzone
            onDrop={(files) => console.log(files[0])}
            onReject={(files) => console.log(files[0].errors[0].message)}
            // maxSize={3 * 1024 ** 2}
            accept={[MIME_TYPES.csv, MIME_TYPES.xlsx]}
            {...props}
            style={{marginTop:'3px',padding:'4px'}}
        >

            <Stack
                align="center"
                spacing="sm"
                style={{minHeight: 20, pointerEvents: "none"}}
            >
                <Dropzone.Accept>
                    <RiUploadCloud2Fill
                        size={50}
                        color={theme.colors[theme.primaryColor][6]}
                    />
                </Dropzone.Accept>
                <Dropzone.Reject>
                    <RiCloseFill size={40} color={theme.colors.red[6]}/>
                </Dropzone.Reject>

              <div style={{color:theme.colors[theme.primaryColor][6],fontWeight:'600',fontSize:'15px'}}>
                    Drag and drop / Browse file
              </div>
            </Stack>
        </Dropzone>
    );
};

export default FilesDropZoneSmall;

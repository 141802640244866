import { Tabs, useMantineTheme } from "@mantine/core";
import { IconReport, IconReportAnalytics } from "@tabler/icons";
import GenerateFromLogs from "./GenerateFromLogs";
import GenerateReport from "./GenerateReport";

const ReportGenerationTabs = () => {
  const theme = useMantineTheme();
  return (
    <Tabs
      variant="pills"
      radius="md"
      defaultValue="reportLogs"
      activateTabWithKeyboard={false}
      color={theme.colors.ffcBrand[6]}
    >
      <Tabs.List>
        <Tabs.Tab value="reportLogs" icon={<IconReport size={24} />}>
          Logs
        </Tabs.Tab>
        <Tabs.Tab
          value="reportGeneration"
          icon={<IconReportAnalytics size={24} />}
        >
          Generate Report
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="reportLogs" pt="xs">
        <GenerateFromLogs />
      </Tabs.Panel>

      <Tabs.Panel value="reportGeneration" pt="xs">
        <GenerateReport />
      </Tabs.Panel>
    </Tabs>
  );
};

export default ReportGenerationTabs;

import { RootStateOrAny, useSelector } from "react-redux";

import AppRoutes from "./routes";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./routes/privateRoute";
import { Toaster } from "react-hot-toast";

import { MantineProvider } from "@mantine/core";
import { customMantineTheme } from "./mantine-themes/theme";
import Loader from "./components/Loader";
import ErrorAlert from "./components/Dialogs/ErrorAlert";
import Login from "./views/Authenticate/Login";
import AlertModal from "./components/Modal/AlertModal";
import { NotificationsProvider } from "@mantine/notifications";

const App = () => {
  const { alertArray, isSnackbarOpen, title, message } = useSelector(
    (state: RootStateOrAny) => state.snackbarState
  );

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={customMantineTheme}
    >
      <NotificationsProvider position="top-right" zIndex={2077}>
        <Loader isLoading={false} />
        <AlertModal alertArray={alertArray} />
        <ErrorAlert isOpen={isSnackbarOpen} title={title} message={message} />
        <Toaster
          position="top-right"
          reverseOrder={true}
          toastOptions={{
            success: {
              duration: 6000,
              style: {
                border: "1px solid #17b466",
                padding: "10px",
                backgroundColor: "#17b466",
                color: "#FFFAEE",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#17b466",
              },
            },
            error: {
              duration: 6000,
              style: {
                border: "1px solid #c62828",
                padding: "10px",
                backgroundColor: "#c62828",
                color: "#FFFAEE",
              },
              iconTheme: {
                primary: "#FFFAEE",
                secondary: "#c62828",
              },
            },
          }}
        />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute fallbackTo="/login" />}>
            <Route path="/*" element={<AppRoutes />} />
          </Route>
        </Routes>
      </NotificationsProvider>
    </MantineProvider>
  );
};

export default App;

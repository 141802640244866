import {api} from "../api";
import {decodeToken} from "../helper/crypto";

const setProfileToken = (token: any) => {
    localStorage.setItem(
        "ffc-user-profile",
        JSON.stringify({"user-profile-token": token})
    );
};

const getProfileToken = () => {
    const token = localStorage.getItem("ffc-user-profile");
    if (token) {
        const access_token = JSON.parse(token)["user-profile-token"];
        return access_token;
    }
    return null;
};


const setMenuToken = (token: any) => {
    localStorage.setItem(
        "ffc-user-menus",
        JSON.stringify({"user-menu-token": token})
    );
};
// const getMenuToken = () => {
//     const token = localStorage.getItem("ffc-user-menus");
//     if (token) {
//         const access_token = JSON.parse(token)["user-menu-token"];
//         return access_token;
//     }
//     return null;
// };


const clearProfileToken = () => {
    localStorage.removeItem("ffc-user-profile");
};

// const clearMenuToken = () => {
//     localStorage.removeItem("ffc-user-menus");
// };

export const initialState = {
    isAuth: api.getAuthToken() ? true : false,
    authUser: api.getAuthToken() ? decodeToken(api.getAuthToken()) : null,
    userProfile: getProfileToken() ? decodeToken(getProfileToken()) : null,
  //  userMenus: getMenuToken() ? decodeToken(getMenuToken()) : null,
};

const authReducer = (state = initialState, action: any = {}) => {
    switch (action.type) {
        case "LOGIN":
            api.setAuthToken(action.payload.token);
            setProfileToken(action.payload.profileToken);
            setMenuToken(action.payload.menuToken);

            return {
                ...state,
                isAuth: true,
                userProfile: action.payload.profileData,
              //  userMenus: action.payload.menuData,
                authUser: {
                    user: action.payload.userProfile.user_code,
                    user_id: action.payload.userProfile.user_id,
                    user_emp_code: action.payload.userProfile.user_emp_code,
                    company_code: action.payload.userProfile.def_company,
                    def_group: action.payload.userProfile.def_group,
                    division_code: action.payload.userProfile.def_division,
                    email_id: action.payload.userProfile.user_email,
                    admin_status:
                        action.payload.userProfile.admin_status.toLowerCase() === "y"
                            ? true
                            : false,
                },
            };

        case "LOGOUT":
            api.clearAuth();
            clearProfileToken();
           // clearMenuToken();
            return {
                initialState,
            };
        default:
            return state;
    }
};

export default authReducer;

import {Loader, Select, useMantineTheme} from "@mantine/core";
import {IconArrowAutofitDown} from "@tabler/icons";
import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {api} from "../../../../api";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {TReportControls, TReportInitialValues} from "../../typings";
import {FormikErrors, FormikTouched} from "formik";
import {showCustomNotification} from "../../../../helper/customNotification";
import {isOracleError} from "../../../../helper/common";

type TProps = {
    label: string;
    authUser: any;
    formValues: TReportInitialValues;
    reportCtrl: TReportControls;
    reportControls: TReportControls[];
    isSearchable: boolean;
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void;
    errors: FormikErrors<TReportInitialValues>;
    touched: FormikTouched<TReportInitialValues>;
};

type TOption = {
    label: string;
    value: any;
};

type TParentControls = TReportInitialValues;

const Dropdown = ({
                      label,
                      authUser,
                      reportCtrl,
                      formValues,
                      reportControls,
                      setFieldValue,
                      isSearchable,
                      errors,
                      touched,
                  }: TProps) => {
    // const [options, setOptions] = useState<Option[]>([]);
    const [isOptionsEnabled, setOptionsEnabled] = useState(true);
    const [staticOptions, setStaticOptions] = useState<TOption[]>([]);
    const [queryId, setQueryId] = useState<Record<string, { label: string; value: string }>>({});
    const dispatch = useDispatch();
    const theme = useMantineTheme();
    const reportCtrlUpper = (reportCtrl.PRAM_NAME + "").toUpperCase();

    const parentParamIds = useMemo(() => {
        if (reportCtrl.BIND_TYPE === "STATIC") return [];
        if (reportCtrl.PARENT_PARAM_ID) {
            const parentIds: number[] = JSON.parse(reportCtrl.PARENT_PARAM_ID);
            const visibleIds = parentIds.filter((id) =>
                reportControls.some((ctrl) => ctrl.PRAM_ID === id)
            );
            return visibleIds;
        }
        return [];
    }, [reportControls, reportCtrl.BIND_TYPE, reportCtrl.PARENT_PARAM_ID]);

    const queryClient = useQueryClient();

    const getProcOptions = async () => {
        // if (!reportCtrl.BIND_PARAM)
        //   throw new Error(`Bind parameter of ${reportCtrl.LABEL_TXT} is empty`);
        let ProcParams = {};
        let parsedBindParam = [];
        if (reportCtrl.BIND_PARAM) {
            parsedBindParam = JSON.parse(reportCtrl.BIND_PARAM);
            console.log("parsedBindParam",parsedBindParam);
            for (const el of parsedBindParam) {
                const paramUpper = (el.param + "").toUpperCase();
                
                if (el.type === "IN") {
                    // if (paramUpper === "P_COMP_CODE") {
                    //     ProcParams = {...ProcParams, P_COMP_CODE: authUser.company_code};
                    //     continue;
                    // }

                    if (paramUpper === "P_COMP_GROUP") {
                        ProcParams = {...ProcParams, P_COMP_GROUP: authUser.def_group};
                        continue;
                    }

                    if (paramUpper === "P_USER") {
                        ProcParams = {...ProcParams, P_USER: authUser.user};
                        continue;
                    }

                    if (paramUpper === "P_PLATFORM") {
                        console.log("paramUpper",paramUpper);
                        ProcParams = { ...ProcParams, P_PLATFORM: 'WEB' };
                        continue;
                      }
                      if (paramUpper === "P_SOURCE") {
                        ProcParams = { ...ProcParams, P_SOURCE: 'ERP PORTAL' };
                        continue;
                      }
                      
                    if (paramUpper !== reportCtrlUpper)
                        ProcParams = {
                            ...ProcParams,
                            [paramUpper]: formValues[paramUpper].value,
                        };
                    else ProcParams = {...ProcParams, [reportCtrlUpper]: null};
                }
            
            }
        }
        const payload = {
            proc: reportCtrl.BIND_VALUE,
            params: parsedBindParam,
            param_values: ProcParams,
        };
        console.log("payload",payload);
        const responseOptions = await api.post(
            "report/custom/options",
            undefined,
            payload
        );
        if (!responseOptions.status) throw new Error(responseOptions.message);
        // setOptions(responseOptions.data);
        setOptionsEnabled(true);
        return responseOptions.data;
    };

    const {
        isFetching,
        data: options,
        refetch,
    } = useQuery<TOption[], Error>(
        ["reportGeneration", "dynamicControlValues", reportCtrlUpper, queryId],
        getProcOptions,
        {
            enabled: !!Object.keys(queryId).length,
            staleTime: 0,
            // cacheTime: 0,

            onError: (error) => {
                setOptionsEnabled(false);
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: error.message },
                // });
                if (isOracleError(error.message)) {
                    dispatch({
                        type: "OPEN_ALERT_MODAL",
                        payload: {
                            title: "Oracle Error",
                            message: error.message,
                            type: "ORACLE_ERROR",
                        },
                    });
                } else {
                    showCustomNotification({
                        title: "Error",
                        message: `Error occured in ${reportCtrl.LABEL_TXT}: mostly due to incorrect data provided. ${error.message}`,
                        notifyType: "ERROR",
                    });
                }
            },
        }
    );

    // * may have to set parents in formik right after getting reportControls in report.tsx
    const [parentControls, setParentControls] = useState<TParentControls>({});
    useEffect(() => {
        if (reportCtrl.BIND_TYPE === "STATIC") {
            if (reportCtrl.BIND_VALUE) {
                //  todo : remove eval function when db gives correct value
                const parsedBindValue = eval(reportCtrl.BIND_VALUE);
                // const parsedBindValue = JSON.parse(reportCtrl.BIND_VALUE);
                setStaticOptions(parsedBindValue);
            }
            return;
        }
        if (parentParamIds.length) {
            parentParamIds.forEach((id) => {
                for (const ctrl of reportControls) {
                    if (ctrl.PRAM_ID === id) {
                        const pramNameUpper = (ctrl.PRAM_NAME + "").toUpperCase();
                        setParentControls((prev) => ({
                            ...prev,
                            [pramNameUpper]: formValues[pramNameUpper],
                        }));
                        break;
                    }
                }
            });
            return;
        }
        // getProcOptions();
        setQueryId({parent: {label: "noParent", value: "noParent"}});
    }, []);

    // fill dropDown options
    useEffect(() => {
        if (reportCtrl.BIND_TYPE === "DYNAMIC") {
            // no need to call proc if there's parent dropDown and no change in parentControls value
            if (parentParamIds.length) {
                let newParentControls = {};
                for (const parent in parentControls) {
                    const upperParent = (parent + "").toUpperCase();
                    newParentControls = {
                        ...newParentControls,
                        [upperParent]: formValues[upperParent],
                    };
                }
                if (
                    JSON.stringify(newParentControls) === JSON.stringify(parentControls)
                )
                    return;
                else setParentControls(newParentControls);
                setFieldValue(reportCtrlUpper, {label: "", value: ""});
                setOptionsEnabled(false);
                // setOptions([]);
                // no need to call proc if a parent dropDown is not selected
                let ctrlParents: Record<string, { label: string; value: string }> = {};
                for (const parent in newParentControls) {
                    const parentFound = Object.entries(formValues).find(
                        (value) =>
                            (value[0] + "").toUpperCase() === (parent + "").toUpperCase()
                    ) as [string, { label: string; value: string }];
                    if (!parentFound || !(parentFound[1] && parentFound[1].value)) {
                        // setFieldValue(reportCtrlUpper, { label: "", value: "" });
                        // setOptions([]);
                        queryClient.cancelQueries([
                            "reportGeneration",
                            "dynamicControlValues",
                            reportCtrlUpper,
                        ]);
                        return;
                    }
                    ctrlParents = {...ctrlParents, [parentFound[0]]: parentFound[1]};
                }
                setOptionsEnabled(true);
                setQueryId(ctrlParents);
                if (!isFetching) refetch();
            }
        }
    }, [formValues]);

    // ! option's value must be string|null for mantine-ui
    return (
        <Select
            searchable={isSearchable}
            allowDeselect={false}
            clearable
            label={label}
            placeholder="Select"
            nothingFound="No options available"
            data={
                reportCtrl.BIND_TYPE === "STATIC"
                    ? staticOptions
                    : isOptionsEnabled
                    ? options ? options: []  : []
            }
            value={
                formValues[reportCtrlUpper].value
                    ? formValues[reportCtrlUpper].value + ""
                    : null
            }
            onChange={(value) => {
                setFieldValue(reportCtrlUpper, {label: "", value: ""});
                if (value) {
                    const ctrlOptions =
                        reportCtrl.BIND_TYPE === "STATIC" ? staticOptions : options;
                    const selectedOption = ctrlOptions?.find(
                        (option) => option.value === value
                    );
                    setFieldValue(reportCtrlUpper, selectedOption);
                }
                // setFieldValue(reportCtrlUpper, {
                //   label: value,
                //   value: value,
                // });
            }}
            icon={<IconArrowAutofitDown size={20} color={theme.colors.ffcBrand[6]}/>}
            disabled={isFetching}
            error={
                !!(touched[reportCtrlUpper] && errors[reportCtrlUpper]) &&
                errors[reportCtrlUpper]?.value
            }
            rightSection={isFetching ? <Loader size="sm" variant="dots"/> : null}
        />
    );
};

export default Dropdown;

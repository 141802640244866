import {
  ActionIcon,
  Box,
  Collapse,
  Group,
  LoadingOverlay,
  Paper,
  Select,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconMenu2, IconReportAnalytics } from "@tabler/icons";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import MainCard from "../../../components/Cards/MainCard";
import Loader from "../../../components/Loader";
import GenerationMenuItem from "./GenerationMenuItem";
import LogTable from "./LogTable";
import { useQuery } from "@tanstack/react-query";
import { getReportLogs } from "./queries";
import { TDocsToGenerate, TReportLog } from "../typings";
import { showCustomNotification } from "../../../helper/customNotification";
import { isOracleError } from "../../../helper/common";

const GenerateFromLogs = () => {
  const theme = useMantineTheme();
  const { authUser }: RootStateOrAny = useSelector((state: any) => state.auth);

  const [selectedReport, setSelectedReport] = useState("");
  const [options, setOptions] = useState<TReportLog["CREP_NAME"][]>([]);
  const [docsToGenerate, setDocsToGenerate] = useState<TDocsToGenerate[]>([]);
  const [menuOpened, setMenuOpened] = useState(false);

  const dispatch = useDispatch();

  const {
    isLoading,
    isFetching,
    data: reportLogs,
  } = useQuery<TReportLog[], Error>(
    ["reportLogs"],
    () => getReportLogs(authUser.user),
    {
      // select: (data) => {
      //   console.log("select", data);
      //   return data;
      // },
      // enabled: false,
      refetchIntervalInBackground: true,
      onError: (error) => {
        // dispatch({
        //   type: "OPEN_SNACK_TOAST",
        //   payload: { title: "Server Error", message: error.message },
        // });
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  useEffect(() => {
    if (reportLogs?.length) {
      const reportNames = reportLogs.map((log) => log.CREP_NAME);
      setOptions(Array.from(new Set(reportNames)));
    } else setOptions([]);
  }, [reportLogs]);

  return (
    <>
      {/* <Loader isLoading={isLoading} /> */}
      <MainCard height={200} sx={{ position: "relative" }}>
        <LoadingOverlay visible={isLoading} />
        <Group position="apart" align="center">
          <Select
            allowDeselect={false}
            clearable
            // label="Filter"
            placeholder="Filter Report"
            nothingFound="No options available"
            data={options}
            value={selectedReport ? selectedReport + "" : null}
            onChange={(value) => setSelectedReport(value ?? "")}
            icon={<IconReportAnalytics color={theme.colors.ffcBrand[6]} />}
            sx={{ maxWidth: "16rem" }}
            mb={20}
          />

          <Stack sx={{ display: "none" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Indicator
                size={15}
                disabled={ongoingExportCount === 0}
                label={ongoingExportCount ? ongoingExportCount : null}
              > */}
              <ActionIcon onClick={() => setMenuOpened((prev) => !prev)}>
                <IconMenu2 size={24} />
              </ActionIcon>
              {/* </Indicator> */}
            </Box>

            <Collapse in={menuOpened}>
              <Paper shadow="sm" p="md">
                <Stack spacing="xs">
                  {docsToGenerate.length ? (
                    docsToGenerate.map((doc) => (
                      <GenerationMenuItem
                        key={`${doc.docId}logList-${doc.CREP_ID}`}
                        doc={doc}
                        // setOngoingExportCount={setOngoingExportCount}
                      />
                    ))
                  ) : (
                    <Text size="md">No exports done</Text>
                  )}
                </Stack>
              </Paper>
            </Collapse>
          </Stack>
        </Group>
        {!!reportLogs?.length ? (
          <LogTable
            selectedReport={selectedReport}
            reportLogs={reportLogs}
            setDocsToGenerate={setDocsToGenerate}
          />
        ) : (
          <Box>
            <p
              style={{
                textAlign: "center",
                color: "#cf2229",
                fontSize: "1.3rem",
              }}
            >
              No logs found.
            </p>
          </Box>
        )}
      </MainCard>
    </>
  );
};

export default GenerateFromLogs;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TReportTypeConfig,
  TReportTypeValues,
  TUiSettingsInputFieldValues,
  TWizardData,
} from "../../typings";
import {
  uiSettingsInputFields,
} from "../../Formik/FormFields";
import { stringifyParamValues } from "../../helper";
import { api } from "../../../../../api";
import {
  generateRandomString,
  isOracleError,
} from "../../../../../helper/common";
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Paper,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import ParamInputCard from "../../components/ParamInputCard";
import {
  IconArrowLeft,
  IconCirclePlus,
  IconCircleX,
  IconDeviceFloppy,
  IconCornerDownRight,
  IconTrash,
} from "@tabler/icons";
import { showCustomNotification } from "../../../../../helper/customNotification";
import { useQueryClient } from "@tanstack/react-query";

/**
 * * Component to add Ui Settings (Params)
 */

type TProps = {
  authUser: any;
  next: () => void;
  prev: () => void;
  handleWizardClose: () => void;
  selectedReportType: TReportTypeValues | undefined;
  wizardDataParam: Record<string, TUiSettingsInputFieldValues> | null;
  setWizardData: React.Dispatch<React.SetStateAction<TWizardData>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reportProcedure: string;
  controlOptions: { label: string; value: string }[] | undefined;
};

const StepUiSettings = ({
  authUser,
  next,
  prev,
  handleWizardClose,
  selectedReportType,
  setLoading,
  wizardDataParam,
  setWizardData,
  controlOptions,
  reportProcedure,
}: TProps) => {
  const [paramJsonValue, setParamJsonValue] = useState<string>("");
  const [paramsToDelete, setParamsToDelete] = useState<
    Record<string, TUiSettingsInputFieldValues>
  >({});
  const [paramCards, setParamCards] = useState<
    Record<string, TUiSettingsInputFieldValues>
  >({});
  const [paramCardValues, setParamCardValues] = useState<
    Record<string, TUiSettingsInputFieldValues>
  >({});
  const [triggerFieldTouch, setTriggerFieldTouch] = useState(false);
  const [isFormValid, setIsFormValid] = useState<Record<string, boolean>>({});
  const [validateParam, setValidateParam] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isNextClicked = useRef(false);

  const uiSettingsInputFieldsWithCtrls = useMemo(() => {
    // get params from procedure
    // // regex to get procedure params - procName(:param1,:param2,:param3)
    // const paramArray = reportProcedure?.match(/(?<=:).*?(?=,|\))/g);
    // const paramOptions = paramArray?.map((param) => ({
    //   label: param,
    //   value: param,
    // }));
    return uiSettingsInputFields.map((field) =>
      field.fieldName === "control_id"
        ? { ...field, fieldOptions: controlOptions ?? [] }
        : // : field.fieldName === "param"
          // ? { ...field, fieldOptions: paramOptions ?? [] }
          field
    );
  }, [controlOptions]);

  const isSkipAvailable = useMemo(() => {
    if (Object.keys(paramCards).length) {
      return Object.values(paramCards).some((paramKey) => paramKey.param_id);
    }
  }, [paramCards]);

  //  Generate final Param Json Value from user inputs excluding empty fields
  useEffect(() => {
    const Json_Data = stringifyParamValues(paramCardValues, "Params");
    setParamJsonValue(Json_Data);
  }, [paramCardValues, setParamJsonValue]);

  // const isUniqueParamValue = (fieldName: string, value: string) => {
  //   if (fieldName !== "param") {
  //     return true;
  //   }
  //   const paramValues = Object.values(paramCardValues).filter(
  //     (val) => val.param === value
  //   );
  //   if (paramValues.length > 1) return false;
  //   else return true;
  // };

  // const duplicateErrors = () => {
  //   const duplicates = Object.entries(paramCardValues).reduce(
  //     (acc, [paramkey, value], index) => {
  //       const duplicateVal = Object.entries(paramCardValues)
  //         .slice(index + 1)
  //         .find(([key, val]) => key !== paramkey && val.param === value.param);
  //       if (duplicateVal) return [...acc, duplicateVal[0]];
  //       else return [...acc];
  //     },
  //     [] as string[]
  //   );
  //   return Array.from(new Set(duplicates));
  // }

  const handleAddParams = async () => {
    try {
      setTriggerFieldTouch(true);
      setLoading(true);
      if (Object.values(isFormValid).some((valid) => !valid)) {
        setLoading(false);
        return;
      }
      if (selectedReportType) {
        const P_Json_Data = stringifyParamValues(paramCardValues, "Params");
        const isParamsToDelete = !!Object.keys(paramsToDelete).length;
        const jsonToDelete =
          isParamsToDelete && stringifyParamValues(paramsToDelete, "Params");
        const payload = {
          P_Cr_User: authUser.user,
          reportId: selectedReportType.reportId,
          reportParams: P_Json_Data,
          paramsToDelete: jsonToDelete,
        };
        const response = await api.post(
          "customReport/params/create",
          undefined,
          payload
        );
        // console.log(response);
        if (!response.status) {
          throw new Error(response.message);
        }
        queryClient.setQueryData<TReportTypeConfig>(
          ["uiConfig", "reportType", selectedReportType.reportId],
          (oldData) => {
            if (!oldData)
              return {
                reportParams: response.result,
                reportSubParams: null,
                reportOutParams: [],
              };
            return {
              ...oldData,
              reportParams: response.result,
            };
          }
        );
        isNextClicked.current = true;
        let params = response.result.reduce((acc: any, curr: any) => {
          const id = generateRandomString(6);
          return { ...acc, [id]: curr };
        }, {});
        setWizardData((prev) => ({
          ...prev,
          reportParams: params,
        }));
      }
      queryClient.cancelQueries(["reportGeneration-types"]);
      queryClient.invalidateQueries(["reportGeneration-types"]);
      next();
    } catch (error: any) {
      // console.log(error);
      if (isOracleError(error.message)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Oracle Error",
            message: error.message,
            type: "ORACLE_ERROR",
          },
        });
      } else {
        showCustomNotification({
          title: "Error",
          message: error.message,
          notifyType: "ERROR",
        });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    if (isNextClicked.current) {
      isNextClicked.current = false;
      return;
    }
    if (wizardDataParam) {
      setParamCardValues({});
      if (!!Object.keys(wizardDataParam).length) {
        const errorValues = Object.keys(wizardDataParam).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        );
        setIsFormValid(errorValues);
        setParamCards(wizardDataParam);
      }
    }
  }, [wizardDataParam]);

  const availableParamsInReportProc = useMemo(() => {
    const params = reportProcedure.match(/(?<=:).*?(?=,|\))/g);
    return params?.map((value) => value.toUpperCase());
  }, [reportProcedure]);

  const uiSettingsValidationSchema = useCallback(
    (values: TUiSettingsInputFieldValues) => {
      let errors: any = {};

      if (!values.param) errors = { ...errors, param: "Required" };
      else if (
        availableParamsInReportProc &&
        !availableParamsInReportProc.includes(values.param.toUpperCase())
      )
        errors = { ...errors, param: "does not exist in report procedure" };
      else {
        const paramValues = Object.values(paramCardValues).filter(
          (val) => val.param + "" === values.param + ""
        );
        // console.log(paramValues);
        if (paramValues.length > 1)
          errors = { ...errors, param: "must be unique" };
      }

      if (!values.order) errors = { ...errors, order: "Required" };
      else if (!/^\d+$/.test(values.order.toString())) {
        errors = {
          ...errors,
          order: "must specify a number",
        };
      } else {
        const paramValues = Object.values(paramCardValues).filter(
          (val) => val.order + "" === values.order + ""
        );
        // console.log(paramValues);
        if (paramValues.length > 1)
          errors = { ...errors, order: "must be unique" };
      }

      if (!values.control_id) errors = { ...errors, control_id: "Required" };
      if (!values.type) errors = { ...errors, type: "Required" };
      if (!values.data_type) errors = { ...errors, data_type: "Required" };
      if (!values.label) errors = { ...errors, label: "Required" };
      if (!values.value_type) errors = { ...errors, value_type: "Required" };
      if (values.value_type === "DYNAMIC" && !values.parm_val)
        errors = { ...errors, parm_val: "Required" };
      if (!values.ui_visible) errors = { ...errors, ui_visible: "Required" };
      if (!values.mandatory_field)
        errors = { ...errors, mandatory_field: "Required" };

      return errors;
    },
    [validateParam, availableParamsInReportProc]
  );

  return (
    <Grid>
      <Grid.Col xs={12} my={2} px={2}>
        <Text align="center">
          Here you can enter the details of each control fields of the report
          type which is shown in the Ui.
        </Text>
      </Grid.Col>
      {selectedReportType && isSkipAvailable && (
        <Grid.Col xs={12} sx={{ display: "flex", justifyContent: "end" }}>
          <Button variant="outline" onClick={next}>
            Skip
          </Button>
        </Grid.Col>
      )}
      <Grid.Col xs={12}>
        <Grid gutter="xs">
          {Object.keys(paramCards).length > 0 &&
            Object.keys(paramCards).map((paramKey) => (
              <Grid.Col xs={12} key={paramKey}>
                <Paper shadow="sm" p="md">
                  <Grid gutter="sm">
                    <ParamInputCard
                      paramKey={paramKey}
                      paramCardValues={paramCards[paramKey]}
                      setParamCardValues={setParamCardValues}
                      validation={uiSettingsValidationSchema}
                      inputFields={uiSettingsInputFieldsWithCtrls}
                      paramType="Params"
                      setIsFormValid={setIsFormValid}
                      triggerFieldTouch={triggerFieldTouch}
                      setTriggerFieldTouch={setTriggerFieldTouch}
                      validateParam={validateParam}
                      setValidateParam={setValidateParam}
                    />
                    <Grid.Col
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip label="Delete field">
                        <ActionIcon
                          size="md"
                          onClick={() => {
                            const { [paramKey]: param, ...newParams } =
                              paramCards;
                            const { [paramKey]: paramVal, ...newParamVals } =
                              paramCardValues;
                            if (paramCards[paramKey].param_id !== "") {
                              setParamsToDelete((prev) => ({
                                ...prev,
                                [paramKey]: param,
                              }));
                            }
                            setParamCards(newParams);
                            setParamCardValues(newParamVals);
                            const {
                              [paramKey]: paramFormValid,
                              ...newParamFormValid
                            } = isFormValid;
                            setIsFormValid(newParamFormValid);
                          }}
                          // disabled={
                          //   Object.keys(paramCards).length === 1 ||
                          //   !!paramCards[paramKey].param_id
                          // }
                        >
                          <IconTrash fontSize="medium" />
                        </ActionIcon>
                      </Tooltip>
                    </Grid.Col>
                  </Grid>
                </Paper>
              </Grid.Col>
            ))}
        </Grid>
      </Grid.Col>
      {/* <Grid.Col xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Tooltip label="Add field">
          <ActionIcon
            size="md"
            onClick={() => {
              const id = generateRandomString(6);
              setParamCards((prev) => ({
                ...prev,
                [id]: uiSettingsInputFieldValues,
              }));
              setIsFormValid((prev) => ({
                ...prev,
                [id]: false,
              }));
            }}
          >
            <IconCirclePlus fontSize="medium" />
          </ActionIcon>
        </Tooltip>
      </Grid.Col> */}
      <Grid.Col xs={12} mt={2}>
        <Textarea
          id="read-only-params-textfield"
          value={paramJsonValue}
          label="Preview in Json format"
          maxRows={5}
          readOnly
        />
      </Grid.Col>
      {/* <Grid.Col xs={1} display="flex" justifyContent="center">
        <IconButton
          size="large"
          color="primary"
          aria-label="refresh field"
          onClick={() => {
            const Json_Data = stringifyParamValues(paramCardValues, "Params");
            setParamJsonValue(Json_Data);
          }}
        >
          <RefreshIcon fontSize="inherit" />
        </IconButton>
      </Grid.Col> */}
      <Grid.Col xs={12} mt={1}>
        <Group spacing="sm" position="right">
          {/* <Grid.Col xs={3}> */}
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconArrowLeft />}
            onClick={() => {
              // if (!selectedReportType) {
              //   if (
              //     Object.values(isFormValid).some((valid) => !valid) &&
              //     Object.keys(paramCards).length !== 1
              //   ) {
              //     setTriggerFieldTouch(true);
              //     setLoading(false);
              //     return;
              //   } else if (Object.values(isFormValid).every((valid) => valid))
              //     setWizardData((prev) => ({
              //       ...prev,
              //       reportParams: paramCardValues,
              //     }));
              // }
              prev();
            }}
          >
            Back
          </Button>
          {/* </Grid.Col> */}
          {/* <Grid.Col xs={3} sx={{ display: "flex", justifyContent: "center" }}> */}
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconCornerDownRight />}
            onClick={() => {
              setParamCards({});
              setParamCardValues({});
              setIsFormValid({});
              setTimeout(() => {
                if (wizardDataParam) {
                  if (!!Object.keys(wizardDataParam).length) {
                    const errorValues = Object.keys(wizardDataParam).reduce(
                      (acc, key) => ({ ...acc, [key]: false }),
                      {}
                    );
                    setParamsToDelete({});
                    setIsFormValid(errorValues);
                    setParamCards(wizardDataParam);
                  }
                }
              }, 0);
            }}
          >
            Reset
          </Button>
          {/* </Grid.Col> */}
          {/* <Grid.Col xs={3}> */}
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconCircleX />}
            onClick={() => {
              handleWizardClose();
            }}
          >
            Cancel
          </Button>
          {/* </Grid.Col> */}
          {/* <Grid.Col xs={3}> */}
          <Button
            leftIcon={<IconDeviceFloppy />}
            variant="filled"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            onClick={handleAddParams}
          >
            Save & Next
          </Button>
          {/* </Grid.Col> */}
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default StepUiSettings;

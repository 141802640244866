import {useMantineTheme} from "@mantine/core";
import { ExcelUploadResponseProps} from "./FileUploadDetails/types";
import {
    getValidatedData,
    uploadExcelForValidation,
} from "../queries";
import {useMutation, useQuery} from "@tanstack/react-query";
import FileDropZoneSmall from "../../../../components/FileDropZone/FileDropZoneSmall";
import {showCustomNotification} from "../../../../helper/customNotification";
import {
    Box,
    Group,
    Loader,
    LoadingOverlay,
    Text,
} from "@mantine/core";
import {useEffect, useState} from "react";
import FileDetails from "./FileUploadDetails/FileDetails";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const FileUpload = ({
                        reportCtrl,
                        formValues,
                        setFieldValue,
                        touched,
                        errors,
                        label,
                        setInitialValues,
                    }: any) => {

    const theme = useMantineTheme();
    const reportCtrlUpper = (reportCtrl.PRAM_NAME + "").toUpperCase();

    const [fileRefId, setFileRef] = useState<string | null>(null);
    const [jsonData, setJsonData] = useState<any[]>([]);
    const [hasInvalidData, setInvalid] = useState<boolean>(false);
    const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);

    function refreshState() {
        setFileRef(null);
        setJsonData([]);
        setInvalid(false);
    }
    const {mutate: mutUploadExcel, isLoading} = useMutation(
        uploadExcelForValidation,
        {
            onError: (error: Error) => {
                showCustomNotification({
                    title: "Error",
                    message: "failed to upload excel",
                    notifyType: "ERROR",
                });
            },
            onSuccess: (data: ExcelUploadResponseProps) => {

           //     console.log(data)
                setFileRef(data.fileRefId);
            },
        }
    );
    const {isFetching: isLoadingValidatedData, refetch} = useQuery(
        ["excel", "validated"],
        () => getValidatedData(fileRefId ? fileRefId : ""),
        {
            enabled: false,
            onSuccess(data) {

                setJsonData(JSON.parse(data.P_Data.toString()));
              //  setAddModalOpen(true)
                setFieldValue(reportCtrlUpper, {
                    value: fileRefId,
                    label: fileRefId,
                });
                setInvalid(
                    JSON.parse(data.P_Data.toString()).filter((row: any) => row["Error Messages"]).length > 0
                );
            },
            onError(err) {
                console.log("error : ", err);
                showCustomNotification({
                    title: "Server Error",
                    message: "Failed to validate input data",
                    notifyType: "ERROR",
                });
            },
        }
    );
    useEffect(() => {
        if (fileRefId) refetch();
    }, [fileRefId]);

    function readExcelFile(file: File) {
        if (file) {
            const formData = new FormData();

            formData.append("report_excel_data", file);
            mutUploadExcel(formData);
        }
    }
    const handleAddModalClose = () => {
        setAddModalOpen(false);
    };
    return (
        <div>
            <LoadingOverlay
                loader={
                    <div
                        style={{
                            display: "flex",
                            flex: 2,
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Loader/>
                        {isLoading
                            ? "Loading..."
                            : isLoadingValidatedData
                                ? "Validating uploaded data..."
                                : null}
                    </div>
                }
                visible={isLoading || isLoadingValidatedData}
            />
            <Group spacing="xl" pb="xl" position="apart" align="start">
                <Box sx={{width: "35rem"}}>
                    <Text style={{color: "#212529", fontSize: '14px',fontWeight:500}}>{label}</Text>
                    <FileDropZoneSmall
                        loading={false}
                        multiple={false}
                        onDrop={(files) => readExcelFile(files[0])}
                    />

                    <Text style={{color: "#fa5252",fontSize: '12px'}}>{errors[reportCtrlUpper]?.value}</Text>
                </Box>
            </Group>


            {jsonData.length && fileRefId ? (
                <Box>
                    <Group position="right" sx={{marginTop:'-20px'}}>
                        <RemoveRedEyeIcon  sx={{color:theme.colors[theme.primaryColor][6]}} onClick={() => setAddModalOpen(true) } />
                    </Group>

                    <FileDetails
                        isModalOpen={isAddModalOpen}
                        handleModalClose={handleAddModalClose}
                        jsonData={jsonData}
                        hasInvalidData={hasInvalidData}
                        modalTitle="Add Control"
                    />
                </Box>


            ) : null}
        </div>
    );
}

export default FileUpload;

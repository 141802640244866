import {Dispatch, useMemo, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button,
    Divider,
    Grid,
    Group,
    Paper,
    Menu,
    Text,
    useMantineTheme,
} from "@mantine/core";
import ReportCardLoader from "./ReportCardLoader";
import dayjs from "dayjs";
import {IconClipboard, IconDownload, IconFileText} from "@tabler/icons";
import OptionsToPrintReportModal from "./OptionsToPrintReport";
import {QueryClient, useQuery, useQueryClient} from "@tanstack/react-query";
import {generateReport} from "./queries";
import {generateRandomString} from "../../../helper/common";
import {TReportCheckedColumns, TReportToGenerateData} from "../typings";

type PropsType = {
    data: TReportToGenerateData;
};

type TGeneratedReport = {
    docs: Record<"pdf" | "excel", string>;
    generatedTime: Date;
};

type TGenerateReportProps = {
    data: TReportToGenerateData;
    authUser: any;
    checkedColumns: TReportCheckedColumns;
    selectedDocs: {
        pdf: boolean;
        excel: boolean;
    };
    dispatch: Dispatch<any>;
    progressDetails: {
        id: string;
        reportName: string;
        reportDetails: string;
        message: string;
    };
    queryClient: QueryClient;
};

const ReportGenerationCard = ({data}: PropsType) => {
    const {authUser}: RootStateOrAny = useSelector((state: any) => state.auth);
    const theme = useMantineTheme();

    const [generateReportProps, setGenerateReportProps] =
        useState<TGenerateReportProps>();
    const [isModalOpen, setModalOpen] = useState(false);
    const id = useMemo(() => generateRandomString(6), []);
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const {
        isFetching,
        data: generatedDocs,
        refetch,
    } = useQuery<TGeneratedReport, Error>(
        ["reportGeneration-generate", [data.reportId]],
        () => generateReport(generateReportProps),
        {
            enabled: !!generateReportProps,
            refetchIntervalInBackground: true,
            cacheTime: Infinity,
            staleTime: Infinity,
            retry: false,
            // onSuccess: () => {
            //   queryClient.cancelQueries(["reportLogs"]);
            //   queryClient.invalidateQueries(["reportLogs"]);
            // },
            // onError: (error) => {
            //   dispatch({
            //     type: "OPEN_SNACK_TOAST",
            //     payload: { title: "Server Error", message: error.message },
            //   });
            // },
        }
    );

    const handleGenerateReport = ({
                                      checkedColumns,
                                      selectedDocs,
                                  }: {
        checkedColumns: TReportCheckedColumns;
        selectedDocs: {
            pdf: boolean;
            excel: boolean;
        };
    }) => {
        let progressDetails = {
            id,
            reportName: "",
            reportDetails: "",
            message: "",
        };
        if (!!generateReportProps) refetch();
        else
            setGenerateReportProps({
                data,
                authUser,
                checkedColumns,
                selectedDocs,
                dispatch,
                progressDetails,
                queryClient,
            });
    };

    const handleDownloadReport = async (doc: "pdf" | "excel") => {
        try {
            if (generatedDocs && generatedDocs.docs[doc]) {
                fetch(generatedDocs.docs[doc]).then((resp) => {
                    resp.blob().then((blob) => {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        const linkString = generatedDocs.docs[doc].split("/").pop();
                        if (linkString) {
                            link.download = linkString;
                            link.click();
                        }
                    });
                });
            }
        } catch (error) {
            console.log("catched error : ", error);
        }
    };

    return (
        <>
            {/* {
            <ReportGeneratedMessage
              isError={isError}
              isReport={!!report?.Stklst?.length}
              theme={theme}
            />
          } 
      */}
            <OptionsToPrintReportModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                handleGenerateReport={handleGenerateReport}
                tableColumns={data.checkedColumns}
            />
            <Paper sx={{position: "relative"}}>
                {isFetching && <ReportCardLoader/>}
                <Group position="apart" mb={10}>
                    <Text weight="bold" size="lg">
                        {data.payload.reportName}
                    </Text>
                    {generatedDocs &&
                    (generatedDocs.docs.pdf || generatedDocs.docs.excel) ? (
                        <Menu shadow="md" width={160}>
                            <Menu.Target>
                                <Button rightIcon={<IconDownload size={22}/>}>Download</Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                {generatedDocs.docs.pdf && (
                                    <Menu.Item
                                        onClick={() => handleDownloadReport("pdf")}
                                        icon={<IconFileText size={20}/>}
                                    >
                                        <Text weight={500}>PDF</Text>
                                    </Menu.Item>
                                )}
                                {generatedDocs.docs.excel && (
                                    <Menu.Item
                                        onClick={() => handleDownloadReport("excel")}
                                        icon={<IconFileText size={20}/>}
                                    >
                                        <Text weight={500}>Excel</Text>
                                    </Menu.Item>
                                )}
                            </Menu.Dropdown>
                        </Menu>
                    ) : (
                        <Button onClick={() => setModalOpen(true)}>Generate</Button>
                    )}
                </Group>

                <Grid gutter="sm" ml={15}>
                    {!!data?.stockValues &&
                    Object.entries(data.stockValues).map(function (stock:any) {
                        // {
                        //    // console.log(stock[1])
                        // }
                        if (stock[1].label) {
                            return  <Grid.Col xs={6} md={4} key={stock[0]}>
                                <Group>
                                    <IconClipboard size={28} color={theme.colors.ffcBrand[6]} />
                                    <Box styles={{ display: "flex", direction: "column" }}>
                                        <Text weight={500}>{stock[1].header}</Text>
                                        <Text size="sm">
                                            {stock[1].label instanceof Date
                                                ? dayjs(stock[1].label).format("DD/MM/YYYY")
                                                : stock[1].label}
                                        </Text>
                                    </Box>
                                </Group>
                            </Grid.Col>
                        } else {
                            return null
                        }
                    })}

                    <Grid.Col xs={12} md={8}>
                        {!isFetching &&
                        generatedDocs &&
                        (generatedDocs.docs.pdf || generatedDocs.docs.excel) &&
                        generatedDocs.generatedTime && (
                            <Text mt={10} weight={500}>
                                Report generated on{" "}
                                {dayjs(generatedDocs.generatedTime).format(
                                    "DD/MM/YYYY h:mm:ss A"
                                )}
                            </Text>
                        )}
                    </Grid.Col>
                </Grid>
            </Paper>
            <Divider/>
        </>
    );
};

export default ReportGenerationCard;

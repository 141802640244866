import {useState,useEffect} from "react";
import {
    AppShell,
    Navbar,
    MediaQuery,
    Burger,
    useMantineTheme,
    ScrollArea,
    Divider,
    UnstyledButton,
    Group,
    ThemeIcon,
} from "@mantine/core";
import {IconLogout} from "@tabler/icons";
import {Outlet} from "react-router-dom";
import NavMenuList from "./Sidebar/NavMenuList";
import FFCLogo from "../assets/images/logo/ffc-short-logo.png";
import FinanceSample from "../assets/documents/ffclog1.jpg";

import {useDispatch} from "react-redux";
import GenerationHistory from "../views/report/GenerationHistory";
import {useQueryClient} from "@tanstack/react-query";
import IdleTimeOutHandler from '../components/IdleTime/IdleTimeOutHandler'
import {setMenuState} from "../store/menuStateSlice";
import {api} from "../api";

export default function AppLayout() {
    const theme = useMantineTheme();
    const [isNavMenuOpened, setNavMenuOpened] = useState(false);
    const [isActive, setIsActive] = useState(true)
    const [isLogout, setLogout] = useState(false)
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const handleLogout = () => {
        queryClient.removeQueries();
        dispatch({
            type: "LOGOUT",
        });
    };
    const getMenus:any = async () => {
        try {
            await api
                .get("users/menus")
                .then((response) => dispatch(setMenuState(response.data)));
        } catch (error: any) {
            return console.error(error.message);
        }
    };

    useEffect(() => {
        getMenus();
    });
    return (

        <AppShell
            styles={{
                main: {
                    background: theme.colors.gray[0],
                },
            }}
            navbarOffsetBreakpoint="sm"
            fixed
            navbar={
                <Navbar
                    sx={(theme) => ({backgroundColor: theme.colors.ffcBrand[7]})}
                    p="md"
                    hiddenBreakpoint="sm"
                    hidden={!isNavMenuOpened}
                    width={{sm: 200, md: 250}}
                >
                    <Navbar.Section mt="xs">
                        <MediaQuery largerThan="sm" styles={{display: "none"}}>
                            <Burger
                                opened={isNavMenuOpened}
                                onClick={() => setNavMenuOpened((o) => !o)}
                                size="sm"
                                color={theme.colors.gray[6]}
                                mb="md"
                            />
                        </MediaQuery>
                    </Navbar.Section>

                    <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "#fff",
                                    width: "10rem",
                                    height: "10rem",
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                }}
                            >
                                <img
                                    style={{
                                        objectFit: "none",
                                        width: "100%",
                                    }}
                                    src={FFCLogo}
                                    alt="FFC"
                                />
                            </div>
                        </div>
                        <NavMenuList menuType="main"/>
                    </Navbar.Section>

                    <Navbar.Section>
                        <Divider my="sm"/>
                        <NavMenuList menuType="footer"/>
                        <UnstyledButton
                            sx={{
                                display: "block",
                                width: "100%",
                                padding: theme.spacing.xs,
                                borderRadius: theme.radius.sm,
                                color: theme.white,
                                paddingLeft: theme.spacing.sm,

                                "&:hover": {
                                    backgroundColor: theme.colors.indigo[8],
                                },
                                "&:active": {
                                    backgroundColor: theme.colors.indigo[7],
                                },
                            }}
                            onClick={handleLogout}
                        >
                            <Group>
                                <ThemeIcon>
                                    <IconLogout size={20} color={theme.white}/>
                                </ThemeIcon>

                                <div style={{display: "inline", fontSize: 14}}>Logout</div>
                            </Group>
                        </UnstyledButton>
                    </Navbar.Section>
                </Navbar>
            }
        >
            <MediaQuery largerThan="sm" styles={{display: "none"}}>
                <Burger
                    opened={isNavMenuOpened}
                    onClick={() => setNavMenuOpened((prev) => !prev)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mb="md"
                />
            </MediaQuery>
            <GenerationHistory/>
            <Outlet/>

            <IdleTimeOutHandler
                onActive={() => {
                    setIsActive(true)
                }}
                onIdle={() => {
                    setIsActive(false)
                }}
                onLogout={() => {
                    setLogout(true)
                }}
            />

        </AppShell>
    );
}

import { Button, Grid, Group, LoadingOverlay, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainCard from "../../components/Cards/MainCard";
import AddTask from "./AddTask";
import { getTimesheetData } from "./queries";
import TimesheetTable from "./TimesheetTable";
import { TimesheetResultProps } from "./types";

const Timesheet = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { authUser } = useSelector((state: any) => state.auth);
  const [formDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  // const [timesheetData, setTimesheetData] = useState<TimesheetResultProps[]>(
  //   []
  // );

  const {
    isLoading,
    data: timesheetData,
    refetch,
  } = useQuery<TimesheetResultProps[], Error>(
    ["timesheet", "list"],
    () => getTimesheetData(authUser.user_emp_code, formDate, toDate),
    {
      onError: (error: any) => {
        console.log(error);
      },
      onSuccess: (data) => {
        console.log("data : ", data);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [formDate, toDate]);
  return (
    <MainCard title="Timesheet" titleOrder={2}>
      <LoadingOverlay visible={isLoading} />
      <AddTask isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
      <Grid>
        <Grid.Col lg={6}>
          <Group position="left">
            <Grid align={"center"}>
              <Grid.Col xs={12} md={2}>
                <Text>From</Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <DatePicker
                  value={formDate}
                  onChange={(newDate: Date) => {
                    setFromDate(newDate);
                  }}
                  clearable={false}
                  rightSection={<IconCalendar size={18} color="#25338e" />}
                  zIndex={500}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={2}>
                <Text>To</Text>
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <DatePicker
                  value={toDate}
                  onChange={(newDate: Date) => {
                    setToDate(newDate);
                  }}
                  clearable={false}
                  rightSection={<IconCalendar size={18} color="#25338e" />}
                  zIndex={500}
                />
              </Grid.Col>
            </Grid>
          </Group>
        </Grid.Col>

        <Grid.Col lg={6}>
          <Group position="right">
            <Button onClick={() => setModalOpen(true)}>Add Task</Button>
          </Group>
        </Grid.Col>
      </Grid>

      <TimesheetTable data={timesheetData} />
    </MainCard>
  );
};

export default Timesheet;

import { api } from "../../../api";
import { TReportTypeValues } from "./typings";

export const getControlOptions = async () => {
  const controlsResponse = await api.get("controls/list");
  if (controlsResponse.status) {
    const controls: {
      CRUIC_ID: string | number;
      CRUIC_NAME: string;
      CRUIC_CODE: string;
    }[] = JSON.parse(controlsResponse.data.P_RefCur);
    const options = controls.map((control) => ({
      label: control.CRUIC_NAME,
      value: control.CRUIC_ID + "",
      code: control.CRUIC_CODE,
    }));
    return options;
  } else throw new Error(controlsResponse.message);
};

export const getReportList = async () => {
  const response = await api.get("customReport/getReportTypes");
  if (response.status) {
    return response.result;
  } else throw new Error(response.message);
};

export const getReportTypeConfig = async (
  reportType: TReportTypeValues | undefined
) => {
  if (!reportType) return;
  const response = await api.post(
    "customReport/getReportParams",
    undefined,
    reportType
  );
  if (response.status) {
    return response.result;
  } else throw new Error(response.message);
};

export const getReportProcParameters = async (procedureName: string) => {
  if (!procedureName) return;
  const response = await api.post("customReport/getParameters", undefined, {
    procedureName,
  });
  if (response.status) {
    return response.result;
  } else throw new Error(response.message);
};

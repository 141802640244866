import React, {useEffect, useState} from 'react';
import moment from 'moment'
import {useQueryClient} from "@tanstack/react-query";
import {useDispatch} from "react-redux";

const IdleTimeOutHandler = (props: any) => {
   // const [showModal, setShowModal] = useState(false)
    const [isLogout, setLogout] = useState(false)
    let timer: any = undefined;
    const events = ['click', 'load', 'keydown']
    const eventHandler = (eventType: any) => {
      //  console.log(eventType)
        if (!isLogout) {
            var k: any = moment
            localStorage.setItem('lastInteractionTime', k)
            if (timer) {
                props.onActive();
                startTimer();
            }
        }
    };
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    useEffect(() => {
        addEvents();
        return (() => {
            removeEvents();
            clearTimeout(timer);
        })
    }, [])


    const startTimer = () => {

        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
           console.log(process.env.REACT_APP_IDLE_TIMEOUT)
            let lastInteractionTime = localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            let timeOutInterval = props.timeOutInterval ? props.timeOutInterval : process.env.REACT_APP_IDLE_TIMEOUT;
            if (isLogout) {
                clearTimeout(timer)
            } else {
                if (diff.milliseconds < timeOutInterval) {
                    startTimer();
                    props.onActive();
                } else {
                    props.onIdle();
                    queryClient.removeQueries();
                    dispatch({
                        type: "LOGOUT",
                    });
                  //  setShowModal(true)
                }
            }

        }, props.timeOutInterval ? props.timeOutInterval : process.env.REACT_APP_IDLE_TIMEOUT)


    }
    const addEvents = () => {

        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler)
        })

        startTimer();
    }

    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler)
        })
    };

    // const handleContinueSession = () => {
    //  //   setShowModal(false)
    //     setLogout(false)
    // }
    //
    // const handleLogout = () => {
    //     removeEvents();
    //     clearTimeout(timer);
    //     setLogout(true)
    //     props.onLogout();
    //  //   setShowModal(false)
    //     queryClient.removeQueries();
    //     dispatch({
    //         type: "LOGOUT",
    //     });
    //
    // };
    return (
        <div></div>
    )

}

export default IdleTimeOutHandler;
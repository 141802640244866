import { Text } from "@mantine/core";
import { closeAllModals, openConfirmModal } from "@mantine/modals";

export const openDeleteModal = ({ onConfirm }: { onConfirm: any }) =>
  openConfirmModal({
    title: "Delete control configuration",
    centered: true,
    children: (
      <Text size="sm">
        Are you sure you want to delete this control configuration?
      </Text>
    ),
    labels: { confirm: "Confirm", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onCancel: () => closeAllModals(),
    onConfirm: () => onConfirm(),
  });

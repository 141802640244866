import {
    Box,
    Button,
    Group,
    Paper,
    Table, Grid, Text,
} from "@mantine/core";

type WpsPropsTypes = {
    wpsListRows: any;
    wpsListColumns: any;
    wpsListData: any;
    downloadLink: any;
};

const WpsList = ({wpsListRows, wpsListColumns, wpsListData, downloadLink}: WpsPropsTypes) => {

    const handleDownload = () => {
        const fileName: any = downloadLink.split("/").pop();
        fetch(downloadLink).then((resp) => {
            resp.blob().then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            });
        });
        return;
    }

    return (
        <>
            <div>
                {wpsListColumns && wpsListColumns.length > 0 && (
                    <Grid gutter="md" mb="lg" sx={{maxWidth: "100%"}} mt='10px' ml='30px'>
                        <Paper px="xl" py={35}
                               shadow="lg" radius="lg"
                               sx={{height: "100%", border: '2px solid #FFF'}}>
                            <Group position="apart">
                                <Box sx={(theme) => ({color: theme.colors[theme.primaryColor][6]})}>
                                    {(wpsListColumns)
                                        .map((row: any, index: any) => {
                                            return <Text key={index} sx={{
                                                lineHeight: '2.5rem',
                                                fontWeight: 400
                                            }}>    {row.value ? row.value : '-'}</Text>
                                        })}
                                </Box>

                                <Box sx={{marginLeft: '60px'}}>
                                    {(wpsListRows)
                                        .map((row: any, index: any) => {
                                            return <Text key={index} sx={{
                                                lineHeight: '2.5rem',
                                                fontWeight: 600
                                            }}>   {row.value ? row.value : '-'}</Text>
                                        })}
                                </Box>
                            </Group>

                            <Group position="apart" mt='40px' ml='240px'>
                                <Button onClick={handleDownload}>Download CSV</Button>
                            </Group>
                        </Paper>
                    </Grid>
                )}
                {wpsListData && wpsListData.length > 0 && (
                    <div>
                        < Box sx={{width: "35%"}} pt="xl" mt="40px">
                            <Paper sx={{width: "100%", mb: 2}}>
                                <Box sx={{display: "flex", justifyContent: "flex-end"}} mb="lg" mt="30px">
                                </Box>
                                <Table
                                    mb={10}
                                    striped
                                    horizontalSpacing="md"
                                    verticalSpacing="sm"
                                >
                                    <tbody>
                                    <tr style={{
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        backgroundColor: '#ccc',
                                        color: '#2e439e'
                                    }}>
                                        {(wpsListData)
                                            .map((row: any, index: any) => {

                                                return (
                                                    <td key={index} align="left"
                                                        style={{fontWeight: 600}}>{row.key}</td>
                                                );
                                            })}
                                        <td></td>
                                    </tr>
                                    <tr style={{paddingTop: 5, paddingBottom: 5}}>
                                        {(wpsListData)
                                            .map((row: any, index: any) => {
                                                return (
                                                    <td key={index} align="left">{row.value}</td>
                                                );
                                            })}
                                        <td><Button onClick={handleDownload}>Download</Button></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Paper>
                        </Box>
                    </div>
                )}
            </div>
        </>
    );
};
export default WpsList;
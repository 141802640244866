import { Grid, LoadingOverlay, Title } from "@mantine/core";
import {
  IconBell,
  IconCalendarTime,
  IconClock,
  IconLogin,
  IconReport,
  IconUserX,
  TablerIcon,
} from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import StatusCard from "../../components/StatusCard";
import { getDashboardData } from "./queries";
import { DashboardProps } from "./types";

type TProps = {
  Icon: TablerIcon;
  title: string;
  subTitle: string;
  color: string;
};

const MyStatus = () => {
  const [data, setData] = useState<TProps[]>([]);

  const { isLoading, data: dashboardData } = useQuery<DashboardProps[], Error>(
    ["dashboard", "data"],
    () => getDashboardData()
  );

  useEffect(() => {
    if (dashboardData?.length) {
      const statusData: TProps[] = [
        {
          Icon: IconLogin,
          title: dashboardData.length? dashboardData[0].PUNCH_TIME:'',
          subTitle: "Today Checked In",
          color: "green",
        },
        {
          Icon: IconCalendarTime,
          title: dashboardData.length?dashboardData[0].TOT_WORK:'',
          subTitle: "Total Working Hours this week",
          color: "blue",
        },
        {
          Icon: IconClock,
          title: dashboardData.length?dashboardData[0].LATE.toString():'',
          subTitle: "Late",
          color: "yellow",
        },
        {
          Icon: IconReport,
          title: dashboardData.length?dashboardData[0].TASK.toString():'',
          subTitle: "Task In Progress",
          color: "orange",
        },
        {
          Icon: IconUserX,
          title: dashboardData.length?dashboardData[0].ABSENT.toString():'',
          subTitle: "Absent",
          color: "red",
        },
        {
          Icon: IconBell,
          title: dashboardData.length? dashboardData[0].NOTIF.toString():'',
          subTitle: "Notification",
          color: "violet",
        },
      ];
      setData(statusData);
    }
  });

  return (
    <>
      <Title order={4}>My Status</Title>
      <LoadingOverlay visible={isLoading} />
      <Grid gutter="xs" mb="md" sx={{ maxWidth: "85rem" }}>
        {data &&
          data.map((data) => (
            <Grid.Col key={data.subTitle} xs={12} md={6} xl={4}>
              <StatusCard
                Icon={data.Icon}
                title={data.title}
                subTitle={data.subTitle}
                color={data.color}
              />
            </Grid.Col>
          ))}
      </Grid>
    </>
  );
};

export default MyStatus;

import { DatePicker } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import { useMantineTheme } from "@mantine/core";
import { useEffect } from "react";

const DateField = ({
  reportCtrl,
  formValues,
  setFieldValue,
  touched,
  errors,
  setInitialValues,
}: any) => {
  const theme = useMantineTheme();
  const reportCtrlUpper = (reportCtrl.PRAM_NAME + "").toUpperCase();

  useEffect(() => {
    if (reportCtrl.BIND_TYPE === "STATIC") {
      setInitialValues((prev: any) => ({
        ...prev,
        [reportCtrlUpper]: {
          value: new Date(reportCtrl.BIND_VALUE),
          label: new Date(reportCtrl.BIND_VALUE),
        },
      }));
    } else {
      setInitialValues((prev: any) => ({
        ...prev,
        [reportCtrlUpper]: {
          value: new Date(),
          label: new Date(),
        },
      }));
    }
  }, [
    reportCtrl.BIND_TYPE,
    reportCtrl.BIND_VALUE,
    reportCtrlUpper,
    setInitialValues,
  ]);

  return (
    <DatePicker
      label={reportCtrl.LABEL_TXT}
      value={
        formValues[reportCtrlUpper].value === ""
          ? null
          : formValues[reportCtrlUpper].value
      }
      inputFormat="DD/MM/YYYY"
      onChange={(newDate: Date) => {
        setFieldValue(reportCtrlUpper, {
          value: newDate,
          label: newDate,
        });
      }}
      clearable={false}
      error={
        !!(touched[reportCtrlUpper] && errors[reportCtrlUpper]) &&
        errors[reportCtrlUpper]?.value
      }
      icon={<IconCalendar size={20} color={theme.colors.ffcBrand[6]} />}
      zIndex={500}
    />
  );
};

export default DateField;

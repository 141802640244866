import { TextInput } from "@mantine/core";
import { TInputField } from "../../typings";

const ParamTextInput = ({
  id,
  // paramKey,
  inputField,
  formik,
  handleModalOpen,
  setValidateParam,
  ...props
}: {
  id: string;
  // paramKey: string;
  inputField: TInputField;
  handleModalOpen?: any;
  setValidateParam?: any;
  formik: any;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const valueFormik = `${paramKey}.${inputField.fieldName}`;
    // formik.setFieldValue(valueFormik, event.target.value);
    formik.setFieldValue(inputField.fieldName, event.currentTarget.value);
  };

  return (
    <TextInput
      id={id}
      label={inputField.fieldLabel}
      value={formik.values[inputField.fieldName]}
      onChange={handleChange}
      disabled={formik.isSubmitting}
      readOnly={inputField.isReadOnly}
      onBlur={() => {
        formik.setFieldTouched(inputField.fieldName);
        if (
          inputField.fieldName === "param" ||
          inputField.fieldName === "sparam" ||
          inputField.fieldName === "oparam" ||
          inputField.fieldName === "order" ||
          inputField.fieldName === "oorder"
        )
          setValidateParam((prev: boolean) => !prev);
      }}
      error={
        !!(
          formik.touched &&
          formik.touched[inputField.fieldName] &&
          formik.errors &&
          formik.errors[inputField.fieldName]
        ) && formik.errors[inputField.fieldName]
      }
      {...props}
    />
    // <TextField
    //   fullWidth
    //   id={id}
    //   label={inputField.fieldLabel}
    //   value={formik.values[paramKey][inputField.fieldName]}
    //   onChange={handleChange}
    //   disabled={formik.isSubmitting}
    //   onBlur={() => {
    //     formik.setFieldTouched(`${paramKey}.${inputField.fieldName}`);
    //   }}
    //   helperText={
    //     formik.touched &&
    //     formik.touched[paramKey] &&
    //     formik.touched[paramKey][inputField.fieldName] &&
    //     formik.errors &&
    //     formik.errors[paramKey] &&
    //     formik.errors[paramKey][inputField.fieldName]
    //   }
    //   error={
    //     !!(
    //       formik.touched &&
    //       formik.touched[paramKey] &&
    //       formik.touched[paramKey][inputField.fieldName] &&
    //       formik.errors &&
    //       formik.errors[paramKey] &&
    //       formik.errors[paramKey][inputField.fieldName]
    //     )
    //   }
    //   {...textFieldConfig}
    //   {...props}
    // />
  );
};

export default ParamTextInput;

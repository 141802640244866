import {
  ActionIcon,
  Box,
  Button,
  Group,
  Pagination,
  Paper,
  Table,
  Tooltip,
} from "@mantine/core";
import { IconChevronDown, IconChevronUp, IconEdit } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../../api";
import MainCard from "../../../components/Cards/MainCard";
import Loader from "../../../components/Loader";
import { isOracleError } from "../../../helper/common";
import { showCustomNotification } from "../../../helper/customNotification";
import ConfigWizard from "./ConfigWizard";
import { getControlOptions, getReportList } from "./queries";
import { TReportTypeConfig, TReportTypeValues } from "./typings";

type Order = "asc" | "desc";
type TableColumnHeadersProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
};

type HeadCell = {
  id: string;
  label: string;
  // align: "left" | "center" | "right" | "justify" | "inherit" | undefined;
};

const headCells: HeadCell[] = [
  {
    id: "reportName",
    label: "Report Name",
    // align: "left",
  },
  {
    id: "reportStatus",
    label: "Report Status",
    // align: "left",
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// function descendingComparator(a: any, b: any, orderBy: any) {
//   // if (!(a[orderBy] && b[orderBy])) return 0;
//   const itemA = isNaN(a[orderBy])
//     ? a[orderBy].split(" ").join("").toLocaleLowerCase()
//     : Number(a[orderBy]);
//   const itemB = isNaN(b[orderBy])
//     ? b[orderBy].split(" ").join("").toLocaleLowerCase()
//     : Number(b[orderBy]);

//   if (itemB < itemA) {
//     return -1;
//   }
//   if (itemB > itemA) {
//     return 1;
//   }
//   return 0;
// }

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const TableColumnHeaders = (props: TableColumnHeadersProps) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead>
      <tr
        style={{
          backgroundColor: "#2e439e",
          // color: "#404040",
        }}
      >
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            align="left"
            style={{ color: "#EAE9E7" }}
            // align={headCell.numeric ? "right" : "left"}
          >
            {headCell.label}
            {/* <Group spacing="xs" onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {order === "desc" ? (
                <IconChevronDown
                  style={{
                    visibility: orderBy === headCell.id ? "visible" : "hidden",
                  }}
                />
              ) : (
                <IconChevronUp
                  style={{
                    visibility: orderBy === headCell.id ? "visible" : "hidden",
                  }}
                />
              )}
            </Group> */}
          </th>
        ))}
        <th></th>
      </tr>
    </thead>
  );
};

const UiConfig = () => {
  const dispatch = useDispatch();
  // const [isLoading, setLoading] = useState<boolean>(false);
  const [isWizardOpen, setWizardOpenState] = useState<boolean>(false);
  // const [reportList, setReportList] = useState<TReportList[]>([]);
  const [reportToEdit, setReportToEdit] = useState<TReportTypeValues>();
  // const [controlOptions, setControlOptions] = useState<
  //   { label: string; value: string }[]
  // >([]);

  const { isLoading, data: reportList } = useQuery<TReportTypeValues[], Error>(
    ["uiConfig", "reportList"],
    getReportList,
    {
      onError: (error) => {
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  const { isLoading: ctrlOptionsLoading, data: controlOptions } = useQuery<
    {
      label: string;
      value: string;
    }[],
    Error
  >(["uiConfig", "controlOptions"], getControlOptions, {
    onError: (error) => {
      if (isOracleError(error.message)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Oracle Error",
            message: error.message,
            type: "ORACLE_ERROR",
          },
        });
      } else {
        showCustomNotification({
          title: "Error",
          message: error.message,
          notifyType: "ERROR",
        });
      }
    },
  });

  // table properties
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("reportName");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (reportList?.length ?? 0))
      : 0;

  const totalPages = Math.ceil((reportList?.length ?? 0) / rowsPerPage);

  return (
    <>
      <Loader isLoading={isLoading || ctrlOptionsLoading} />
      <ConfigWizard
        isWizardOpen={isWizardOpen}
        reportList={reportList}
        setWizardOpenState={setWizardOpenState}
        reportToEdit={reportToEdit}
        setReportToEdit={setReportToEdit}
        controlOptions={controlOptions}
      />
      <MainCard title="UI Configuration">
        <Box sx={{ width: "100%" }} pt="xl">
          {reportList && (
            <>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }} mb="lg">
                <Button onClick={() => setWizardOpenState(true)}>Add</Button>
              </Box>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <Table
                  mb={10}
                  striped
                  horizontalSpacing="md"
                  verticalSpacing="sm"
                >
                  <TableColumnHeaders
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={reportList.length}
                  />
                  <tbody>
                    {!!reportList.length ? (
                      reportList
                        .slice()
                        .sort(getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <tr
                              style={{
                                paddingTop: 5,
                                paddingBottom: 5,
                                ...(row.reportStatus === "L" && {
                                  color: "#BF181D",
                                  backgroundColor: "#ffe8e7",
                                }),
                              }}
                              key={`reportListTable-${row.reportId}`}
                            >
                              <td align="left">{row.reportName}</td>
                              <td align="left">
                                {row.reportStatus === "O" ? "Open" : "Lock"}
                              </td>

                              <td align="right">
                                <Tooltip label="Edit control">
                                  <ActionIcon
                                    size="md"
                                    onClick={() => {
                                      setReportToEdit(row);
                                      setWizardOpenState(true);
                                    }}
                                  >
                                    <IconEdit fontSize="medium" />
                                  </ActionIcon>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          color: "#BF181D",
                        }}
                      >
                        <td colSpan={3} align="center">
                          No reports found.
                        </td>
                      </tr>
                    )}
                    {emptyRows > 0 && (
                      <tr
                        style={{
                          height: 20 * emptyRows,
                        }}
                      >
                        <td colSpan={5} />
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Pagination
                  position="center"
                  page={page + 1}
                  onChange={(page: number) => setPage(page - 1)}
                  withEdges
                  total={totalPages}
                />
              </Paper>
            </>
          )}
        </Box>
      </MainCard>
    </>
  );
};
export default UiConfig;

import { Button, Grid, Modal, Text, Title } from "@mantine/core";
import { IconCircleX, IconDeviceFloppy } from "@tabler/icons";
import TextAreaInput from "../../../components/TextareaInput";
import TextFieldInput from "../../../components/TextFieldInput";

const AddControlModal = ({
  isModalOpen,
  handleModalClose,
  inputFields,
  formik,
  modalTitle,
}: any) => {
  return (
    <Modal
      opened={isModalOpen}
      onClose={() => {
        formik.resetForm();
        handleModalClose();
      }}
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
      // size="50%"
      size="auto"
      zIndex={500}
      // title={modalTitle}
    >
      <Title order={3} align="center" mb={5}>
        {modalTitle}
      </Title>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid gutter="md">
          {inputFields.map((inputField: any) => {
            if (inputField.fieldType === "TXT")
              return (
                <Grid.Col xs={12} key={`addModal-${inputField.fieldName}`}>
                  <TextFieldInput
                    id={inputField.fieldName}
                    label={inputField.fieldLabel}
                    formik={formik}
                    disabled={formik.isSubmitting}
                  />
                </Grid.Col>
              );
            else if (inputField.fieldType === "TXT_AREA")
              return (
                <Grid.Col xs={12} key={`addModal-${inputField.fieldName}`}>
                  <TextAreaInput
                    id={inputField.fieldName}
                    label={inputField.fieldLabel}
                    formik={formik}
                    disabled={formik.isSubmitting}
                  />
                </Grid.Col>
              );
            else
              return (
                <Grid.Col xs={12} key={`addModal-${inputField.fieldName}`}>
                  <Text>input control error.</Text>
                </Grid.Col>
              );
          })}
          <Grid.Col xs={6}>
            <Button
              fullWidth
              leftIcon={<IconCircleX />}
              disabled={formik.isSubmitting}
              variant="outline"
              onClick={() => {
                formik.resetForm();
                handleModalClose();
              }}
            >
              Cancel
            </Button>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Button
              fullWidth
              loading={formik.isSubmitting}
              leftIcon={<IconDeviceFloppy />}
              type="submit"
            >
              Add
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default AddControlModal;

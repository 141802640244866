import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../../api";
import dayjs from "dayjs";
import {
    Box, Grid, Button, TextInput, Text, Group, Select, useMantineTheme,
} from "@mantine/core";
import Loader from "../../../components/Loader";
import MainCard from "../../../components/Cards/MainCard";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import WpsList from "./wpsList";
import {IconCalendar} from "@tabler/icons";
import {LocalizationProvider, MobileDatePicker, DatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {showCustomNotification} from "../../../helper/customNotification";
import {isOracleError} from "../../../helper/common";
import moment from "moment";
import ReportCardLoader from "../../../components/ReportCardLoader";

const Wps = () => {
    const theme = useMantineTheme();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [type, setType] = useState(false);
    const [YrMthPickerPicker, setYrMthPickerPicker] = useState<Date | null>(new Date());
    const [txnCodeOptions, setTxnCodeOptions] = useState<{ label: string; value: string; }[]>([]);
    const [sponsorCodeOptions, setSponsorCodeOptions] = useState<{ label: string; value: string; }[]>([]);
    const [wpsListRows, setWpsListRows] = useState([]);
    const [wpsListColumns, setWpsListColumns] = useState([]);
    const [wpsListData, setWpsListData] = useState([]);
    const [downloadLink, setDownloadLink] = useState('');
    const handleSubmit = async (
        values: any,
        {setErrors, setSubmitting}: any
    ) => {
        try {
            const month = dayjs(YrMthPickerPicker).format("MM");
            const year = dayjs(YrMthPickerPicker).format("YYYY");
            const payload = {
                wps_data_input: values.wps_data_input,
                spons_code: values.spons_code,
                txn_code: values.txn_code,
                YrMth: year + month,
            };
            setLoading(true)
            const resp = await api.post("hrWps/list", undefined, payload);
            if (resp.status) {
                setLoading(false)

                setDownloadLink(resp.data.downloadLink)
                if (values.wps_data_input === 'payslip') {
                    const rowColumns = resp.data.columns.map((row: any) =>
                        Object.entries(row).map(([key, value]) => {
                            return {value: value};
                        })
                    );
                    const rowValues = resp.data.summary.map((row: any) =>
                        Object.entries(row).map(([key, value]) => {
                            return {value: value};
                        })
                    );
                    setWpsListRows(rowValues[0] ? rowValues[0] : [])
                    setWpsListColumns(rowColumns[0] ? rowColumns[0] : [])
                    setWpsListData([])

                } else {
                    const rowData = resp.data.summary.map((row: any) =>
                        Object.entries(row).map(([key, value]) => {
                            return {key: key, value: value};
                        })
                    );
                    setWpsListRows([])
                    setWpsListColumns([])
                    setWpsListData(rowData ? rowData[0] : [])
                }
            } else {
                setLoading(false)
                setErrors({submit: resp.message});
                setSubmitting(false);
                dispatch({
                    type: "OPEN_SNACK_TOAST",
                    payload: {title: "Server Error", message: resp.message},
                });
            }

        } catch (error: any) {
            setErrors({submit: error.message});
            setSubmitting(false);
        }
    };

    const getTxnPayType = async () => {
        try {
            setLoading(true);
            const response = await api.get("hrWps/txnPayType");
            if (response.status) {

                const options = response.result.map((item: any) => ({
                    label: item.PTXNTYPE_NAME,
                    value: item.PTXNTYPE_CODE,

                }));
                setTxnCodeOptions(options);

            } else {
                throw new Error(response.message);
            }
            setLoading(false);
        } catch (error: any) {
            if (isOracleError(error.message)) {
                dispatch({
                    type: "OPEN_ALERT_MODAL",
                    payload: {
                        title: "Oracle Error",
                        message: error.message,
                        type: "ORACLE_ERROR",
                    },
                });
            } else {
                showCustomNotification({
                    title: "Error",
                    message: error.message,
                    notifyType: "ERROR",
                });
            }
            setLoading(false);
        }
    };
    const getSponsorCode = async () => {
        try {
            setLoading(true);
            const response = await api.get("hrWps/sponsorCode");
            if (response.status) {
                const options = response.result.map((item: any) => ({
                    label: item.SPONS_NAME,
                    value: item.SPONS_CODE,
                }));
                setSponsorCodeOptions(options);

            } else {
                throw new Error(response.message);
            }
            setLoading(false);
        } catch (error: any) {
            if (isOracleError(error.message)) {
                dispatch({
                    type: "OPEN_ALERT_MODAL",
                    payload: {
                        title: "Oracle Error",
                        message: error.message,
                        type: "ORACLE_ERROR",
                    },
                });
            } else {
                showCustomNotification({
                    title: "Error",
                    message: error.message,
                    notifyType: "ERROR",
                });
            }
            setLoading(false);
        }
    };


    useEffect(() => {
        getTxnPayType();
        getSponsorCode();
    }, []);

    return (
        <>
            <MainCard title="Download WPS Data">
                <Formik
                    initialValues={{
                        wps_data_input: "",
                        spons_code: "",
                        txn_code: "",
                        YrMth: YrMthPickerPicker,
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        wps_data_input: Yup.string().required('Please Select Type'),
                        spons_code: Yup.string().when([], {
                            is: () => type,
                            then: Yup.string().required("Passphrase is required"),
                            otherwise: Yup.string().notRequired(),
                        }),
                        txn_code: Yup.string().required('Please Transaction Code'),
                        YrMth: Yup.string().required('Please Month & Year'),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({
                          errors,
                          handleBlur,
                          handleChange,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue,
                          handleSubmit
                      }) => (
                        <Form>
                            <Grid sx={{marginTop: '30px'}}>
                                <Grid.Col xs={12} md={2}>
                                    <Select
                                        label="Type"
                                        placeholder="Type"
                                        searchable
                                        name="wps_data_input"
                                        data={[
                                            {value: 'payslip', label: 'Payslip'},
                                            {value: 'bank_account', label: 'Bulk Payment Other Card'},
                                            {value: 'paycard', label: 'Bulk Payment Paycard'},
                                        ]}
                                        error={!!(touched['wps_data_input'] && errors['wps_data_input']) && errors['wps_data_input']}

                                        onChange={(value: any) => {
                                            setFieldValue('wps_data_input', value)
                                            if (value === 'payslip') {
                                                setType(true)
                                            } else {
                                                setFieldValue('spons_code', '')
                                                setType(false)


                                            }
                                        }}
                                    />
                                </Grid.Col>

                                {type && (
                                    <Grid.Col xs={12} md={2}>
                                        <Select
                                            searchable
                                            label="Sponsor Code"
                                            placeholder="Sponsor Code"
                                            name="spons_code"
                                            data={sponsorCodeOptions}
                                            error={!!(touched['spons_code'] && errors['spons_code']) && errors['spons_code']}
                                            onChange={(value) => setFieldValue('spons_code', value)}
                                        />
                                    </Grid.Col>

                                )}


                                <Grid.Col xs={12} md={2}>
                                    <Select
                                        searchable
                                        label="Txn Code"
                                        placeholder="Txn Code"
                                        name="txn_code"
                                        data={txnCodeOptions}
                                        error={!!(touched['txn_code'] && errors['txn_code']) && errors['txn_code']}
                                        onChange={(value) => setFieldValue('txn_code', value)}
                                    />
                                </Grid.Col>


                                <Grid.Col xs={12} md={2}>

                                    <Text fz="sm" style={{fontWeight: '500', color: '#212529'}}>Year&Month</Text>

                                    <ThemeProvider theme={createTheme({})}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                views={["year", "month"]}
                                                label="Month and Year"
                                                minDate={moment(new Date(2021, 11, 24))}
                                                maxDate={moment(new Date())}
                                                // value={dayjs('2022-04-17')}
                                                // value={moment(new Date())}
                                                value={YrMthPickerPicker}
                                                onChange={(newValue: any) => {
                                                    setYrMthPickerPicker(newValue);
                                                    setFieldValue('YrMth', newValue);
                                                }}
                                                renderInput={({inputRef, inputProps, InputProps}) => {
                                                    return (
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                            <TextInput
                                                                name="YrMth"
                                                                id="YrMth"
                                                                ref={inputRef}
                                                                {...inputProps}
                                                                icon={
                                                                    <IconCalendar
                                                                        size={20}
                                                                        color={theme.colors.ffcBrand[6]}
                                                                    />
                                                                }
                                                            />
                                                            {InputProps?.endAdornment}
                                                        </Box>
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </ThemeProvider>
                                </Grid.Col>

                                <Grid.Col xs={12} md={2} sx={{marginTop: '-3px'}}>
                                    <Group>
                                        <Group mt="xl">

                                            <Button type="submit" fullWidth> Show </Button>

                                            {errors.submit && (
                                                <Box sx={{mt: 2}}>
                                                    <Text
                                                        size="sm"
                                                        sx={(theme) => ({
                                                            color: theme.other.danger,
                                                        })}
                                                    >
                                                        {errors.submit}
                                                    </Text>
                                                </Box>
                                            )}
                                        </Group>
                                    </Group>
                                </Grid.Col>
                                <Grid.Col xs={12} md={2}>

                                </Grid.Col>
                            </Grid>
                        </Form>
                    )}
                </Formik>

                {isLoading && <ReportCardLoader/>}

                <WpsList wpsListRows={wpsListRows} wpsListColumns={wpsListColumns}
                         wpsListData={wpsListData} downloadLink={downloadLink}/>
            </MainCard>

        </>
    );
};

export default Wps;

import { Paper, Table } from "@mantine/core";

export default function PayTable({ payDetails }: any) {
  const getTotalEarnings = () =>
    !!payDetails.length &&
    payDetails.reduce((acc: number, curr: any) => acc + curr.EARNING, 0);

  const getTotalDeduction = () =>
    !!payDetails.length &&
    payDetails.reduce((acc: number, curr: any) => acc + curr.DEDUCTION, 0);

  const getNetTotal = () =>
    !!payDetails.length &&
    payDetails.reduce((acc: number, curr: any) => acc + curr.ACTUALTOTAL, 0);

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <Table mb={10} striped horizontalSpacing="md" verticalSpacing="sm">
        <thead>
          <tr
            style={{
              backgroundColor: "#2e439e",
            }}
          >
            <th style={{ color: "#fff" }}>Code</th>
            <th style={{ color: "#fff" }}>Particulars</th>
            <th style={{ color: "#fff" }}>Type</th>
            <th style={{ color: "#fff", textAlign: "right" }}>Earnings</th>
            <th style={{ color: "#fff", textAlign: "right" }}>Deduction</th>
            <th style={{ color: "#fff", textAlign: "right" }}>Balance</th>
          </tr>
        </thead>
        <tbody>
          {payDetails.map((row: any) => (
            <tr
              key={row.ALLOWANCE_CODE}
              style={{ paddingTop: 5, paddingBottom: 5 }}
            >
              <td>{row.PAYH_PAY_TRANS_CODE}</td>
              <td>{row.ALLOWANCE_NAME}</td>
              <td>{row.ALLOWANCE_TYPE}</td>
              <td align="right">{row.EARNING}</td>
              <td align="right">{row.DEDUCTION}</td>
              <td align="right">{row.ACTUALTOTAL}</td>
            </tr>
          ))}
          <tr style={{ backgroundColor: "#2e439e", color: "#fff" }}>
            <td colSpan={3} align="right">
              Net Total :
            </td>
            <td align="right">{getTotalEarnings()}</td>
            <td align="right">{getTotalDeduction()}</td>
            <td align="right">{getNetTotal()}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}

import { useEffect, useMemo, useState } from "react";
import React from "react";
import dayjs from "dayjs";

import {
    Box,
    Button,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    Text, Card, Tabs, Stack,
    Title, Paper
} from "@mantine/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';
import InfoRow from "../../../components/InfoRow/InfoRow";
import { Route, Link, Routes, useParams } from 'react-router-dom';
import MainCard from "../../../components/Cards/MainCard";
import { api } from "../../../api";

const SupplierApprovedView = () => {
    const params = useParams();
    const { id } = useParams()
    const [loading, setLoading] = useState(false);
    const [approvedListData, setApprovedListData] = useState<any>('');
    const [approvedDocs, setApprovedDocs] = useState<any>([]);
    const [approvedContacts, setApprovedContacts] = useState<any>([]);

    const getApprovedList = async (id: any) => {
        try {
            setLoading(true)
            const payload = {
                supp_code: id
            };
            const response = await api.post("supplier/actualList", undefined, payload);
            if (response.status == true) {
                setLoading(false)
                setApprovedListData(response.data.Rc[0]);
                setApprovedContacts(response.data.Rc_Dtl);
            }
        } catch (error: any) {
            // console.log(error);
        }
    };

    const getApprovedDocs = async (id: any) => {
        try {
            setLoading(true)
            const payload = {
                supp_req_id: id
            };
            const response = await api.post("supplier/ApprovedDocs", undefined, payload);
            if (response.status == true) {
                setLoading(false)
                setApprovedDocs(response.data);

            }
        } catch (error: any) {
            // console.log(error);
        }
    };

    const handleDownload = (path: any) => {
        const fileName: any = path.split("/").pop();
        fetch(path).then((resp) => {
            resp.blob().then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            });
        });
        return;
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [

            // {
            //     accessorKey: 'SUPPRDOC_ID',
            //     header: ' Id',
            //     enableHiding: true,
            // },
            {
                accessorKey: 'SUPPD_DOC_NO',
                header: ' DOC NO',
            },
            // {
            //     accessorKey: 'SUPPD_DOC_TYPE',
            //     header: 'Doc Type',
            // },

            {
                accessorKey: 'File',
                header: 'File',
                Cell: ({ cell, table, row }) => {
                    var SUPPD_DOC_DESC: any = row.original['SUPPD_DOC_DESC'] ? row.original['SUPPD_DOC_DESC'] : ''
                    return (<div>
                        {SUPPD_DOC_DESC && (
                            <Button onClick={(value: any) => {
                                handleDownload(SUPPD_DOC_DESC)
                            }}>Download</Button>)}

                    </div>)
                },
            },
        ],
        [],
    );


    const columns1 = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'SUPPCONT_NAME',
                header: 'Name',

            },
            // {
            //     accessorKey: 'SUPPRD_ID',
            //     header: ' Id',
            //     enableHiding: true,
            // },

            {
                accessorKey: 'Status',
                header: 'Phone',
                Cell: ({ cell, table, row }) => {
                    var Tel: any = row.original['SUPPCONT_PHONE'] ? row.original['SUPPCONT_PHONE'] : ''
                    // var Mobile: any = row.original['SUPPRD_MOB_NO'] ? row.original['SUPPRD_MOB_NO'] : ''
                    var Fax: any = row.original['SUPPCONT_FAX'] ? row.original['SUPPCONT_FAX'] : ''
                    return (<div>
                        {Tel && (
                            <Text fw={500}> T: {Tel}</Text>)}
                        {/* {Mobile && (
                            <Text fw={500}>m: {Mobile}</Text>)} */}
                        {Fax && (
                            <Text fw={500}>F: {Fax}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'SUPPCONT_DESIG',
                header: 'Role',
            },

            {
                accessorKey: 'SUPPCONT_EMAIL',
                header: 'Email',
            },
        ],
        [],
    );

    useEffect(() => {
        window.scrollTo(0, 0)
        //   setLoading(true)
        getApprovedList(id)
        // getApprovedDocs(id)
    }, []);

    return (
        <>
            <MainCard title="Supplier View">
                <Card shadow="sm" radius="md" withBorder mt={'30px'}>
                    <Tabs defaultValue="gallery">
                        <Tabs.List>
                            <Tabs.Tab value="gallery">Details</Tabs.Tab>
                            <Tabs.Tab value="contacts" >Docs & Contacts</Tabs.Tab>
                            <Tabs.Tab value="messages" >BDA</Tabs.Tab>
                            {/*<Tabs.Tab value="settings">Settings</Tabs.Tab>*/}
                        </Tabs.List>

                        <Tabs.Panel value="gallery" pt="xs">


                            <Grid>
                                <Grid.Col xs={12} md={10}>

                                </Grid.Col>

                                <Grid.Col xs={12} md={2}>
                                    <Button type="submit" sx={{ textAlign: 'right' }}>
                                        {approvedListData.SUPPB_STATUS ? approvedListData.SUPPB_STATUS : 'Approved'}
                                    </Button>
                                </Grid.Col>
                            </Grid>
                            <Grid sx={{ marginTop: '10px' }}>


                                <Grid.Col xs={12} md={4}>
                                    <Paper px="xl" py={5} shadow="sm" radius="md" sx={{ height: "100%" }}>
                                        <Stack>
                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                                                    size="lg"
                                                    my="sm"
                                                >
                                                    Business Information
                                                </Title>
                                                <InfoRow title="Code"
                                                    values={[approvedListData.SUPP_CODE ? approvedListData.SUPP_CODE : "-"]} />
                                                <InfoRow title="Name"
                                                    values={[approvedListData.SUPP_NAME ? approvedListData.SUPP_NAME : "-"]} />
                                                <InfoRow title="Short Name"
                                                    values={[approvedListData.SUPP_SHORT_NAME ? approvedListData.SUPP_SHORT_NAME : "-"]} />
                                                <InfoRow title="Email"
                                                    values={[approvedListData.SUPP_EMAIL ? approvedListData.SUPP_EMAIL : "-"]} />
                                                <InfoRow title="Telephone"
                                                    values={[approvedListData.SUPP_TEL1 ? approvedListData.SUPP_TEL1 : "-"]} />

                                                {/*<InfoRow title="Websiite"*/}
                                                {/*values={[approvedListData.SUPP_WEBSITE ? approvedListData.SUPP_WEBSITE : "-"]}/>*/}
                                                {/*<InfoRow title="No of Employee"*/}
                                                {/*values={[approvedListData.SUPP_NO_EMPLOYEE ? approvedListData.SUPP_NO_EMPLOYEE : "-"]}/>*/}
                                                {/*<InfoRow title="Sponsor"*/}
                                                {/*values={[approvedListData.SUPP_SPONSOR ? approvedListData.SUPP_SPONSOR : "-"]}/>*/}
                                                {/*<InfoRow title="Turnover Amount"*/}
                                                {/*values={[approvedListData.SUPP_TURNOVER_AMT ? approvedListData.SUPP_TURNOVER_AMT : "-"]}/>*/}
                                                {/*<InfoRow title="Reg Date"*/}
                                                {/*values={[approvedListData.SUPP_REG_DT ? dayjs(approvedListData.SUPP_REG_DT).format("DD-MMM-YY") : "-"]}/>*/}
                                                {/*<InfoRow title="Other Retailers"*/}
                                                {/*values={[approvedListData.SUPP_OTH_RETAILERS ? approvedListData.SUPP_OTH_RETAILERS : "-"]}/>*/}
                                                {/*<InfoRow title="Supp Code"*/}
                                                {/*values={[approvedListData.SUPP_SUPP_CODE ? approvedListData.SUPP_SUPP_CODE : "-"]}/>*/}


                                            </Box>

                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                                                    size="lg"
                                                    my="sm"
                                                >
                                                    Address
                                                </Title>
                                                <InfoRow
                                                    title="Address"
                                                    values={[approvedListData.SUPP_ADDRESS1 ? approvedListData.SUPP_ADDRESS1 : "", approvedListData.SUPP_ADDRESS2 ? approvedListData.SUPP_ADDRESS2 : "", approvedListData.SUPP_ADDR3 ? approvedListData.SUPP_ADDR3 : "-"]}
                                                />
                                                <InfoRow title="City" values={[approvedListData.SUPP_CITY ? approvedListData.SUPP_CITY : "-"]} />
                                                <InfoRow title="Area" values={[approvedListData.SUPP_AREA_CODE ? approvedListData.SUPP_AREA_CODE : "-"]} />
                                                <InfoRow title="Country" values={[approvedListData.SUPP_COUNTRY ? approvedListData.SUPP_COUNTRY : "-"]} />

                                            </Box>


                                        </Stack>
                                    </Paper>
                                </Grid.Col>

                                <Grid.Col xs={12} md={4}>
                                    <Paper px="xl" py={5} shadow="sm" radius="md" sx={{ height: "100%" }}>
                                        <Stack>
                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })} size="lg"
                                                    my="sm"
                                                >
                                                    Bank Details
                                                </Title>
                                                <InfoRow title="Ac Name"
                                                    values={[approvedListData.SUPPB_AC_NAME ? approvedListData.SUPPB_AC_NAME : "-"]} />
                                                <InfoRow title="Account No"
                                                    values={[approvedListData.SUPPB_AC_NO ? approvedListData.SUPPB_AC_NO : "-"]} />
                                                {/*<InfoRow title="Branch"*/}
                                                {/*values={[approvedListData.SUPP_BANK_BRANCH ? approvedListData.SUPP_BANK_BRANCH : "-"]}/>*/}
                                                <InfoRow title="Code"
                                                    values={[approvedListData.SUPPB_BANK_CODE ? approvedListData.SUPPB_BANK_CODE : "-"]} />
                                                {/*<InfoRow title="Iban No"*/}
                                                {/*values={[approvedListData.SUPP_IBAN_NO ? approvedListData.SUPP_IBAN_NO : "-"]}/>*/}
                                                <InfoRow title="Pay Type"
                                                    values={[approvedListData.SUPPB_PAY_TYPE ? approvedListData.SUPPB_PAY_TYPE : "-"]} />
                                                <InfoRow title="Curr Code"
                                                    values={[approvedListData.SUPPB_CURR_CODE ? approvedListData.SUPPB_CURR_CODE : "-"]} />
                                                <InfoRow title="Swift Code"
                                                    values={[approvedListData.SUPPB_SWIFT_CODE ? approvedListData.SUPPB_SWIFT_CODE : "-"]} />
                                            </Box>
                                           
                                        </Stack>
                                    </Paper>

                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                <Paper px="xl" py={5} shadow="sm" radius="md" sx={{ height: "100%" }}>
                                        <Stack>
                                          
                                            <Box>
                                                <Title
                                                    sx={(theme) => ({ color: theme.colors.gray[7] })}
                                                    size="lg"
                                                    my="sm"
                                                >
                                                    Basic Terms of the Agreement
                                                </Title>
                                                <InfoRow title="Cr No"
                                                    values={[approvedListData.SUPP_CR_NO ? approvedListData.SUPP_CR_NO : "-"]} />
                                                {/*<InfoRow title="Created User" values={[approvedListData.SUPP_CR_USER ?approvedListData.SUPP_CR_USER: "-"]} />*/}
                                                {/*<InfoRow title="Created Date" values={[approvedListData.SUPP_CR_DT ? dayjs( approvedListData.SUPP_CR_DT).format("DD-MMM-YY") : "-"]} />*/}
                                                {/*<InfoRow title="Edit User" values={[approvedListData.SUPP_EDT_USER ?approvedListData.SUPP_EDT_USER: "-"]} />*/}
                                                {/*<InfoRow title="Edit Date" values={[approvedListData.SUPP_EDT_DT ? dayjs( approvedListData.SUPP_EDT_DT).format("DD-MMM-YY") : "-"]} />*/}
                                                {/*<InfoRow title="Ret Price"*/}
                                                {/*values={[approvedListData.SUPP_RET_PRICE ? approvedListData.SUPP_RET_PRICE : "-"]}/>*/}
                                                {/*<InfoRow title="Invoice Disc"*/}
                                                {/*values={[approvedListData.SUPP_INV_DISC ? approvedListData.SUPP_INV_DISC : "-"]}/>*/}
                                                {/*<InfoRow title="Rebate"*/}
                                                {/*values={[approvedListData.SUPP_REBATE ? approvedListData.SUPP_REBATE : "-"]}/>*/}
                                                {/*<InfoRow title="Loyalty Amt"*/}
                                                {/*values={[approvedListData.SUPP_LOYLT_AMT ? approvedListData.SUPP_LOYLT_AMT : "-"]}/>*/}
                                                <InfoRow title="Cr Days"
                                                    values={[approvedListData.SUPP_CR_DAYS ? approvedListData.SUPP_CR_DAYS : "-"]} />
                                                {/*<InfoRow title="Cr Amt"*/}
                                                {/*values={[approvedListData.SUPP_CR_AMT ? approvedListData.SUPP_CR_AMT : "-"]}/>*/}
                                                {/*<InfoRow title="Aged Days"*/}
                                                {/*values={[approvedListData.SUPP_AGED_DAYS ? approvedListData.SUPP_AGED_DAYS : "-"]}/>*/}
                                                {/*<InfoRow title="Status"*/}
                                                {/*values={[approvedListData.SUPP_APPROVAL_STATUS ? approvedListData.SUPP_APPROVAL_STATUS : "-"]}/>*/}
                                                {/*<InfoRow title="Order Amt"*/}
                                                {/*values={[approvedListData.SUPP_ORD_AMT ? approvedListData.SUPP_ORD_AMT : "-"]}/>*/}

                                            </Box>
                                        </Stack>
                                    </Paper>
                                </Grid.Col>


                            </Grid>


                        </Tabs.Panel>
                        <Tabs.Panel value="contacts" pt="xs">



                            <Grid sx={{ marginTop: '10px' }}>



                                <Grid.Col xs={12} md={6}>



                                    <Box>
                                        <Title
                                            sx={(theme) => ({ color: theme.colors.gray[7] })}
                                            size="lg"
                                            my="sm"
                                        >
                                            Contacts
                                        </Title>

                                        {approvedContacts && (
                                            <ThemeProvider theme={createTheme({})}>
                                                <MaterialReactTable
                                                    muiTableBodyProps={{
                                                        sx: (theme) => ({
                                                            '& tr:nth-of-type(odd)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                            '& tr:nth-of-type(even)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                        }),
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: (theme) => ({
                                                            backgroundColor: '#fff', color: 'green', fontSize: '14px'
                                                        }),
                                                    }}
                                                    columns={columns1}
                                                    data={approvedContacts}
                                                    enableColumnActions={false}
                                                    enableColumnFilters={false}
                                                    enableSorting={false}
                                                    enableTopToolbar={false}
                                                    enableBottomToolbar={false}
                                                    muiTableBodyRowProps={{ hover: false }}
                                                    initialState={{
                                                        pagination: { pageIndex: 0, pageSize: 10 },
                                                        columnVisibility: { redemption_id: false }
                                                    }}
                                                />
                                            </ThemeProvider>
                                        )}
                                    </Box>

                                    <Box mt={'50px'}>
                                        <Title
                                            sx={(theme) => ({ color: theme.colors.gray[7] })}
                                            size="lg" my="sm"
                                        >
                                            Documents
                                        </Title>
                                        {approvedDocs && (
                                            <ThemeProvider theme={createTheme({})}>
                                                <MaterialReactTable
                                                    muiTableBodyProps={{
                                                        sx: (theme) => ({
                                                            '& tr:nth-of-type(odd)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                            '& tr:nth-of-type(even)': {
                                                                backgroundColor: '#fff',
                                                            },
                                                        }),
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: (theme) => ({ backgroundColor: '#fff', color: 'green', fontSize: '14px' }),
                                                    }}
                                                    columns={columns}
                                                    data={approvedDocs}
                                                    enableColumnActions={false}
                                                    enableColumnFilters={false}
                                                    enableSorting={false}
                                                    enableBottomToolbar={false}
                                                    enableTopToolbar={false}
                                                    muiTableBodyRowProps={{ hover: false }}
                                                    initialState={{ columnVisibility: { redemption_id: false } }}
                                                />
                                            </ThemeProvider>
                                        )}


                                    </Box>


                                </Grid.Col>

                            </Grid>

                        </Tabs.Panel>

                    </Tabs>

                </Card>
            </MainCard>
        </>
    );
};

export default SupplierApprovedView;

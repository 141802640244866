import { useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Paper,
  Badge,
  LoadingOverlay,
  Box,
} from "@mantine/core";
import dayjs from "dayjs";

import {
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconArrowsSort,
} from "@tabler/icons";
import { TimesheetResultProps } from "./types";

type TThProps = {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
};

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
}));

function Th({ children, reversed, sorted, onSort }: TThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconArrowNarrowUp
      : IconArrowNarrowDown
    : IconArrowsSort;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group spacing="xs">
          <Text weight={500} size="md">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

type TData = any;

function filterData(data: TData[], search: string) {
  if (search === "") return data;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.keys(data[0]).some((key) =>
      (item[key as keyof TData] + "").toLowerCase().includes(query)
    )
  );
}

function sortData(
  data: TData[],
  payload: { sortBy: keyof TData | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[sortBy] + "").localeCompare(a[sortBy] + "", "en", {
          numeric: true,
        });
      }

      return (a[sortBy] + "").localeCompare(b[sortBy] + "", "en", {
        numeric: true,
      });
    }),
    payload.search
  );
}

type TProps = {
  data: TimesheetResultProps[] | undefined;
};
const TimesheetTable = ({ data }: TProps) => {
  const [selectedDivision, setSelectedDivision] = useState<string | null>(null);
  const [pODivOptions, setPODivOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // const [search, setSearch] = useState("");
  const [search] = useState("");
  // const [sortedData, setSortedData] = useState<TData[]>(data);
  // const [sortedData, setSortedData] = useState<TData[]>([]);
  const [sortBy, setSortBy] = useState<keyof TData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  // const { isLoading: isLoadingPODivision, data: purchaseOrderDivision } =
  //   useGetAllDivisions();

  // const { isLoading: isLoadingProductsData, data: productsData } = useQuery<
  //   TDatasData | null,
  //   Error
  // >(
  //   ["products", "productsData", "active", selectedDivision],
  //   () => geTDatasData({ flag: "ACT", selectedDivision }),
  //   {
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   }
  // );

  // useEffect(() => {
  //   if (purchaseOrderDivision && !selectedDivision) {
  //     const divisionOptions = purchaseOrderDivision.map((division) => ({
  //       label: division.DIVN_NAME,
  //       value: `${division.DIVN_CODE}_${division.DIVN_COMP_CODE}`,
  //     }));
  //     setPODivOptions(divisionOptions);
  //     setSelectedDivision(divisionOptions[0].value);
  //   }
  // }, [purchaseOrderDivision, selectedDivision]);

  // useEffect(() => {
  //   if (productsData) {
  //     setSortedData(
  //       sortData(productsData.products, {
  //         sortBy,
  //         reversed: reverseSortDirection,
  //         search,
  //       })
  //     );
  //   }
  // }, [productsData, reverseSortDirection, search, sortBy]);

  const setSorting = (dummy: any) => {}; // this is a dummy function
  // const setSorting = (field: keyof TData) => {
  //   const reversed = field === sortBy ? !reverseSortDirection : false;
  //   setReverseSortDirection(reversed);
  //   setSortBy(field);
  //   setSortedData(
  //     sortData(productsData?.products ?? [], {
  //       sortBy: field,
  //       reversed,
  //       search,
  //     })
  //   );
  // };

  // const totalPages = Math.ceil(
  //   (productsData?.products.length ?? 0) / rowsPerPage
  // );

  const rows =
    data &&
    data.map((row) => (
      <tr key={`${row.ETSH_ID}`}>
        {/*<td>*/}
          {/*<Text size="md" sx={(theme) => ({ color: theme.colors.gray[7] })}>*/}
            {/*<Text size="md">{row.ETSH_ETSK_ID}</Text>*/}
          {/*</Text>*/}
        {/*</td>*/}
        <td>
          <Text size="md">{row.ETSH_DESCRIPTION}</Text>
        </td>
        <td>
          <Text size="md">
            {row.ETSH_TIMESHEET_DT}
          </Text>
        </td>
        <td>
          <Text size="md">{row.ETSD_TIME_START}</Text>
        </td>
        <td>
          <Text size="md">{row.ETSD_TIME_END}</Text>
        </td>
        <td>
          <Badge
            size="md"
            radius="sm"
            variant="filled"
            color={
              row.TSUT_APPR_STATUS === "Rejected"
                ? "red"
                : row.TSUT_APPR_STATUS === "Pending"
                ? "orange"
                : "green"
            }
            sx={{ width: "7.5rem" }}
          >
            {row.TSUT_APPR_STATUS}
          </Badge>
        </td>
      </tr>
    ));

  return (
    <>
      <Paper
        withBorder
        radius="md"
        my="sm"
        sx={{ minWidth: 700, position: "relative" }}
      >
        <LoadingOverlay visible={false} />
        <ScrollArea>
          <Paper sx={{ height: "36rem", width: "100%" }}>
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              striped
              sx={{ tableLayout: "auto" }}
            >
              <thead>
                <tr>

                  {/*<Th*/}
                    {/*sorted={sortBy === ""}*/}
                    {/*reversed={reverseSortDirection}*/}
                    {/*onSort={() => setSorting("")}*/}
                  {/*>*/}
                    {/*Task*/}
                  {/*</Th>*/}
                  <Th
                    sorted={sortBy === ""}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("")}
                  >
                    Comment
                  </Th>
                  <Th
                    sorted={sortBy === ""}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("")}
                  >
                    Start Date
                  </Th>
                  <Th
                    sorted={sortBy === ""}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("")}
                  >
                    Start Time
                  </Th>
                  <Th
                    sorted={sortBy === ""}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("")}
                  >
                    End Time
                  </Th>
                  <Th
                    sorted={sortBy === ""}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("")}
                  >
                    Status
                  </Th>
                </tr>
              </thead>
              <tbody>
                {rows && rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <Text weight={500} align="center" color="red">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Paper>
        </ScrollArea>
      </Paper>
      {/* <Pagination
        disabled={!productsData?.products.length}
        position="right"
        page={page + 1}
        onChange={(page: number) => setPage(page - 1)}
        withEdges
        total={totalPages}
      /> */}
    </>
  );
};

export default TimesheetTable;

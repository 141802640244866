import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";

type PrivateRouteProps = RouteProps & {
  fallbackTo: string;
};

export const PrivateRoute = ({ fallbackTo }: PrivateRouteProps) => {
  const url = useLocation().search;
  const { isAuth } = useSelector((state: any) => state.auth);
  if (!isAuth) {
    return <Navigate to={url ? `${fallbackTo + url}` : fallbackTo} />;
  }
  return <Outlet />;
};

import AppLayout from "../layout";
import ControlConfig from "../views/Configurations/ControlConfig";
import UiConfig from "../views/Configurations/UiConfig";
import Dashboard from "../views/Dashboard";
import UploadFinancialEntry from "../views/finance/financialEntry";
import DocumentUpload from "../views/HR/DocumentUpload";
import Payslip from "../views/HR/Payslip";
import ReportGenerationTabs from "../views/report";
import DownloadFromMail from "../views/report/DownloadFromMail";
import Timesheet from "../views/Timesheet";
import UserProfile from "../views/UserProfile";
import WPS from "../views/HR/Wps";
import UploadPurchaseEntry from "../views/purchase/purchaseEntry";
import PendingSupplier from "../views/Buying/Suppliers";
import SupplierView from "../views/Buying/Suppliers/view";
import SupplierApprovedView from "../views/Buying/Suppliers/approvedView";

const MainRoutes = {
    path: "/",
    element: <AppLayout/>,
    children: [
        {
            path: "/",
            element: <Dashboard/>,
        },
        {
            path: "/timesheet",
            element: <Timesheet/>,
        },
        {
            path: "/report",
            element: <ReportGenerationTabs/>,
        },
        {
            path: "/downloads",
            element: <DownloadFromMail/>,
        },
        {
            path: "/config/controls",
            element: <ControlConfig/>,
        },
        {
            path: "/config/ui",
            element: <UiConfig/>,
        },
        {
            path: "/profile",
            element: <UserProfile/>,
        },
        {
            path: "/payslip",
            element: <Payslip/>,
        },
        {
            path: "/upload-documents",
            element: <DocumentUpload/>,
        },
        {
            path: "/finance",
            element: <UploadFinancialEntry/>,
        },
        {
            path: "/wps",
            element: <WPS/>,
        },
        {
            path: "/purchase",
            element: <UploadPurchaseEntry/>,
        },
        {
            path: "/psupplier",
            element: <PendingSupplier/>,
        },
        {
            path: "/asupplier",
            element: <UploadPurchaseEntry/>,
        },
        {
            path: "/supplier/view/:id",
            element: <SupplierView />,
        },
        {
            path: "/supplier/approved-view/:id",
            element: <SupplierApprovedView />,
        },
    ],
};

export default MainRoutes;

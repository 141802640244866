import { Alert, Modal, Text } from "@mantine/core";
import { useDispatch } from "react-redux";

const AlertModal = ({ alertArray }: any) => {
  const dispatch = useDispatch();

  const textColor =
    !!alertArray.length &&
    (alertArray[0].alertType === "ERROR" ||
      alertArray[0].alertType === "ORACLE_ERROR")
      ? "red"
      : "";
  //  alertId, alertTitle, alertMessage, alertType
  return (
    <Modal
      centered
      size="40%"
      opened={!!alertArray.length}
      closeOnClickOutside={false}
      closeOnEscape={false}
      onClose={
        () =>
          dispatch({
            type: "CLEAR_ALERT_MODAL",
          })
        // dispatch({
        //   type: "CLOSE_ALERT_MODAL",
        //   payload: {
        //     id,
        //   },
        // })
      }
      withCloseButton={false}
      zIndex={800}
      padding={0}
    >
      {!!alertArray.length && (
        <Alert
          // icon={<IconAlertCircle size={16} />}
          title={alertArray[0].alertTitle}
          color={
            alertArray[0].alertType === "WARNING"
              ? "yellow"
              : alertArray[0].alertType === "ERROR" || "ORACLE_ERROR"
              ? "red"
              : "green"
          }
          withCloseButton
          onClose={() =>
            dispatch({
              type: "CLEAR_ALERT_MODAL",
            })
          }
        >
          <div
            style={{
              border: "1px solid #464646",
              backgroundColor: "#fefefe",
              padding: "10px",
              marginBottom: "5px",
            }}
          >
            {alertArray.map(({ alertId, alertMessage }: any) => (
              <Text key={alertId} sx={{ color: textColor }} mb="sm">
                {alertMessage}
              </Text>
            ))}
          </div>
          {alertArray[0].alertType === "ORACLE_ERROR" && (
            <>
              <Text>
                This could be due to missing procedure on database or incorrect
                parameters provided.
              </Text>
              <Text>Please contact the administrator.</Text>
            </>
          )}
        </Alert>
      )}
    </Modal>
  );
};

export default AlertModal;

import {
    Box,
    Button,
    Grid,
    Group,
    Loader,
    LoadingOverlay, Card,
    Text,
    Title, Paper
} from "@mantine/core";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useEffect, useState, useMemo} from "react";

import MainCard from "../../../components/Cards/MainCard";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';

type PurropsTypes = {
    purHead: any;
    purDetails: any;
    downloadLink: any;
};

function PurchaseDetails({purHead, purDetails, downloadLink}: PurropsTypes) {

    const handleDownload = () => {
        const fileName: any = downloadLink.split("/").pop();
        fetch(downloadLink).then((resp) => {
            resp.blob().then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            });
        });
        return;
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Item_Name',
                header: 'Item ',
                Cell: ({cell, table, row}) => {
                    var Item_Name: any = row.original['Product Description'] ? row.original['Product Description'] : ''
                    var Item_Code: any = row.original['Item Code'] ? row.original['Item Code'] : '-'
                    return (<div>
                        {Item_Name && (<Text fw={500}>{Item_Name}</Text>)}
                        {Item_Code && (<Text fw={500}>{Item_Code}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'UOM',
                header: 'Codes',
                Cell: ({cell, table, row}) => {
                    var Barcode: any = row.original.Barcode ? row.original.Barcode : ''

                    var Uom: any = row.original.Uom ? row.original.Uom : '-'
                    return (<div>
                        {Barcode && (
                            <Text fw={500}>{Barcode}</Text>)}

                        {Uom && (
                            <Text fw={500}>{Uom}</Text>)}

                    </div>)
                },
            },
            {
                accessorKey: 'POI_QTY',
                header: 'Stock',
                Cell: ({cell, table, row}) => {
                    var Qty: any = row.original.Qty ? row.original.Qty : '0'
                    var Loose: any = row.original.Loose ? row.original.Loose : '0'
                    return (<div>
                        {Qty && (
                            <Text fw={500}>Qty:{Qty}</Text>)}
                        {Loose && (
                            <Text fw={500}>Loose:{Loose}</Text>)}

                    </div>)
                },
            },
            {
                accessorKey: 'FOC_QTY',
                header: 'Foc',
                Cell: ({cell, table, row}) => {
                    var FOC_QTY: any = row.original['FOC Qty'] ? row.original['FOC Qty'] : '0'
                    var FOC_LOOSE: any = row.original['FOC Loose'] ? row.original['FOC Loose'] : '0'
                    return (<div>
                        {FOC_QTY && (
                            <Text fw={500}>Qty:{FOC_QTY ? FOC_QTY : '-'}</Text>)}
                        {FOC_LOOSE && (
                            <Text fw={500}>Loose:{FOC_LOOSE ? FOC_LOOSE : '-'}</Text>)}

                    </div>)
                },
            },

            {
                accessorKey: 'Rate',
                header: 'Rate',
                Cell: ({cell, table, row}) => {
                    var Rate: any = row.original.Rate ? row.original.Rate : '0'
                    var Amount: any = row.original.Amount ? row.original.Amount : '0'
                    return (<div>
                        {Rate && (
                            <Text fw={500}>Rate:{Rate ? Rate : '-'}</Text>)}
                        {Amount && (
                            <Text fw={500}>Amount:{Amount}</Text>)}

                    </div>)
                },
            },

            {
                accessorKey: 'Discount',
                header: 'Discount',
                Cell: ({cell, table, row}) => {
                    var POI_DISC_AMT: any = row.original['Disc. Amount']? row.original['Disc. Amount']: '0'
                    var POI_DISC_PERC: any = row.original['Disc(%)'] ? row.original['Disc(%)'] : '0'
                    return (<div>

                        {POI_DISC_AMT && (
                            <Text fw={500}>Disc. Amount:{POI_DISC_AMT}</Text>)}
                        {POI_DISC_PERC && (
                            <Text fw={500}>Disc(%):{POI_DISC_PERC}</Text>)}

                    </div>)
                },
            },
        ],
        [],
    );

    return (
        <Card shadow="sm" radius="md" withBorder>


            <Card.Section withBorder inheritPadding py="lg" mb="sm">
                <Group position="apart">
                    <Text weight={700}>Foreign Purchase Order Details</Text>
                    <Button onClick={handleDownload}>
                        Download Pdf
                    </Button>
                </Group>
            </Card.Section>
            {purHead ? (
                <div>
                    <Paper p="md" shadow="xs" radius="xs" sx={{backgroundColor: '#f3f3f3'}}>
                        {/*<Paper shadow="xs" p="md">*/}
                        {/*<Title order={3} underline>Purchase Entry Details</Title>*/}


                        <Grid sx={{marginTop: '10px'}}>


                            <Grid.Col xs={12} md={2}>
                                <Text fw={700} sx={{lineHeight: '2.5rem'}}> Owner </Text>
                                <Text
                                    sx={{lineHeight: '2.5rem'}}>     {purHead['Owner'] ? purHead['Owner'] : '-'}</Text>
                            </Grid.Col>


                            <Grid.Col xs={12} md={2}>
                                <Text fw={700} sx={{lineHeight: '2.5rem'}}> Created On </Text>
                                <Text> {purHead['Created On'] ? purHead['Created On'] : '-'}</Text>
                            </Grid.Col>


                            <Grid.Col xs={12} md={2}>
                                <Text fw={700} sx={{lineHeight: '2.5rem'}}>Sub Total </Text>
                                <Text>     {purHead['Sub Total'] ? purHead['Sub Total'] : '-'}</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={2}>

                                <Text fw={700} sx={{lineHeight: '2.5rem'}}> Net Amount </Text>
                                <Text>     {purHead['Nett Amount'] ? purHead['Nett Amount'] : '-'}</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={2} sx={{marginTop: '-3px'}}>
                                <Text fw={700} sx={{lineHeight: '2.5rem'}}> Local Net Amount </Text>
                                <Text>     {purHead['Net Amount(Local)'] ? purHead['Net Amount(Local)'] : '-'}</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={2}>
                                <Text fw={700} sx={{lineHeight: '2.5rem'}}> Total Disc Amt </Text>
                                <Text sx={{
                                    lineHeight: '2.5rem',      fontWeight: 400
                                }}>     {purHead['Disc. Amount'] ? purHead['Disc. Amount'] : '-'}</Text>
                            </Grid.Col>
                        </Grid>


                    </Paper>
                </div>

            ) : null}
            {purDetails ? (
                <ThemeProvider theme={createTheme({})}>
                    <Paper mt='45px' sx={{width: "100%"}}>
                        <MaterialReactTable
                            muiTableBodyProps={{
                                sx: (theme) => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: '#fff',
                                    },
                                    '& tr:nth-of-type(even)': {
                                        backgroundColor: '#fff',
                                    },
                                }),
                            }}
                            // enableColumnActions={false}
                            // enableColumnFilters={false}
                            // enableTopToolbar={false}
                            // enableColumnDragging ={false}
                            muiBottomToolbarProps={{
                                sx: {
                                    height: '100px',
                                    backgroundColor: '#fff',
                                    fontWeight: '500',
                                    fontSize: '15px',
                                    color: 'rgb(34, 51, 84)'
                                }
                            }}
                            // muiTopToolbarProps={{
                            //     sx: {
                            //         height: '100px',
                            //         backgroundColor: '#fff',
                            //         fontWeight: '500',
                            //         fontSize: '15px',
                            //         color: 'rgb(34, 51, 84)'
                            //     }
                            // }}
                            // enableColumnFilterModes={false}
                            // enablePinning
                            // enableRowNumbers
                            // // enableRowNumbers
                            // rowNumberMode="original" //default
                            // editingMode="modal" //default
                            // enableColumnOrdering
                            // enableEditing
                            // enableGlobalFilter={false}

                            muiTableHeadCellProps={{
                                sx: (theme) => ({
                                    backgroundColor: '#2e439e', color: '#fff', fontSize: '14px'
                                }),
                            }}
                            columns={columns}
                            data={purDetails}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            //  enablePagination={false}
                            enableSorting={false}
                            //    enableBottomToolbar={false}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{hover: false}}
                            initialState={{
                                pagination: {pageIndex: 0, pageSize: 10},
                                columnVisibility: {redemption_id: false}
                            }}
                        />
                    </Paper>
                </ThemeProvider>
            ) : null}
        </Card>
    );
}

export default PurchaseDetails;

export const generateRandomString = (length: number = 15): string => {
  const alphabetDictionary =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  return new Array(length)
    .fill(0)
    .map(() =>
      alphabetDictionary.charAt(
        Math.floor(Math.random() * alphabetDictionary.length)
      )
    )
    .join("");
};

export const isOracleError = (err: string): boolean =>
  err.substring(0, 3) === "ORA";

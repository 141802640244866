import { Loader, LoadingOverlay, Stack, Text } from "@mantine/core";

const WizardLoader = ({
  isLoading,
  isParamsLoading,
  isProcParamsLoading,
}: {
  isLoading: boolean;
  isParamsLoading: boolean;
  isProcParamsLoading: boolean;
}) => {
  const DotLineLoader = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="160px"
      height="20px"
      viewBox="0 0 128 16"
    >
      <path
        fill="#abaadf"
        d="M6.4,4.8A3.2,3.2,0,1,1,3.2,8,3.2,3.2,0,0,1,6.4,4.8Zm12.8,0A3.2,3.2,0,1,1,16,8,3.2,3.2,0,0,1,19.2,4.8ZM32,4.8A3.2,3.2,0,1,1,28.8,8,3.2,3.2,0,0,1,32,4.8Zm12.8,0A3.2,3.2,0,1,1,41.6,8,3.2,3.2,0,0,1,44.8,4.8Zm12.8,0A3.2,3.2,0,1,1,54.4,8,3.2,3.2,0,0,1,57.6,4.8Zm12.8,0A3.2,3.2,0,1,1,67.2,8,3.2,3.2,0,0,1,70.4,4.8Zm12.8,0A3.2,3.2,0,1,1,80,8,3.2,3.2,0,0,1,83.2,4.8ZM96,4.8A3.2,3.2,0,1,1,92.8,8,3.2,3.2,0,0,1,96,4.8Zm12.8,0A3.2,3.2,0,1,1,105.6,8,3.2,3.2,0,0,1,108.8,4.8Zm12.8,0A3.2,3.2,0,1,1,118.4,8,3.2,3.2,0,0,1,121.6,4.8Z"
      />
      <g>
        <path
          fill="#3734b2"
          d="M-42.7,3.84A4.16,4.16,0,0,1-38.54,8a4.16,4.16,0,0,1-4.16,4.16A4.16,4.16,0,0,1-46.86,8,4.16,4.16,0,0,1-42.7,3.84Zm12.8-.64A4.8,4.8,0,0,1-25.1,8a4.8,4.8,0,0,1-4.8,4.8A4.8,4.8,0,0,1-34.7,8,4.8,4.8,0,0,1-29.9,3.2Zm12.8-.64A5.44,5.44,0,0,1-11.66,8a5.44,5.44,0,0,1-5.44,5.44A5.44,5.44,0,0,1-22.54,8,5.44,5.44,0,0,1-17.1,2.56Z"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="23 0;36 0;49 0;62 0;74.5 0;87.5 0;100 0;113 0;125.5 0;138.5 0;151.5 0;164.5 0;178 0"
          calcMode="discrete"
          dur="1000ms"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );

  const customLoader = (
    <Stack align="center">
      {isParamsLoading || isProcParamsLoading ? (
        <>
          <DotLineLoader />
          <Text>
            {isParamsLoading
              ? "Fetching report Ui settings and all dependent fields."
              : "Fetching Procedure param arguments."}
          </Text>
        </>
      ) : (
        <Loader />
      )}
    </Stack>
  );

  return (
    <LoadingOverlay
      loader={customLoader}
      visible={isLoading || isParamsLoading || isProcParamsLoading}
    />
  );
};

export default WizardLoader;

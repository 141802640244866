import { MutateFunction } from "@tanstack/react-query";
import dayjs from "dayjs";
import { api } from "../../api";
import { TimesheetProps, TimesheetRequestProps } from "./types";

export const getTimesheetData = async (
  emp_code: string,
  from: Date,
  to: Date
) => {
  try {
    const result = await api.get(
      `timesheet/${emp_code}/${dayjs(from).format("DD-MMM-YY")}/${dayjs(
        to
      ).format("DD-MMM-YY")}`
    );
    return result;
  } catch (error) {
    throw error;
  }
};

export const createTimesheet: MutateFunction<
  { status: boolean; message: string },
  TimesheetProps,
  TimesheetRequestProps
> = ({ emp_code, request }) => {
  console.log("data : ", emp_code, request);
  try {
    return api.post(`timesheet/create/${emp_code}`, undefined, request);
  } catch (error) {
    throw error;
  }
};

export async function getProjectList() {
  try {
    const result = await api.get("timesheet/project/list");
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getTaskList() {
  try {
    const result = await api.get(`timesheet/tasks`);
    return result;
  } catch (error) {
    throw error;
  }
}

import {useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {Formik} from "formik";
import {api} from "../../../api";
import MainCard from "../../../components/Cards/MainCard";
import {Box, Button, Grid, Group, Stack} from "@mantine/core";
import AutocompleteCustom from "../../../components/Autocomplete";
import ReportGenerationCard from "./ReportGenerationCard";
import Controls from "./Controls";
import Loader from "../../../components/Loader";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getReportTypes} from "./queries";
import dayjs from "dayjs";
import {
    TReportColHeaders,
    TReportControls,
    TReportInitialValues,
    TReportToGenerateData,
    TReportTypes,
    TReportValidationSchema,
    TSelectedReportParams,
} from "../typings";
import {showCustomNotification} from "../../../helper/customNotification";
import {isOracleError} from "../../../helper/common";

const GenerateReport = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoadingReport, setLoadingReport] = useState<boolean>(false);
    const [reportControls, setReportControls] = useState<TReportControls[]>([]);
    const [reportColHeaders, setReportColHeaders] = useState<TReportColHeaders[]>(
        []
    );
    const [initialValues, setInitialValues] = useState<TReportInitialValues>({});
    const [reportValidationSchema, setReportValidationSchema] =
        useState<TReportValidationSchema>({});
    const [selectedReportId, setSelectedReportId] = useState<string | null>(null);

    const {authUser}: RootStateOrAny = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const {data} = useQuery<TReportToGenerateData[]>(
        ["reportGenerations-generatedData"],
        () => [],
        {}
    );

    const {isFetching: isReportTypesLoading, data: reportTypes} = useQuery<TReportTypes[],
        Error>(["reportGeneration-types"], getReportTypes, {
        // select: (data) => {
        //   console.log("select", data);
        //   return data;
        // },
        onError: (error) => {
            // dispatch({
            //   type: "OPEN_SNACK_TOAST",
            //   payload: { title: "Server Error", message: error.message },
            // });
            if (isOracleError(error.message)) {
                dispatch({
                    type: "OPEN_ALERT_MODAL",
                    payload: {
                        title: "Oracle Error",
                        message: error.message,
                        type: "ORACLE_ERROR",
                    },
                });
            } else {
                showCustomNotification({
                    title: "Error",
                    message: error.message,
                    notifyType: "ERROR",
                });
            }
        },
    });

    const handleSelectReportType = async (reportTypeId: string) => {
        if (reportTypeId) {
            try {
                setLoading(true);
                const response: TSelectedReportParams = await api.post(
                    `report/custom/params/${reportTypeId}`,
                    undefined,
                    {}
                );
                if (response.status) {
                    // set initial formik values
                    let initializedValues = {};
                    let initializedSchema = {};
                    response.data?.P_RepUI.forEach((reportCtrl: any) => {
                        // if (reportCtrl.CONTROL_CODE === "DT_PCKR") {
                        //   initializedValues = {
                        //     ...initializedValues,
                        //     [reportCtrl.PRAM_NAME]: {
                        //       value: new Date(),
                        //       label: new Date(),
                        //     },
                        //   };
                        // } else {
                        const repCtrlUpper = (reportCtrl.PRAM_NAME + "").toUpperCase();
                        initializedValues = {
                            ...initializedValues,
                            [repCtrlUpper]: {
                                value: "",
                                label: "",
                            },
                        };
                        // }

                        // console.log(reportCtrl.CONTROL_CODE)
                        // console.log(reportCtrl.PARAM_MANDATORY)
                        if (reportCtrl.PARAM_MANDATORY === "Yes") {
                            if (reportCtrl.CONTROL_CODE === "DT_PCKR") {
                                initializedSchema = {
                                    ...initializedSchema,
                                    [repCtrlUpper]: Yup.object().shape({
                                        label: Yup.date().required(
                                            `${reportCtrl.LABEL_TXT} is required`
                                        ),
                                        value: Yup.date().required(
                                            `${reportCtrl.LABEL_TXT} is required`
                                        ),
                                    }),
                                };
                            } else if (reportCtrl.CONTROL_CODE === "FL_UPLOAD") {
                             //   console.log(repCtrlUpper)
                                initializedSchema = {
                                    ...initializedSchema,
                                    [repCtrlUpper]: Yup.object().shape({
                                        label: Yup.mixed().required(`field is required`),
                                        value: Yup.mixed().required(`field is required`),
                                        //   path: Yup.mixed().required(`field is required`),
                                    }),
                                };
                            } else {
                                initializedSchema = {
                                    ...initializedSchema,
                                    [repCtrlUpper]: Yup.object().shape({
                                        label: Yup.string().required(`field is required`),
                                        value: Yup.string().required(`field is required`),
                                    }),
                                };
                            }
                        }


                    });

             //       console.log(initializedValues)
                    setReportValidationSchema(initializedSchema);
                    setInitialValues(initializedValues);
                    setReportControls(response.data.P_RepUI);
                    setReportColHeaders(response.data.P_RepOut);
                } else {
                    // dispatch({
                    //   type: "OPEN_SNACK_TOAST",
                    //   payload: { title: "Server Error", message: response.message },
                    // });
                    setReportControls([]);
                    throw new Error(response.message);
                    // console.log("response error", response.message);
                }
                setLoading(false);
            } catch (error: any) {
                console.log({error});
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: error.message },
                // });
                if (isOracleError(error.message)) {
                    dispatch({
                        type: "OPEN_ALERT_MODAL",
                        payload: {
                            title: "Oracle Error",
                            message: error.message,
                            type: "ORACLE_ERROR",
                        },
                    });
                } else {
                    showCustomNotification({
                        title: "Error",
                        message: error.message,
                        notifyType: "ERROR",
                    });
                }
                setLoading(false);
            }
        }
    };

    const handleGenerateReport = async (formValues: TReportInitialValues) => {
        try {

        //    console.log(54545)
        //    console.log(formValues)
            if (!reportTypes) throw new Error("No report types found");
            setLoadingReport(true);
            let checkedColumns = {};
           if(reportColHeaders.length && reportColHeaders)
            reportColHeaders
                .sort((a, b) => a.OUT_ORDER_NO - b.OUT_ORDER_NO)
                .forEach((column) => {
                    checkedColumns = {
                        ...checkedColumns,
                        [column.OUT_PRAM_NAME.toUpperCase()]: {
                            label: column.OUT_LABEL,
                            orderNo: column.OUT_ORDER_NO,
                            isChecked: true,
                        },
                    };
                });

            const selectedReport = reportTypes.find(
                (report) => Number(report.REP_ID) === Number(selectedReportId)
            );
            if (!selectedReport) throw new Error("No report selected");
            const params = JSON.parse(selectedReport.REP_PARAM);
            // const nonUiValues: any = reportControls.reduce(
            //   (acc, ctrl) =>
            //     ctrl.BIND_TYPE === "DYNAMIC"
            //       ? acc
            //       : { ...acc, [ctrl.PRAM_NAME]: ctrl.BIND_VALUE },
            //   {}
            // );
            // let bind_values = params.reduce(
            //   (acc: any, param: any) =>
            //     param.dataType === "SYS_REFCURSOR"
            //       ? acc
            //       : { ...acc, [param.param]: nonUiValues[param.param] },
            //   {}
            // );

            // NOTE: if any updates are required for P_COMP_CODE or P_USR_ID, then please update in the getDocExportPayload function inside reportService of API too.
            let bind_values = params.reduce(
                (acc: any, param: any) =>
                    (param.param + "").toUpperCase() === "P_COMP_CODE"
                        ? {
                            ...acc,
                            P_COMP_CODE: authUser.company_code,
                        }
                        : (param.param + "").toUpperCase() === "P_USR_ID"
                        ? {...acc, P_USR_ID: authUser.user}
                        : acc,
                {}
            );

            let formattedValues = {};
            Object.entries(formValues).forEach(([key, val]) => {

                //  console.log(formValues)
                bind_values = {
                    ...bind_values,
                    [key]: val.value,
                };
                // console.log(88888)
                //
                // console.log(bind_values)
                //
                // console.log(888888)


                // for unique reportId
                let value = val.value;
                let label = val.label;
                if (val.label instanceof Date) {
                    label = dayjs(val.label).format("DD-MM-YYYY");
                }
                if (val.value instanceof Date) {
                    value = dayjs(val.value).format("DD-MM-YYYY");
                }
                formattedValues = {
                    ...formattedValues,
                    [key]: {label, value},
                };
            });

            const payload = {
                rep_id: selectedReport.REP_ID,
                query: selectedReport.REP_SP,
                bind_params: selectedReport.REP_PARAM,
                reportName: selectedReport.REP_NAME,
                bind_values,
            };

            const reportId = JSON.stringify({
                id: selectedReport.REP_ID,
                name: selectedReport.REP_NAME,
                ...formattedValues,
            });

            let stockValues = {};
            Object.entries(formValues).forEach(([key]) => {
                for (const repCtrl of reportControls) {
                    const repCtrlNameUpper = (repCtrl.PRAM_NAME + "").toUpperCase();
                    if (repCtrlNameUpper === key) {
                        stockValues = {
                            ...stockValues,
                            [repCtrlNameUpper]: {
                                ...formValues[repCtrlNameUpper],
                                header: repCtrl.LABEL_TXT,
                            },
                        };
                    }
                }
            });
            // console.log(9999)
            // console.log(stockValues)
            // console.log(9999)

            const idExists = data?.some((value) => value.reportId === reportId);
            if (!idExists) {
                queryClient.setQueryData<TReportToGenerateData[]>(
                    ["reportGenerations-generatedData"],
                    (data) => {
                        if (data) {
                            const newData = {
                                reportId,
                                stockValues,
                                payload,
                                checkedColumns,
                            };
                            return [...data, newData];
                        }
                        return [];
                    }
                );
            } else {
                // toast("Report already selected", {
                //   icon: <IconAlertTriangle />,
                //   duration: 6000,
                //   style: {
                //     border: "1px solid #e9ad01",
                //     padding: "10px",
                //     backgroundColor: "#FFFAEE",
                //     color: "#e9ad01",
                //   },
                // });
                showCustomNotification({
                    title: "Alert",
                    message: "Report already selected",
                    notifyType: "WARNING",
                });
            }

            setLoadingReport(false);
        } catch (error) {
            setLoadingReport(false);
            // console.log("catched error : ", error);
        }
    };

    return (
        <>
            <Loader isLoading={isLoading}/>
            {/* when downloadButton is clicked, set data to modal and open it */}
            {/* modal moved to report card */}
            {/* <OptionsToPrintReportModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      /> */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <MainCard title="ERP Report" height="40rem">
                    <Grid mb={2} gutter="sm">
                        <Grid.Col lg={3} sm={6} xs={12}>
                            <AutocompleteCustom
                                dataArray={reportTypes ?reportTypes : []}
                                dataLabel="REP_NAME"
                                dataValue="REP_ID"
                                title="Report"
                                value={selectedReportId}
                                onChange={(repId) => {
                                    setReportControls([]);
                                    setSelectedReportId(repId);
                                    handleSelectReportType(repId);
                                }}
                                isLoading={isReportTypesLoading}
                                // onItemSubmit={(item) => {
                                //   setReportControls([]);
                                //   setSelectedReport(item.data);
                                //   handleSelectReportType(item.data);
                                // }}
                            />
                        </Grid.Col>
                    </Grid>
                    {!!reportControls.length && (
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={Yup.object().shape(reportValidationSchema)}
                            onSubmit={handleGenerateReport}
                        >
                            {({
                                  errors,
                                  handleSubmit,
                                  touched,
                                  values,
                                  setFieldValue,
                                  resetForm,
                              }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Stack>
                                        <Grid gutter="xs">
                                            {reportControls
                                                .sort((a, b) => a.ORDER_NO - b.ORDER_NO)
                                                .map((reportCtrl) => {
                                                    return (
                                                        <Grid.Col
                                                            lg={2}
                                                            sm={6}
                                                            xs={12}
                                                            key={reportCtrl.PRAM_NAME}
                                                        >
                                                            <Controls
                                                                reportCtrl={reportCtrl}
                                                                authUser={authUser}
                                                                formValues={values}
                                                                setFieldValue={setFieldValue}
                                                                reportControls={reportControls}
                                                                errors={errors}
                                                                touched={touched}
                                                                setInitialValues={setInitialValues}
                                                            />
                                                        </Grid.Col>
                                                    );
                                                })}
                                        </Grid>

                                        <Group spacing="sm" position="right">
                                            <Button
                                                sx={{maxWidth: "8rem"}}
                                                fullWidth
                                                variant="light"
                                                onClick={() => {
                                                    queryClient.cancelQueries([
                                                        "reportGeneration",
                                                        "dynamicControlValues",
                                                    ]);
                                                    resetForm();
                                                    // setData([]);
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                sx={{maxWidth: "8rem"}}
                                                fullWidth
                                                type="submit"
                                                disabled={isLoadingReport}
                                            >
                                                Submit
                                            </Button>
                                        </Group>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    )}
                    <Box mt={5}>
                        <MainCard title="Report Details">
                            <Stack>
                                {data?.length ? (
                                    data.map((dataItem) => (
                                        <ReportGenerationCard
                                            key={`rcard-${dataItem.reportId}`}
                                            data={dataItem}
                                        />
                                    ))
                                ) : (
                                    <p
                                        style={{
                                            textAlign: "center",
                                            color: "#cf2229",
                                            fontSize: "1.3rem",
                                        }}
                                    >
                                        No records found.
                                    </p>
                                )}
                            </Stack>
                        </MainCard>
                    </Box>
                </MainCard>
            </Box>
        </>
    );
};

export default GenerateReport;

import { useEffect, useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { generateRandomString } from "../../../../../helper/common";
import { IconCirclePlus, IconTrash } from "@tabler/icons";

/**
 * * This is a Procedure component that splits procedure fields
 * * so the user can enter proc name and each params without any typo
 * * and the combined single string will be set to formik.
 */

const ProcedureInput = ({
  formik,
  fieldName,
  clearFields,
  initialProc,
  setFetchedProcedure,
  reportProcedureName,
  setReportProcedureName,
  isProcParamsLoading,
}: {
  clearFields: boolean;
  fieldName: string;
  formik: any;
  initialProc: string;
  setFetchedProcedure: React.Dispatch<React.SetStateAction<string>>;
  reportProcedureName: string;
  setReportProcedureName: React.Dispatch<React.SetStateAction<string>>;
  isProcParamsLoading: boolean;
}) => {
  const [params, setParams] = useState<Record<string, string>>({
    randomKey45rn: "",
  });
  const [procedureName, setProcedureName] =
    useState<string>(reportProcedureName);

  // * Generate procedure from user inputs excluding empty fields
  const stringifyProcedureValues = (params: Record<string, string>) => {
    const nonEmptyValues = Object.values(params).filter(
      (value) => value !== ""
    );
    if (!procedureName || !nonEmptyValues.length) return "";
    const paramValue = nonEmptyValues.map((value) => `:${value}`);
    const previewString = `${procedureName}(${paramValue})`;
    return previewString;
  };

  // * autofill the inputs if any procedure exists
  useEffect(() => {
    if (initialProc) {
      // regex to check procedure - procName(:param1,:param2,:param3)
      const paramArray = initialProc.match(/(?<=:).*?(?=,|\))/g);
      const procName = initialProc.substring(0, initialProc.indexOf("("));
      if (paramArray) setParams(Object.fromEntries(Object.entries(paramArray)));
      setProcedureName(procName);
      setReportProcedureName(procName);
    } else {
      setParams({ randomKey45rn: "" });
      setProcedureName("");
      // setProcedureToFetch("");
    }
  }, [initialProc, clearFields, reportProcedureName]);

  useEffect(() => {
    const P_Json_Data = stringifyProcedureValues(params);
    formik.setFieldValue(fieldName, P_Json_Data);
  }, [params, procedureName]);

  return (
    <Grid.Col xs={12}>
      <Grid gutter="xs">
        <Grid.Col xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              gap: "10px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <TextInput
                id="procedureName"
                label="Procedure Name"
                value={procedureName}
                onChange={(e) => setProcedureName(e.currentTarget.value)}
                disabled={formik.isSubmitting}
              />
            </Box>
            <Button
              loading={isProcParamsLoading}
              onClick={() => {
                if (reportProcedureName !== procedureName) {
                  setFetchedProcedure("");
                  setReportProcedureName(procedureName);
                }
              }}
            >
              Fetch Params
            </Button>
          </Box>
        </Grid.Col>
        {reportProcedureName &&
          Object.keys(params).length > 0 &&
          Object.keys(params).map((paramKey, index: number) => (
            <Grid.Col xs={12} md={11} key={paramKey}>
              <Grid gutter="xs">
                <Grid.Col xs={11}>
                  <TextInput
                    id={`param-textField-of-${paramKey}`}
                    label={`Parameter ${index + 1}`}
                    disabled={formik.isSubmitting}
                    value={params[paramKey]}
                    onChange={(e) =>
                      setParams((prev: any) => ({
                        ...prev,
                        [paramKey]: e.currentTarget.value,
                      }))
                    }
                  />
                </Grid.Col>
                <Grid.Col xs={1} sx={{ display: "flex", alignItems: "end" }}>
                  <Tooltip label="Delete input field">
                    <ActionIcon
                      size="md"
                      onClick={() => {
                        const { [paramKey]: param, ...newParams } = params;
                        setParams(newParams);
                      }}
                      disabled={Object.keys(params).length === 1}
                    >
                      <IconTrash fontSize="medium" />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          ))}
        {reportProcedureName && (
          <Grid.Col xs={1} sx={{ display: "flex", alignItems: "end" }}>
            <Tooltip label="Add input field">
              <ActionIcon
                size="md"
                onClick={() => {
                  const id = generateRandomString(6);
                  setParams((prev: any) => ({
                    ...prev,
                    [id]: "",
                  }));
                }}
              >
                <IconCirclePlus fontSize="medium" />
              </ActionIcon>
            </Tooltip>
          </Grid.Col>
        )}

        <Grid.Col xs={12} mt={2}>
          <Textarea
            id="read-only-params-textfield"
            value={formik.values[fieldName]}
            label="Preview in Json format"
            maxRows={5}
            error={
              !!(formik.touched[fieldName] && formik.errors[fieldName]) &&
              formik.errors[fieldName]
            }
            readOnly
          />
        </Grid.Col>
        {/* <Grid
        item
        xs={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          size="large"
          color="primary"
          aria-label="refresh field"
          onClick={() => {
            const P_Json_Data = stringifyProcedureValues(params);
            formik.setFieldValue(fieldName, P_Json_Data);
          }}
        >
          <RefreshIcon fontSize="inherit" />
        </IconButton>
      </Grid> */}
      </Grid>
    </Grid.Col>
  );
};

export default ProcedureInput;

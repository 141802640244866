import { useEffect, useMemo, useState } from "react";
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Modal,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { generateRandomString } from "../../../../../helper/common";
import {
  IconArrowBack,
  IconCirclePlus,
  IconCircleX,
  IconDeviceFloppy,
  IconCornerDownRight,
  IconTrash,
  IconCalendar,
} from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";

type TOption = {
  label: string;
  value: string;
};

/**
 * * Modal to enter static values for the controls of Ui settings (Params)
 */

const StaticValueModal = ({
  isModalOpen,
  handleModalClose,
  paramKey,
  cardFormik,
}: any) => {
  const [options, setOptions] = useState<Record<string, TOption>>();
  const [jsonPreview, setJsonPreview] = useState<string>();
  const [date, setDate] = useState<Date>(new Date());
  const [clearField, triggerClearField] = useState<boolean>(false);
  const matches = useMediaQuery("(min-width: 1200px)");
  const queryClient = useQueryClient();
  const controlId: { [x: string]: string } | undefined = useMemo(() => {
    const controls:
      | { label: string; value: string; code: string }[]
      | undefined = queryClient.getQueryData(["uiConfig", "controlOptions"]);
    const control = controls?.reduce(
      (acc, curr) => ({ ...acc, [curr.code]: curr.value }),
      {}
    );
    return control;
  }, [queryClient]);

  // * If parm_val exists, parse and autofill the input fields
  useEffect(() => {
    if (
      paramKey &&
      cardFormik.values.value_type === "STATIC" &&
      (cardFormik.values.control_id + "" === controlId?.DRWN + "" ||
        cardFormik.values.control_id + "" === controlId?.S_DRWN + "")
    ) {
      if (cardFormik.values.parm_val) {
        const values = JSON.parse(cardFormik.values.parm_val);
        setOptions(Object.fromEntries(Object.entries(values)));
      } else setOptions({ rand5: { label: "", value: "" } });
    } else if (
      paramKey &&
      cardFormik.values.value_type === "STATIC" &&
      cardFormik.values.control_id + "" === controlId?.TXT + ""
    ) {
      setJsonPreview(cardFormik.values.parm_val);
    } else if (
        paramKey &&
        cardFormik.values.value_type === "STATIC" &&
        cardFormik.values.control_id + "" === controlId?.FL_UPLOAD + ""
    ) {
        setJsonPreview(cardFormik.values.parm_val);
    } else if (
      paramKey &&
      cardFormik.values.value_type === "STATIC" &&
      cardFormik.values.control_id + "" === controlId?.DT_PCKR + ""
    ) {
      if (cardFormik.values.parm_val) {
        const parsedDate = new Date(cardFormik.values.parm_val);
        setDate(parsedDate);
      }
    }
  }, [
    paramKey,
    clearField,
    cardFormik.values.value_type,
    cardFormik.values.control_id,
    cardFormik.values.parm_val,
    controlId?.DRWN,
    controlId?.S_DRWN,
      controlId?.FL_UPLOAD,
    controlId?.TXT,
    controlId?.DT_PCKR,
  ]);

  // * Generate parm_val json from user inputs excluding empty fields
  const stringifyOptionValues = (options: Record<string, TOption>) => {
    const nonEmptyValues = Object.values(options).filter(
      (val) => val.label !== "" && val.value !== ""
    );
    if (!nonEmptyValues.length) return "";

    const previewString = JSON.stringify(nonEmptyValues);
    return previewString;
  };

  useEffect(() => {
    if (options && Object.keys(options).length) {
      const Json_Data = stringifyOptionValues(options);
      setJsonPreview(Json_Data);
    }
  }, [options]);

  return (
    <Modal
      opened={isModalOpen}
      onClose={handleModalClose}
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
      size={matches ? "50%" : "100%"}
      zIndex={500}
    >
      {!!paramKey && !!cardFormik.values.control_id ? (
        <>
          <Title order={3} align="center" mb={10}>
            Enter Static Value
          </Title>

          <Grid gutter="sm">
            {cardFormik.values.control_id + "" === controlId?.TXT + "" && (
              <Grid.Col xs={12}>
                <TextInput
                  // id="control_id_value"
                  value={jsonPreview}
                  onChange={(e) => setJsonPreview(e.currentTarget.value)}
                />
              </Grid.Col>
            )}
            {cardFormik.values.control_id + "" === controlId?.DT_PCKR + "" && (
              <Grid.Col xs={12}>
                <DatePicker
                  value={date}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newDate: Date) => {
                    setDate(newDate);
                  }}
                  clearable={false}
                  icon={<IconCalendar size={20} />}
                  zIndex={500}
                />
              </Grid.Col>
            )}
            {(cardFormik.values.control_id + "" === controlId?.DRWN + "" ||
              cardFormik.values.control_id + "" === controlId?.S_DRWN + "") && (
              <Grid.Col xs={12}>
                <Grid>
                  {!!options &&
                    Object.entries(options)?.map(([key, option], index) => (
                      <Grid.Col xs={11} key={key}>
                        <Grid gutter="sm">
                          <Grid.Col xs={5.5}>
                            <TextInput
                              label={`label ${index + 1}`}
                              id={`label-of-${key}`}
                              value={option.label}
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setOptions((prev) => ({
                                  ...prev,
                                  [key]: {
                                    ...option,
                                    label: value,
                                  },
                                }));
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col xs={5.5}>
                            <TextInput
                              label={`value ${index + 1}`}
                              id={`value-of-${key}`}
                              value={option.value}
                              onChange={(e) => {
                                const { value } = e.currentTarget;
                                setOptions((prev) => ({
                                  ...prev,
                                  [key]: {
                                    ...option,
                                    value,
                                  },
                                }));
                              }}
                            />
                          </Grid.Col>
                          <Grid.Col
                            xs={1}
                            sx={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "end",
                            }}
                            pb="sm"
                          >
                            <Tooltip label="Delete field">
                              <ActionIcon
                                size="md"
                                onClick={() => {
                                  const { [key]: keyId, ...newOptions } =
                                    options;
                                  setOptions(newOptions);
                                }}
                                disabled={Object.keys(options).length === 1}
                              >
                                <IconTrash fontSize="medium" />
                              </ActionIcon>
                            </Tooltip>
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                    ))}
                  <Grid.Col
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                    }}
                    pb="md"
                  >
                    <Tooltip label="Add field">
                      <ActionIcon
                        size="md"
                        onClick={() => {
                          const id = generateRandomString(6);
                          setOptions((prev) => ({
                            ...prev,
                            [id]: { label: "", value: "" },
                          }));
                        }}
                      >
                        <IconCirclePlus fontSize="medium" />
                      </ActionIcon>
                    </Tooltip>
                  </Grid.Col>
                  <Grid.Col xs={12}>
                    <Textarea
                      id="read-only-static-value-textfield"
                      value={jsonPreview}
                      label="Preview in Json format"
                      maxRows={5}
                      readOnly
                    />
                  </Grid.Col>
                  {/* <Grid.Col xs={1} display="flex" justifyContent="center">
                      <IconButton
                        size="large"
                        color="primary"
                        aria-label="refresh field"
                        onClick={() => {
                          if (options && Object.keys(options).length) {
                            const Json_Data = stringifyOptionValues(options);
                            setJsonPreview(Json_Data);
                          }
                        }}
                      >
                        <RefreshIcon fontSize="inherit" />
                      </IconButton>
                    </Grid.Col> */}
                </Grid>
              </Grid.Col>
            )}

              {cardFormik.values.control_id + "" === controlId?.FILE_UPLOAD + "" && (
                  <Grid.Col xs={12}>
                      <TextInput
                          // id="control_id_value"
                          value={jsonPreview}
                          onChange={(e) => setJsonPreview(e.currentTarget.value)}
                      />
                  </Grid.Col>
              )}


            <Grid.Col xs={12} mt={1}>
              <Group spacing="xs" position="apart">
                {/* <Grid.Col xs={4}> */}
                <Button
                  variant="outline"
                  // fullWidth
                  leftIcon={<IconCircleX />}
                  onClick={() => {
                    handleModalClose();
                  }}
                >
                  Cancel
                </Button>
                {/* </Grid.Col>
                <Grid.Col
                  xs={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                > */}
                <Button
                  variant="outline"
                  leftIcon={<IconCornerDownRight />}
                  onClick={() => {
                    triggerClearField((prev) => !prev);
                  }}
                >
                  Reset
                </Button>
                {/* </Grid.Col> */}
                {/* <Grid.Col xs={4}> */}
                <Button
                  leftIcon={<IconDeviceFloppy />}
                  variant="filled"
                  // fullWidth
                  onClick={() => {
                    if (
                      cardFormik.values.value_type === "STATIC" &&
                      cardFormik.values.control_id + "" ===
                        controlId?.DT_PCKR + ""
                    )
                      cardFormik.setFieldValue("parm_val", date);
                    else cardFormik.setFieldValue("parm_val", jsonPreview);
                    handleModalClose();
                  }}
                >
                  Add
                </Button>
                {/* </Grid.Col> */}
              </Group>
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <Grid>
          <Grid.Col xs={12}>
            <Title order={3} align="center" mb={3}>
              No Control Type is selected. Please select a control type to enter
              Static Values.
            </Title>
          </Grid.Col>
          <Grid.Col xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="outline"
              fullWidth
              leftIcon={<IconArrowBack />}
              onClick={() => {
                handleModalClose();
              }}
            >
              Back
            </Button>
          </Grid.Col>
        </Grid>
      )}
    </Modal>
  );
};

export default StaticValueModal;

import { useEffect, useState } from "react";
import { Box, LoadingOverlay, Paper, Select, Stack } from "@mantine/core";
import MainCard from "../../../components/Cards/MainCard";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { isOracleError } from "../../../helper/common";
import { showCustomNotification } from "../../../helper/customNotification";
import { useQuery } from "@tanstack/react-query";
import { getUserDocuments, getUserList } from "./queries";
import DocCard from "./DocCard";
import { useDebouncedValue } from "@mantine/hooks";

type TUserList = {
  EMP_COMP_CODE: string;
  USER_CODE: string;
  EMP_CODE: string;
  EMP_SHORT_NAME: string;
  EMP_NAME: string;
  EMP_DIVN_CODE: string;
  DIVN_INCHARGE: string;
};

export type TUserUploadedDoc = {
  DOC_EMP_CODE: string;
  EMP_NAME: string;
  DOC_NO: string;
  DOC_TYPE_CODE: string;
  DOC_TYPE_NAME: string;
  DOC_ISS_PLACE: string;
  DOC_ISS_DT: Date;
  DOC_EXP_DT: Date;
  DOC_URL: string | null;
};

const DocumentUpload = () => {
  const [selectedUser, setSelectedUser] = useState({
    empCompCode: "",
    empCode: "",
    empName: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 1000);
  const [userOptions, setUserOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [userValue, setUserValue] = useState<string | null>(null);
  const {
    authUser: { user_emp_code, company_code },
  }: RootStateOrAny = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const { isFetching, data: userList } = useQuery<TUserList[], Error>(
    ["userList", debouncedSearchTerm],
    () =>
      getUserList({
        user_emp_code,
        company_code,
        searchTerm: debouncedSearchTerm,
      }),
    {
      // staleTime: 0,
      enabled: !!debouncedSearchTerm,
      // onSuccess: (data) => {
      //   const options = data.map((user) => ({
      //     label: user.EMP_NAME,
      //     value: user.EMP_CODE,
      //   }));
      //   setUserOptions(options);
      // },
      onError: (error) => {
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  const { isFetching: isDocLoading, data: docs } = useQuery<
    TUserUploadedDoc[],
    Error
  >(
    ["userDocuments", selectedUser.empCode, selectedUser.empCompCode],
    () =>
      getUserDocuments({
        empCode: selectedUser.empCode,
        empCompCode: selectedUser.empCompCode,
      }),
    {
      // staleTime: 0,
      enabled: !!selectedUser.empCode && !!selectedUser.empCompCode,
      onError: (error) => {
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  useEffect(() => {
    let active = true;
    if (userList) {
      const options = userList.map((user) => ({
        label: user.EMP_NAME,
        value: user.EMP_CODE,
      }));
      if (active) setUserOptions(options);
    }

    return () => {
      active = false;
    };
  }, [userList]);

  return (
    <>
      <MainCard title="Upload Documents" height="20rem">
        <Box sx={{ width: "100%" }} pt="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
            mb="lg"
          >
            <Select
              sx={{ width: "20rem" }}
              searchable
              zIndex={500}
              placeholder="Search user"
              maxDropdownHeight={250}
              nothingFound={isFetching ? "Loading..." : "No user found"}
              onSearchChange={(query) => {
                setSearchTerm(query.toLowerCase());
                if (!query) setUserOptions([]);
              }}
              onDropdownClose={() => {
                setUserValue("");
              }}
              value={userValue}
              onChange={(value) => {
                const user = userList?.find((user) => user.EMP_CODE === value);
                if (user) {
                  setSelectedUser({
                    empCode: user.EMP_CODE,
                    empCompCode: user.EMP_COMP_CODE,
                    empName: user.EMP_NAME,
                  });
                }
              }}
              data={isFetching ? [] : userOptions}
            />
          </Box>

          <Paper
            sx={{
              width: "100%",
              maxHeight: "70vh",
              overflowY: "auto",
              mb: 2,
              position: "relative",
            }}
          >
            <LoadingOverlay visible={isDocLoading} />
            <MainCard
              height={300}
              title={
                selectedUser.empName &&
                `Upload Documents of ${selectedUser.empName}`
              }
              titleOrder={4}
            >
              <Stack spacing="md">
                {docs && docs.length > 0 ? (
                  docs.map((doc) => (
                    <DocCard
                      key={`${doc.DOC_NO}_${doc.DOC_TYPE_CODE}`}
                      doc={doc}
                      empCode={selectedUser.empCode}
                      empCompCode={selectedUser.empCompCode}
                    />
                  ))
                ) : (
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#cf2229",
                        fontSize: "1.3rem",
                      }}
                    >
                      {!selectedUser.empCode
                        ? "No user selected."
                        : "No user documents found."}
                    </p>
                  </div>
                )}
              </Stack>
            </MainCard>
          </Paper>
        </Box>
      </MainCard>
    </>
  );
};

export default DocumentUpload;

import { api } from "../../../api";

type TGetUserDocProps = { empCode: string; empCompCode: string };
type TGetUserListProps = {
  user_emp_code: string;
  company_code: string;
  searchTerm: string;
};
type TUploadUserDocProps = { formData: FormData; resetForm: any };

export const getUserDocuments = async ({
  empCode,
  empCompCode,
}: TGetUserDocProps) => {
  const response = await api.get(`users/documents/${empCode}/${empCompCode}`);
  if (!response.status) throw new Error(response.message);
  return JSON.parse(response.data);
};

export const uploadUserDocument = async ({
  formData,
  resetForm,
}: TUploadUserDocProps) => {
  const response = await api.post(
    "users/documents/upload",
    undefined,
    formData
  );

  if (!response.status) throw new Error(response.message);
  return response.data;
};

export const getUserList = async ({
  user_emp_code,
  company_code,
  searchTerm,
}: TGetUserListProps) => {
  const response = await api.get(
    `users/list/${user_emp_code}/${company_code}/${searchTerm}`
  );
  if (!response.status) throw new Error(response.message);
  return response.data;
};

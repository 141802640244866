import { showNotification } from "@mantine/notifications";

type TNotificationProps = {
  title: string;
  message: string;
  autoClose?: false | number;
  notifyType: "WARNING" | "ERROR" | "SUCCESS";
  // position?:
  //   | "top-left"
  //   | "top-right"
  //   | "top-center"
  //   | "bottom-left"
  //   | "bottom-right"
  //   | "bottom-center";
};

export const showCustomNotification = ({
  title,
  message,
  autoClose = 6000,
  notifyType,
}: // position = "top-right",
TNotificationProps) => {
  // const IconComponent =
  //   notifyType === "WARNING"
  //     ? IconAlertTriangle
  //     : notifyType === "ERROR" || "SERVER_ERROR"
  //     ? IconCircleX
  //     : IconCircleCheck;

  showNotification({
    title,
    message,
    // icon: IconComponent,
    autoClose,
    styles: (theme) => ({
      root: {
        padding: "1rem",
        backgroundColor:
          notifyType === "WARNING"
            ? theme.colors.yellow[6]
            : notifyType === "ERROR"
            ? theme.colors.red[6]
            : theme.colors.green[6],
        borderColor:
          notifyType === "WARNING"
            ? theme.colors.yellow[6]
            : notifyType === "ERROR"
            ? theme.colors.red[6]
            : theme.colors.green[6],

        "&::before": { backgroundColor: theme.white },
      },
      body: { marginLeft: "0.5rem" },
      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": {
          backgroundColor:
            notifyType === "WARNING"
              ? theme.colors.yellow[7]
              : notifyType === "ERROR"
              ? theme.colors.red[7]
              : theme.colors.green[7],
        },
      },
    }),
  });
};

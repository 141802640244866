import React from "react";
import {
  Badge,
  Box,
  DefaultMantineColor,
  Grid,
  Group,
  Text,
} from "@mantine/core";

type TProps = {
  title: string;
  values: string[];
  badgeValue?: {
    value: string;
    color: DefaultMantineColor | undefined;
  };
  footerValue?: string;
};

const InfoRow = ({ title, values, badgeValue, footerValue }: TProps) => {
  return (
    <Grid>
      <Grid.Col xs={6} lg={4} xl={3}>
        <Text
          size="lg"
          sx={(theme) => ({
            color: theme.colors[theme.primaryColor][6],
          })}
        >
          {title}:
        </Text>
      </Grid.Col>
      <Grid.Col xs={6} lg={8} xl={9}>
        <Group align="center">
          <Box>
            {values.map((val) => (
              <Text
                key={val}
                size="lg"
                sx={(theme) => ({
                  color: theme.colors[theme.primaryColor][6],
                })}
              >
                {val}
              </Text>
            ))}
          </Box>
          {badgeValue && (
            <Badge color={badgeValue.color} radius="xs">
              {badgeValue.value}
            </Badge>
          )}
        </Group>
        {footerValue && (
          <Text
            size="xs"
            sx={(theme) => ({
              color: theme.colors[theme.primaryColor][6],
            })}
          >
            {footerValue}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default InfoRow;

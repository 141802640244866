import {
  Box,
  DefaultMantineColor,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

type TProps = {
  title: string;
  endTitle?: string;
  subTitle: string;
  Icon: any;
  color: DefaultMantineColor | undefined;
};

const StatusCard = ({
  title,
  endTitle = "",
  subTitle,
  Icon,
  color,
}: TProps) => {
  return (
    <Paper px="xl" py={35} shadow="sm" radius="md" sx={{ height: "100%" }}>
      <Group position="apart">
        <Box sx={(theme) => ({ color: theme.colors[theme.primaryColor][6] })}>
          <ThemeIcon variant="light" radius="xl" size={75} color={color}>
            <Icon size={40} />
          </ThemeIcon>
          <Text sx={{ maxWidth: "10rem" }}>{subTitle}</Text>
        </Box>
        <Title>
          {title + " "}
          <Text component="span" size="sm" weight="normal">
            {endTitle}
          </Text>
        </Title>
      </Group>
    </Paper>
  );
};

export default StatusCard;

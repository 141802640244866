const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  BASE_URL: "http://localhost:1234",
  basename: "",
  defaultPath: "",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  AWS_S3_FRONT_BASE_URL: "https://ffcportal-01.s3.amazonaws.com/report",
};

export default config;

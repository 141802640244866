type TInitialState = {
  title: string;
  message: string;
  isSnackbarOpen: boolean;
  isAlertOpen: boolean;
  alertArray: {
    alertId: string;
    alertTitle: string;
    alertType: "WARNING" | "ERROR" | "SUCCESS" | "ORACLE_ERROR";
    alertMessage: string;
  }[];
};
const initialState: TInitialState = {
  title: "",
  message: "",
  isSnackbarOpen: false,
  isAlertOpen: false,
  alertArray: [],
};


const setProfileToken = (token: any) => {
    localStorage.setItem(
        "ffc-user-profile",
        JSON.stringify({ "user-profile-token": token })
    );
};

const getProfileToken = () => {
    const token = localStorage.getItem("ffc-user-profile");
    if (token) {
        const access_token = JSON.parse(token)["user-profile-token"];
        return access_token;
    }
    return null;
};


const snackbarReducer = (state = initialState,  action:any ={}) => {
  switch (action.type) {
    case "OPEN_SNACK_TOAST":
      return {
        ...state,
        title: action.payload.title,
        message: action.payload.message,
        isSnackbarOpen: true,
      };
    case "CLOSE_SNACK_TOAST":
      return {
        ...state,
        isSnackbarOpen: false,
      };
    case "OPEN_ALERT_MODAL": {
      const alertTitle = action.payload.title;
      const alertMessage = action.payload.message;
      const alertType = action.payload.type;
      const alertId = alertTitle + alertMessage + alertType;
      const isIdExist = state.alertArray.some(
        (alert) => alert.alertId === alertId
      );
      const newAlertArray = [...state.alertArray];
      if (!isIdExist)
        newAlertArray.push({ alertId, alertTitle, alertMessage, alertType });
      return {
        ...state,
        // isAlertOpen: true,
        alertArray: newAlertArray,
      };
    }
    case "CLOSE_ALERT_MODAL": {
      const alertFilter = state.alertArray.filter(
        (alert) => alert.alertId !== action.payload.id
      );
      return {
        ...state,
        // isAlertOpen: false,
        alertArray: alertFilter,
      };
    }
    case "CLEAR_ALERT_MODAL": {
      return {
        ...state,
        alertArray: [],
      };
    }
    default:
      return state;
  }
};

export default snackbarReducer;

import { useEffect, useState, useRef } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Modal,
  Stepper,
  Title,
} from "@mantine/core";
import { generateRandomString, isOracleError } from "../../../../helper/common";
import { reportHeaderInitialValues,  reportTypeInitialValues} from "../Formik/FormikData";
import { TReportTypeConfig, TReportTypeValues, TWizardData } from "../typings";
import StepReportType from "./StepReportType";
import StepUiSettings from "./StepUiSettings";
import StepDependentFields from "./StepDependentFields";
import StepReportHeader from "./StepReportHeader";
import { useMediaQuery } from "@mantine/hooks";
import { IconCircleX } from "@tabler/icons";
import { useQuery } from "@tanstack/react-query";
import { showCustomNotification } from "../../../../helper/customNotification";
import { getReportProcParameters, getReportTypeConfig } from "../queries";
import WizardLoader from "./Loader";

type TProps = {
  isWizardOpen: boolean;
  setWizardOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  reportToEdit: TReportTypeValues | undefined;
  setReportToEdit: React.Dispatch<
    React.SetStateAction<TReportTypeValues | undefined>
  >;
  reportList: TReportTypeValues[] | undefined;
  controlOptions: { label: string; value: string }[] | undefined;
};

export type TProcParameter = {
  ARGUMENT_NAME: string;
  SLNO: number;
  DATA_TYPE: string;
  IN_OUT: "IN" | "OUT" | "IN OUT";
};

/**
 * * This is a Wizard component to Add Report Type and its
 * * UI Settings (Params), Dependent Fields (Sub params), and Report Header (Out params).
 */

const initialData = {
  reportType: reportTypeInitialValues,
  reportParams: null,
  reportSubParams: null,
  reportOutParams: reportHeaderInitialValues,
};

export const WizardError = ({
  title = "",
  isButton = false,
  buttonClickHandler = () => {},
}) => (
  <Box
    sx={{
      width: "50vw",
      height: "50vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 3,
    }}
  >
    <Title order={3}>{title}</Title>
    {isButton && (
      <Button
        variant="outline"
        fullWidth
        leftIcon={<IconCircleX />}
        onClick={buttonClickHandler}
        sx={{ maxWidth: "8rem" }}
      >
        Close
      </Button>
    )}
  </Box>
);

const ConfigWizard = ({
  isWizardOpen,
  setWizardOpenState,
  reportToEdit,
  setReportToEdit,
  reportList,
  controlOptions,
}: TProps) => {
  const { authUser }: RootStateOrAny = useSelector((state: any) => state.auth);
  const [currentStep, setCurrentStep] = useState(4);
  const [selectedReportType, setSelectedReportType] = useState<
    TReportTypeValues | undefined
  >(reportToEdit);
  const [wizardData, setWizardData] = useState<TWizardData>(initialData);
  const [isLoading, setLoading] = useState(false);
  const [reportProcedureName, setReportProcedureName] = useState("");
  const [fetchedProcedure, setFetchedProcedure] = useState("");
  const [procArgChange, setProcArgChanged] = useState("");
  const reloadWizardData = useRef(true);
  const existingReportNames = useRef<string[]>([]);
  const matches = useMediaQuery("(min-width: 1300px)");
  const dispatch = useDispatch();

  const { isFetching: isProcParamsLoading, data: argParameters } = useQuery<
    TProcParameter[],
    Error
  >(
    ["uiConfig", "reportProcedureParameters", reportProcedureName],
    () => getReportProcParameters(reportProcedureName),
    {
      enabled: !!reportProcedureName,
      // staleTime: 0,
      // onSuccess: (data) => {
      //   if (!data.length) {
      //     setFetchedProcedure("");
      //   } else {
      //     const paramValue = data.map((value) => `:${value.ARGUMENT_NAME}`);
      //     const previewString = `${reportProcedureName}(${paramValue})`;
      //     setFetchedProcedure(previewString);
      //   }
      // },
      onError: (error) => {
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  const { isFetching: isParamsLoading, data: selectedReport } = useQuery<
    TReportTypeConfig,
    Error
  >(
    ["uiConfig", "reportType", reportToEdit?.reportId],
    () => getReportTypeConfig(reportToEdit),
    {
      enabled: !!reportToEdit,
      onError: (error) => {
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }
      },
    }
  );

  useEffect(() => {
    if (!argParameters) return;
    // if (!argParameters.length) {
    //   setFetchedProcedure("");
    // } else {
    const paramValue = argParameters.map((value) => `:${value.ARGUMENT_NAME}`);
    const previewString = `${reportProcedureName}(${paramValue})`;
    setFetchedProcedure(previewString);
    // }
  }, [argParameters, reportProcedureName]);

  useEffect(() => {
    setSelectedReportType(reportToEdit);
    setFetchedProcedure(reportToEdit?.reportProcedure ?? "");
  }, [reportToEdit]);

  const handleWizardClose = () => {
    setReportToEdit(undefined);
    if (isLoading) setLoading(false);
    setWizardOpenState(false);
    reloadWizardData.current = true;
    // setCurrentStep(5); // step will be handled in useEffect
  };

  const handleNextStep = () => {
    if (currentStep > 2) handleWizardClose();
    else {
      setLoading(true);
      setTimeout(() => {
        setCurrentStep((prev: number) => prev + 1);
      }, 260);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setLoading(true);
      setTimeout(() => {
        setCurrentStep((prev: number) => prev - 1);
      }, 260);
    }
  };

  useEffect(() => {
    if (
      isWizardOpen &&
      reloadWizardData.current &&
      reportList &&
      selectedReport
    ) {
      reloadWizardData.current = false;

      // get all existing report names
      const repNames = reportList.reduce((acc, report) => {
        return [...acc, report.reportName];
      }, [] as string[]);
      if (reportToEdit) {
        const nameIndex = repNames.findIndex(
          (name) => name === reportToEdit.reportName
        );
        if (nameIndex !== -1) {
          repNames.splice(nameIndex, 1);
        }
      }
      existingReportNames.current = repNames;

      if (reportToEdit) {
        // setLoading(true);
        let params = selectedReport.reportParams.reduce((acc, curr) => {
          const id = generateRandomString(6);
          return { ...acc, [id]: curr };
        }, {});
        let outParams = selectedReport.reportOutParams.reduce((acc, curr) => {
          const id = generateRandomString(6);
          return { ...acc, [id]: curr };
        }, {});

        setWizardData({
          reportType: reportToEdit,
          reportParams: params,
          reportSubParams: selectedReport.reportSubParams,
          reportOutParams: outParams,
        });
        // setLoading(false);
      } else setWizardData(initialData);
    }
  }, [isWizardOpen, reportList, reportToEdit, selectedReport]);

  useEffect(() => {
    if (isWizardOpen) {
      setCurrentStep(0);
    } else {
      setCurrentStep(4);
      setWizardData(initialData);
      setFetchedProcedure("");
      setReportProcedureName("");
      setProcArgChanged("");
    }
  }, [isWizardOpen]);

  useEffect(() => {
    if (!reportProcedureName) {
      setProcArgChanged("");
      return;
    }
    if (!isProcParamsLoading && argParameters) {
      const currentProc = wizardData?.reportType?.reportProcedure;
      if (!currentProc) return;
      const currentParamArray = currentProc.match(/(?<=:).*?(?=,|\))/g);
      if (!currentParamArray) return;
      const currentProcName = currentProc.substring(
        0,
        currentProc.indexOf("(")
      );
      const isProcNameSame =
        reportProcedureName.toUpperCase() === currentProcName.toUpperCase();
      const isParamSame = currentParamArray.every((value) =>
        argParameters.some(
          (arg) => arg.ARGUMENT_NAME.toUpperCase() === value.toUpperCase()
        )
      );
      if (
        !isProcNameSame ||
        currentParamArray.length !== argParameters.length ||
        !isParamSame
      ) {
        const newParams = argParameters.map(
          (value) => `:${value.ARGUMENT_NAME}`
        );
        const newProcedure = `${reportProcedureName}(${newParams})`;
        setProcArgChanged(newProcedure);
      } else {
        setProcArgChanged("");
      }
    }
  }, [
    argParameters,
    isProcParamsLoading,
    reportProcedureName,
    wizardData.reportType.reportProcedure,
  ]);

  return (
    <Modal
      opened={isWizardOpen}
      onClose={handleWizardClose}
      centered
      closeOnEscape={false}
      closeOnClickOutside={false}
      size={matches ? "70%" : "100%"}
      zIndex={500}
      withCloseButton={false}
    >
      <Box sx={{ width: "100%", position: "relative" }}>
        <WizardLoader
          isLoading={isLoading}
          isProcParamsLoading={isProcParamsLoading}
          isParamsLoading={isParamsLoading}
        />
        <Stepper active={currentStep}>
          <Stepper.Step label="Report Type">
            <StepReportType
              next={handleNextStep}
              authUser={authUser}
              reportInitialValues={wizardData.reportType}
              setWizardData={setWizardData}
              handleWizardClose={handleWizardClose}
              selectedReportType={selectedReportType}
              setLoading={setLoading}
              setSelectedReportType={setSelectedReportType}
              existingReportNames={existingReportNames.current}
              reportProcedureName={reportProcedureName}
              setReportProcedureName={setReportProcedureName}
              isProcParamsLoading={isProcParamsLoading}
              argParameters={argParameters}
              wizardData={wizardData}
              fetchedProcedure={fetchedProcedure}
              setFetchedProcedure={setFetchedProcedure}
              procArgChange={procArgChange}
              setProcArgChanged={setProcArgChanged}
            />
          </Stepper.Step>
          <Stepper.Step label="UI Settings">
            <StepUiSettings
              next={handleNextStep}
              prev={handlePrevStep}
              authUser={authUser}
              handleWizardClose={handleWizardClose}
              selectedReportType={selectedReportType}
              setLoading={setLoading}
              wizardDataParam={wizardData.reportParams}
              setWizardData={setWizardData}
              controlOptions={controlOptions}
              reportProcedure={wizardData.reportType.reportProcedure}
            />
          </Stepper.Step>
          <Stepper.Step label="Dependent Fields">
            <StepDependentFields
              next={handleNextStep}
              prev={handlePrevStep}
              authUser={authUser}
              handleWizardClose={handleWizardClose}
              selectedReportType={selectedReportType}
              setLoading={setLoading}
              wizardData={wizardData}
              setWizardData={setWizardData}
            />
          </Stepper.Step>
          <Stepper.Step label="Report Header">
            <StepReportHeader
              prev={handlePrevStep}
              authUser={authUser}
              handleWizardClose={handleWizardClose}
              selectedReportType={selectedReportType}
              setLoading={setLoading}
              wizardData={wizardData}
            />
          </Stepper.Step>
        </Stepper>
      </Box>
    </Modal>
  );
};

export default ConfigWizard;

import MainCard from "../../components/Cards/MainCard";
import MyStatus from "./MyStatus";
import ProjectSummary from "./ProjectSummary";

const Dashboard = () => {
  return (
    <MainCard titleOrder={2} title="Dashboard">
      <MyStatus />
      {/*<ProjectSummary />*/}
    </MainCard>
  );
};

export default Dashboard;

import {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import {api} from "../../../api";
import Loader from "../../../components/Loader";
import MainCard from "../../../components/Cards/MainCard";
import {
    ActionIcon,
    Box,
    Button,
    Grid,
    Group,
    Pagination,
    Paper,
    Table,
    Tooltip,
} from "@mantine/core";
import AddControlModal from "./AddControlModal";
import EditControlModal from "./EditControlModal";
import {
    IconChevronDown,
    IconChevronUp,
    IconEdit,
    IconTrash,
} from "@tabler/icons";
import {showCustomNotification} from "../../../helper/customNotification";
import {isOracleError} from "../../../helper/common";
import {openDeleteModal} from "../../../components/Dialogs/confirmationDailog";
import {ModalsProvider} from "@mantine/modals";

type Order = "asc" | "desc";
type TableColumnHeadersProps = {
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
};

type HeadCell = {
    id: string;
    label: string;
    // align: "left" | "center" | "right" | "justify" | "inherit" | undefined;
    hidden: boolean;
};

type TControl = {
    CRUIC_ID: string | number;
    CRUIC_NAME: string;
    CRUIC_CODE: string;
};
type TEditControlInitialValue = {
    ctrlName: string;
    ctrlCode: string;
    ctrlId: string | number;
};

const headCells: HeadCell[] = [
    // {
    //   id: "CRUIC_ID",
    //   label: "Control Id",
    //   // align: "left",
    //   hidden: true,
    // },
    {
        id: "CRUIC_NAME",
        label: "Control Name",
        // align: "left",
        hidden: false,
    },
    {
        id: "CRUIC_CODE",
        label: "Control Code",
        // align: "left",
        hidden: false,
    },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

// function descendingComparator(a: any, b: any, orderBy: any) {
//   // if (!(a[orderBy] && b[orderBy])) return 0;
//   const itemA = isNaN(a[orderBy])
//     ? a[orderBy].split(" ").join("").toLocaleLowerCase()
//     : Number(a[orderBy]);
//   const itemB = isNaN(b[orderBy])
//     ? b[orderBy].split(" ").join("").toLocaleLowerCase()
//     : Number(b[orderBy]);

//   if (itemB < itemA) {
//     return -1;
//   }
//   if (itemB > itemA) {
//     return 1;
//   }
//   return 0;
// }

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const TableColumnHeaders = (props: TableColumnHeadersProps) => {
    const {order, orderBy, onRequestSort} = props;

    const createSortHandler =
        (property: any) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <thead>
        <tr
            style={{
                backgroundColor: "#2e439e",
                // color: "#404040",
            }}
        >
            {headCells.map((headCell) => (
                <th
                    key={headCell.id}
                    align="left"
                    style={{color: "#EAE9E7"}}
                    // align={headCell.numeric ? "right" : "left"}
                >
                    <Group spacing="xs" onClick={createSortHandler(headCell.id)}>
                        {headCell.label}
                        {order === "desc" ? (
                            <IconChevronDown
                                style={{
                                    visibility: orderBy === headCell.id ? "visible" : "hidden",
                                }}
                            />
                        ) : (
                            <IconChevronUp
                                style={{
                                    visibility: orderBy === headCell.id ? "visible" : "hidden",
                                }}
                            />
                        )}
                    </Group>
                </th>
            ))}
            <th></th>
        </tr>
        </thead>
    );
};

const ControlConfig = () => {
    const {authUser}: RootStateOrAny = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [isAddModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [controlList, setControlList] = useState<TControl[]>([]);
    const [editFormInitialValues, setEditFormInitialValues] =
        useState<TEditControlInitialValue>({
            ctrlName: "",
            ctrlCode: "",
            ctrlId: "",
        });

    // table properties
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("CRUIC_NAME");
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(10);
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // const handleChangeRowsPerPage = (
    //   event: React.ChangeEvent<HTMLInputElement>
    // ) => {
    //   setRowsPerPage(parseInt(event.target.value, 10));
    //   setPage(0);
    // };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - controlList.length) : 0;

    const totalPages = Math.ceil(controlList.length / rowsPerPage);

    const handleEditModalClose = () => {
        setEditModalOpen(false);
    };

    const handleAddModalClose = () => {
        setAddModalOpen(false);
    };

    const getControlList = async () => {
        try {
            setLoading(true);
            const response = await api.get("controls/list");
            if (response.status) {
                setControlList(JSON.parse(response.data.P_RefCur));
            } else {
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: response.message },
                // });
                throw new Error(response.message);
            }
            setLoading(false);
        } catch (error: any) {
            // console.log(error);
            // dispatch({
            //   type: "OPEN_SNACK_TOAST",
            //   payload: { title: "Server Error", message: error.message },
            // });
            if (isOracleError(error.message)) {
                dispatch({
                    type: "OPEN_ALERT_MODAL",
                    payload: {
                        title: "Oracle Error",
                        message: error.message,
                        type: "ORACLE_ERROR",
                    },
                });
            } else {
                showCustomNotification({
                    title: "Error",
                    message: error.message,
                    notifyType: "ERROR",
                });
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        getControlList();
    }, []);

    const handleAddControl = async (values: TEditControlInitialValue) => {
        try {
            setLoading(true);
            const payload = {
                P_UIC_Name: values.ctrlName,
                P_UIC_Code: values.ctrlCode,
                P_Cr_User: authUser.user,
            };
            const response = await api.post("controls/new", undefined, payload);
            if (response.status) {
                // since no CRUIC_ID is returned in response, have to call api to update list
                await getControlList();
                handleAddModalClose();
            } else {
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: response.message },
                // });
                throw new Error(response.message);
            }
            setLoading(false);
        } catch (error: any) {
            // console.log(error);
            // dispatch({
            //   type: "OPEN_SNACK_TOAST",
            //   payload: { title: "Server Error", message: error.message },
            // });
            if (isOracleError(error.message)) {
                dispatch({
                    type: "OPEN_ALERT_MODAL",
                    payload: {
                        title: "Oracle Error",
                        message: error.message,
                        type: "ORACLE_ERROR",
                    },
                });
            } else {
                showCustomNotification({
                    title: "Error",
                    message: error.message,
                    notifyType: "ERROR",
                });
            }

            setLoading(false);
        }
    };

    const handleEditControl = async (formValues: any) => {
        try {
            setLoading(true);
            const payload = {
                P_UIC_Name: formValues.ctrlName,
                P_UIC_Code: formValues.ctrlCode,
                P_Cr_User: authUser.user,
                P_Uic_Id: formValues.ctrlId,
            };
            const response = await api.patch("controls/update", undefined, payload);
            if (response.status) {
                // update edited fields in the controlList state
                const updatedCtrl = {
                    CRUIC_ID: formValues.ctrlId,
                    CRUIC_NAME: formValues.ctrlName,
                    CRUIC_CODE: formValues.ctrlCode,
                };
                const ctrlIndex = controlList.findIndex(
                    (ctrl) => ctrl.CRUIC_ID === formValues.ctrlId
                );
                if (ctrlIndex !== -1) {
                    setControlList((prev) => [
                        ...prev.slice(0, ctrlIndex),
                        updatedCtrl,
                        ...prev.slice(ctrlIndex + 1),
                    ]);
                }
                handleEditModalClose();
            } else {
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: response.message },
                // });
                throw new Error(response.message);
            }
            // else console.error(response);
            setLoading(false);
        } catch (error: any) {
            // dispatch({
            //   type: "OPEN_SNACK_TOAST",
            //   payload: { title: "Server Error", message: error.message },
            // });
            if (isOracleError(error.message)) {
                dispatch({
                    type: "OPEN_ALERT_MODAL",
                    payload: {
                        title: "Oracle Error",
                        message: error.message,
                        type: "ORACLE_ERROR",
                    },
                });
            } else {
                showCustomNotification({
                    title: "Error",
                    message: error.message,
                    notifyType: "ERROR",
                });
            }
            setLoading(false);
            // console.error(error);
        }
    };

    const addValidationSchema = (values: TEditControlInitialValue) => {
        const errors: any = {};
        if (!values.ctrlName) {
            errors.ctrlName = "Required";
        }

        if (!values.ctrlCode) {
            errors.ctrlCode = "Required";
        }
        if (
            controlList.length &&
            controlList.some((ctrl) => ctrl.CRUIC_CODE === values.ctrlCode)
        ) {
            errors.ctrlCode = "Control Code already exists";
        }
        if (
            controlList.length &&
            controlList.some((ctrl) => ctrl.CRUIC_NAME === values.ctrlName)
        ) {
            errors.ctrlName = "Control Name already exists";
        }
        return errors;
    };

    const addFormik = useFormik({
        initialValues: {
            ctrlId: "",
            ctrlName: "",
            ctrlCode: "",
        },
        validate: addValidationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            try {
                await handleAddControl(values);
                setSubmitting(false);
            } catch (err: any) {
                // console.error(err);
                setSubmitting(false);
            }
        },
    });

    const editValidationSchema = (values: TEditControlInitialValue) => {
        const errors: any = {};
        if (!values.ctrlName) {
            errors.ctrlName = "Required";
        }
        if (!values.ctrlCode) {
            errors.ctrlCode = "Required";
        }
        if (
            controlList.length &&
            controlList.some(
                (ctrl) =>
                    ctrl.CRUIC_NAME === values.ctrlName && ctrl.CRUIC_ID !== values.ctrlId
            )
        ) {
            errors.ctrlName = "Control Name already exists";
        }
        if (
            controlList.length &&
            controlList.some(
                (ctrl) =>
                    ctrl.CRUIC_CODE === values.ctrlCode && ctrl.CRUIC_ID !== values.ctrlId
            )
        ) {
            errors.ctrlCode = "Control Code already exists";
        }
        return errors;
    };

    const editFormik = useFormik({
        enableReinitialize: true,
        initialValues: editFormInitialValues,
        validate: editValidationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            try {
                await handleEditControl(values);
                setSubmitting(false);
            } catch (err: any) {
                // console.error(err);
                setSubmitting(false);
            }
        },
    });

    const inputFields = [
        {
            fieldType: "TXT",
            fieldLabel: "Control Name",
            fieldName: "ctrlName",
        },
        {
            fieldType: "TXT",
            fieldLabel: "Control Code",
            fieldName: "ctrlCode",
        },
    ];

    const inputEditFields = [
        {
            fieldType: "TXT",
            fieldLabel: "Control Name",
            fieldName: "ctrlName",
            isReadOnly: false,
        },
        {
            fieldType: "TXT",
            fieldLabel: "Control Code",
            fieldName: "ctrlCode",
            isReadOnly: true,
        },
    ];

    const handleEditControlClick = (row: TControl) => {
        setEditFormInitialValues({
            ctrlName: row.CRUIC_NAME,
            ctrlCode: row.CRUIC_CODE,
            ctrlId: row.CRUIC_ID,
        });
        setEditModalOpen(true);
    };

    const handleDeleteControl = async (row: TControl) => {
        if (row) {
            try {
                setLoading(true);
                const payload = {
                    P_UIC_Name: row.CRUIC_NAME,
                    P_UIC_Code: row.CRUIC_CODE,
                    P_Cr_User: authUser.user,
                    P_Uic_Id: row.CRUIC_ID,
                };
                const response = await api.post("controls/delete", undefined, payload);
                if (response.status) {
                    await getControlList();
                } else {
                    // dispatch({
                    //   type: "OPEN_SNACK_TOAST",
                    //   payload: { title: "Server Error", message: response.message },
                    // });
                    throw new Error(response.message);
                }
                // else console.error(response);
            } catch (error: any) {
                // dispatch({
                //   type: "OPEN_SNACK_TOAST",
                //   payload: { title: "Server Error", message: error.message },
                // });
                if (isOracleError(error.message)) {
                    dispatch({
                        type: "OPEN_ALERT_MODAL",
                        payload: {
                            title: "Oracle Error",
                            message: error.message,
                            type: "ORACLE_ERROR",
                        },
                    });
                } else {
                    showCustomNotification({
                        title: "Error",
                        message: error.message,
                        notifyType: "ERROR",
                    });
                }
                setLoading(false);
                // console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Loader isLoading={isLoading}/>
            <AddControlModal
                isModalOpen={isAddModalOpen}
                inputFields={inputFields}
                handleModalClose={handleAddModalClose}
                formik={addFormik}
                modalTitle="Add Control"
            />
            <EditControlModal
                isModalOpen={isEditModalOpen}
                handleModalClose={handleEditModalClose}
                inputFields={inputEditFields}
                formik={editFormik}
                modalTitle="Edit Control"
            />
            {/* <ConfirmDialog
        isOpen={!!OpenConfirm}
        message="Do you want to delete the control configuration ?"
        onConfirm={() => handleDeleteControl(OpenConfirm)}
        title="Confirm"
      /> */}
            <ModalsProvider>
                <MainCard title="Control Configuration">
                    <Box sx={{width: "100%"}} pt="xl">
                        <Box sx={{display: "flex", justifyContent: "flex-end"}} mb="lg">
                            <Button onClick={() => setAddModalOpen(true)}>Add</Button>
                        </Box>
                        {controlList && (
                            <Paper sx={{width: "100%", mb: 2}}>
                                <Table
                                    mb={10}
                                    striped
                                    horizontalSpacing="md"
                                    verticalSpacing="sm"
                                >
                                    <TableColumnHeaders
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={controlList.length}
                                    />
                                    <tbody>
                                    {controlList
                                        .slice()
                                        .sort(getComparator(order, orderBy))
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            return (
                                                <tr
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                    key={`logTable-${row.CRUIC_ID}`}
                                                >
                                                    {/* <td align="left">{row.CRUIC_ID}</td> */}
                                                    <td align="left">{row.CRUIC_NAME}</td>
                                                    <td align="left">{row.CRUIC_CODE}</td>
                                                    <td align="right">
                                                        <Grid gutter="md">
                                                            <Grid.Col lg={10}>
                                                                <Tooltip label="Edit control">
                                                                    <ActionIcon
                                                                        size="md"
                                                                        onClick={() =>
                                                                            handleEditControlClick(row)
                                                                        }
                                                                    >
                                                                        <IconEdit fontSize="medium"/>
                                                                    </ActionIcon>
                                                                </Tooltip>
                                                            </Grid.Col>
                                                            <Grid.Col lg={2}>
                                                                <Tooltip label="Edit control">
                                                                    <ActionIcon
                                                                        size="md"
                                                                        onClick={() =>
                                                                            openDeleteModal({
                                                                                onConfirm: () =>
                                                                                    handleDeleteControl(row),
                                                                            })
                                                                        }
                                                                    >
                                                                        <IconTrash
                                                                            fontSize="medium"
                                                                            color="#BF181D"
                                                                        />
                                                                    </ActionIcon>
                                                                </Tooltip>
                                                            </Grid.Col>
                                                        </Grid>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <tr
                                            style={{
                                                height: 20 * emptyRows,
                                            }}
                                        >
                                            <td colSpan={5}/>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                <Pagination
                                    position="center"
                                    page={page + 1}
                                    onChange={(page: number) => setPage(page - 1)}
                                    withEdges
                                    total={totalPages}
                                />
                            </Paper>
                        )}
                    </Box>
                </MainCard>
            </ModalsProvider>
        </>
    );
};

export default ControlConfig;

import {Text} from "@mantine/core";
import NavMenuCollapse from "../NavMenuCollapse";
import NavMenuItem from "../NavMenuItem";
import {useSelector} from "react-redux";
import profile from "../../../menu-items/profile";
import {TNavMenu} from "../../../menu-items";

type TProps = { menuType: "main" | "footer" };

const NavMenuList = ({menuType}: TProps) => {
    const {menuState} = useSelector((state: any) => state.menuState);
    let footerNavMenuItems: TNavMenu[] = [profile];

    const NavMenuItems: any = menuType === "main"
        ? {items: menuState}
        : {items: footerNavMenuItems}

    let navItems: any = []
    if (NavMenuItems.items.length) {
        navItems = NavMenuItems.items.map((item: any) => {
            switch (item.type) {
                case "collapse":
                    return <NavMenuCollapse key={item.id} menuItem={item} level={1}/>;
                case "item":
                    return <NavMenuItem key={item.id} menuItem={item} level={1}/>;
                default:
                    return (
                        <Text key={item.id} color="red">
                            Menu Item Error
                        </Text>
                    );
            }
        });
        return <>{navItems}</>;
    } else {
        return <>{navItems}</>;
    }

};

export default NavMenuList;

import Spreadsheet from "react-spreadsheet";
import styles from "../style.module.css";

export function SpreadsheetView(props: any) {
  const keys = Object.keys(props.data[0]).map((item) => ({
    value: item,
    className: styles.header,
  }));
  const mappedDAta = props.data.map((item: any) => {
    return Object.keys(item).map((key) => ({
      value: item[key],
      className: `${styles.content} ${
        item["Error Messages"] === null ? "" : styles.error
      } ${
        key === "Error Messages" && item[key] !== null
          ? styles.errorHeighlight
          : ""
      }`,
    }));
  });
  mappedDAta.unshift(keys);

  return <Spreadsheet data={mappedDAta} />;
}

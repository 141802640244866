import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "@mantine/core";
import { api } from "../../api";
import MainCard from "../../components/Cards/MainCard";
import Loader from "../../components/Loader";
import { isOracleError } from "../../helper/common";
import { showCustomNotification } from "../../helper/customNotification";
import { TUserProfile } from "../../typings";
import UserDetails from "./UserDetails";
import UserImageHeader from "./UserImageHeader";

// export type TUserProfile = {
//   EMP_CODE: string | number | null;
//   EMP_NAME: string | null;
//   DESIG_NAME: string | null;
//   GENDER: string | null;
//   COMP_NAME: string | null;
//   DIVN_NAME: string | null;
//   DEPT_NAME: string | null;
//   EMP_NATIVE_ADDRS1: string | null;
//   EMP_NATIVE_ADDRS2: string | null;
//   EMP_NATIVE_ADDRS3: string | null;
//   EMP_NATIVE_PHONE: number | null;
//   EMP_BLOOD_GROUP: string | null;
//   EMP_ADDRS1: string | null;
//   EMP_ADDRS2: string | null;
//   EMP_ADDRS3: string | null;
//   EMP_GSM: string | null;
//   EMP_EMAIL1: string | null;
//   EMP_BIRTH_DT: string | null;
//   EMP_JOIN_DT: string | null;
//   EMP_REJOIN_DT: string | null;
//   SPONS_NAME: string | null;
//   DOC_TYPE_NAME: string | null;
//   DOC_NO: string | number | null;
//   EXP_DT: string | null;
//   ISS_DT: string | null;
//   PENDING_DAYS: number | null;
//   VALID_DAYS: number | null;
//   EMP_BANK_ACNT_NO: string | number | null;
//   PAY_TYPE: string | null;
//   COUNTRY_NAME: string | null;
//   IMA_IMAGE: string | null;
// };

const UserProfile = () => {
  const { authUser, userProfile } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [userProfileData, setUserProfileData] =
    useState<TUserProfile>(userProfile);
  const [isLoading, setLoading] = useState(false);

  const { EMP_NAME, DESIG_NAME, IMA_IMAGE } = userProfileData.P_EmpProf[0];
  const { DEPT_NAME, EMP_JOIN_DT } = userProfileData.P_EmpOfficalInfo[0];

  useEffect(() => {
    if (!userProfile) {
      const getUserProfile = async () => {
        try {
          setLoading(true);
          const response = await api.get(
            `users/myProfile/${authUser.user_emp_code}`
          );
          if (response.status) {
            setUserProfileData(response.data);
          } else {
            // dispatch({
            //   type: "OPEN_SNACK_TOAST",
            //   payload: { title: "Server Error", message: response.message },
            // });
            throw new Error(response.message);
          }
          setLoading(false);
        } catch (error: any) {
          // console.log(error);
          // dispatch({
          //   type: "OPEN_SNACK_TOAST",
          //   payload: { title: "Server Error", message: error.message },
          // });
          if (isOracleError(error.message)) {
            dispatch({
              type: "OPEN_ALERT_MODAL",
              payload: {
                title: "Oracle Error",
                message: error.message,
                type: "ORACLE_ERROR",
              },
            });
          } else {
            showCustomNotification({
              title: "Error",
              message: error.message,
              notifyType: "ERROR",
            });
          }
          setLoading(false);
        }
      };

      getUserProfile();
    }
  }, [authUser.user_emp_code, dispatch, userProfile]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <MainCard title="My Profile" titleOrder={2}>
        {userProfileData ? (
          <>
            <UserImageHeader
              name={EMP_NAME}
              designation={DESIG_NAME}
              department={DEPT_NAME}
              joinedDate={EMP_JOIN_DT}
              imageUrl={IMA_IMAGE}
            />
            <UserDetails userProfile={userProfileData} />
          </>
        ) : (
          <Text
            align="center"
            sx={(theme) => ({
              color: isLoading ? theme.colors.ffcBrand[6] : theme.other.danger,
            })}
          >
            {isLoading ? "Fetching user data..." : "No user profile found"}
          </Text>
        )}
      </MainCard>
    </>
  );
};

export default UserProfile;

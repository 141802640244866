import React from 'react'
import PropTypes from 'prop-types'

// React Icons
import {IconDashboard} from "@tabler/icons";
import {IconReportMoney, IconTableImport} from "@tabler/icons";
import {IconAdjustmentsAlt, IconForms, IconTool} from "@tabler/icons";
import {IconCalendarTime} from "@tabler/icons";
import {IconReportAnalytics, IconFileAnalytics} from "@tabler/icons";
import {IconFileUpload, IconReceipt2, IconUsers} from "@tabler/icons";
import {IconUser} from "@tabler/icons";
import {IconFileDownload} from "@tabler/icons";
import {IconList} from "@tabler/icons";

const IconRetrieve = (icon: any) => {
    let arr = [
        {name: "IconDashboard", value: <IconDashboard/>,},
        {name: "IconReportMoney", value: <IconReportMoney/>},
        {name: "IconTableImport", value: <IconTableImport/>},
        {name: "IconAdjustmentsAlt", value: <IconAdjustmentsAlt/>},
        {name: "IconForms", value: <IconForms/>},
        {name: "IconTool", value: <IconTool/>},
        {name: "IconCalendarTime", value: <IconCalendarTime/>},
        {name: "IconReportAnalytics", value: <IconReportAnalytics/>},
        {name: "IconFileAnalytics", value: <IconFileAnalytics/>},
        {name: "IconFileUpload", value: <IconFileUpload/>},
        {name: "IconReceipt2", value: <IconReceipt2/>},
        {name: "IconUsers", value: <IconUsers/>},
        {name: "IconUser", value: <IconUser/>},
        {name: "IconFileDownload", value: <IconFileDownload/>},
        {name: "IconList", value: <IconList/>},
    ];
    let obj = arr.find(o => o.name === icon['icon']);
    return (<> {obj ? obj['value'] : <IconList/>} </>)
}

IconRetrieve.propTypes = {
    icon: PropTypes.string.isRequired,
}

export default IconRetrieve

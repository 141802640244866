import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TReportHeaderInputFieldValues,
  TReportTypeConfig,
  TReportTypeValues,
  TWizardData,
} from "../../typings";
import {stringifyParamValues } from "../../helper";
import { api } from "../../../../../api";
import { reportHeaderInitialValues } from "../../Formik/FormikData";
import {
  ActionIcon,
  Button,
  Grid,
  Group,
  Paper,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import {
  reportHeaderInputFields,
  reportHeaderInputFieldValues,
} from "../../Formik/FormFields";
import ParamInputCard from "../../components/ParamInputCard";
import {
  generateRandomString,
  isOracleError,
} from "../../../../../helper/common";
import {
  IconArrowLeft,
  IconCirclePlus,
  IconDeviceFloppy,
  IconCornerDownRight,
  IconTrash,
  IconCircleX,
} from "@tabler/icons";
import { showCustomNotification } from "../../../../../helper/customNotification";
import { useQueryClient } from "@tanstack/react-query";

type TProps = {
  authUser: any;
  prev: () => void;
  handleWizardClose: () => void;
  selectedReportType: TReportTypeValues | undefined;
  wizardData: TWizardData;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // setReportList: React.Dispatch<React.SetStateAction<TReportTypeConfig[]>>;
};

const StepReportHeader = ({
  authUser,
  prev,
  handleWizardClose,
  wizardData,
  selectedReportType,
  setLoading,
}: TProps) => {
  const [outParamJsonValue, setOutParamJsonValue] = useState<string>("");
  const [paramCards, setParamCards] = useState<
    Record<string, TReportHeaderInputFieldValues>
  >({});
  const [paramCardValues, setParamCardValues] = useState<
    Record<string, TReportHeaderInputFieldValues>
  >({});
  const [triggerFieldTouch, setTriggerFieldTouch] = useState(false);
  const [isFormValid, setIsFormValid] = useState<Record<string, boolean>>({});
  const [validateParam, setValidateParam] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  //  Generate final Param Json Value from user inputs excluding empty fields
  useEffect(() => {
    const Json_Data = stringifyParamValues(paramCardValues, "OutParam");
    setOutParamJsonValue(Json_Data);
  }, [paramCardValues, setOutParamJsonValue]);

  const handleAddOutParams = async () => {
    try {
      setTriggerFieldTouch(true);

      setLoading(true);
      if (Object.values(isFormValid).some((valid) => !valid)) {
        setLoading(false);
        return;
      }
      const outParamJson = stringifyParamValues(paramCardValues, "OutParam");
      if (selectedReportType) {
        const payload = {
          P_Cr_User: authUser.user,
          reportId: selectedReportType.reportId,
          reportOutParams: outParamJson,
        };
        const response = await api.post(
          "customReport/outParams/create",
          undefined,
          payload
        );
        // console.log(response);
        if (!response.status) {
          throw new Error(response.message);
        }
        queryClient.setQueryData<TReportTypeConfig>(
          ["uiConfig", "reportType", selectedReportType.reportId],
          (oldData) => {
            if (!oldData)
              return {
                reportParams: [],
                reportSubParams: null,
                reportOutParams: response.result,
              };
            return {
              ...oldData,
              reportOutParams: response.result,
            };
          }
        );
        handleWizardClose();
      }
    } catch (error: any) {
      if (isOracleError(error.message)) {
        dispatch({
          type: "OPEN_ALERT_MODAL",
          payload: {
            title: "Oracle Error",
            message: error.message,
            type: "ORACLE_ERROR",
          },
        });
      } else {
        showCustomNotification({
          title: "Error",
          message: error.message,
          notifyType: "ERROR",
        });
      }
      setLoading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    setParamCardValues({});
    if (!Object.keys(wizardData.reportOutParams).length) {
      setIsFormValid({ randomKey72rh: false });
      setParamCards(reportHeaderInitialValues);
    } else {
      const errorValues = Object.keys(wizardData.reportOutParams).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      );
      setIsFormValid(errorValues);
      setParamCards(wizardData.reportOutParams);
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  const reportHeaderValidationSchema = useCallback(
    (values: TReportHeaderInputFieldValues) => {
      let errors: any = {};

      if (!values.oparam) errors = { ...errors, oparam: "Required" };
      else {
        const paramValues = Object.values(paramCardValues).filter(
          (val) => val.oparam === values.oparam
        );
        if (paramValues.length > 1)
          errors = { ...errors, oparam: "must be unique" };
      }

      if (!values.oorder) errors = { ...errors, oorder: "Required" };
      else if (!/^\d+$/.test(values.oorder.toString())) {
        errors = {
          ...errors,
          oorder: "must specify a number",
        };
      } else {
        const paramValues = Object.values(paramCardValues).filter(
          (val) => val.oorder + "" === values.oorder + ""
        );
        // console.log(paramValues);
        if (paramValues.length > 1)
          errors = { ...errors, oorder: "must be unique" };
      }

      if (!values.odata_type) errors = { ...errors, odata_type: "Required" };
      if (!values.olabel) errors = { ...errors, olabel: "Required" };

      return errors;
    },
    [validateParam]
  );

  return (
    <Grid gutter="sm">
      <Grid.Col xs={12} my={2} px={2}>
        <Text align="center">
          Here you can enter the column headers for the report table.
        </Text>
      </Grid.Col>
      {/* {selectedReportType && (
        <Grid.Col xs={12} sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="outline"
            onClick={() => {
              handleWizardClose();
            }}
          >
            Skip
          </Button>
        </Grid.Col>
      )} */}
      {!!Object.keys(paramCards).length &&
        Object.keys(paramCards).map((paramKey) => (
          <Grid.Col xs={11.5} key={paramKey}>
            <Paper shadow="sm" p="md">
              <Grid gutter="sm">
                <ParamInputCard
                  setParamCardValues={setParamCardValues}
                  paramKey={paramKey}
                  paramCardValues={paramCards[paramKey]}
                  validation={reportHeaderValidationSchema}
                  inputFields={reportHeaderInputFields}
                  paramType="OutParam"
                  setIsFormValid={setIsFormValid}
                  triggerFieldTouch={triggerFieldTouch}
                  setTriggerFieldTouch={setTriggerFieldTouch}
                  validateParam={validateParam}
                  setValidateParam={setValidateParam}
                />
                <Grid.Col
                  xs={1}
                  sx={{
                    display: "flex",
                    alignItems: "end",
                  }}
                  pb="sm"
                >
                  <Tooltip label="Delete field">
                    <ActionIcon
                      size="md"
                      onClick={() => {
                        const { [paramKey]: param, ...newParams } = paramCards;
                        setParamCards(newParams);
                        const { [paramKey]: paramVal, ...newParamVals } =
                          paramCardValues;
                        setParamCardValues(newParamVals);
                        const {
                          [paramKey]: paramFormValid,
                          ...newParamFormValid
                        } = isFormValid;
                        setIsFormValid(newParamFormValid);
                      }}
                      disabled={
                        Object.keys(paramCards).length === 1 ||
                        !!paramCards[paramKey].oparam_id
                      }
                    >
                      <IconTrash fontSize="medium" />
                    </ActionIcon>
                  </Tooltip>
                </Grid.Col>
              </Grid>
            </Paper>
          </Grid.Col>
        ))}
      <Grid.Col xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip label="Add field">
          <ActionIcon
            size="md"
            onClick={() => {
              const id = generateRandomString(6);
              setParamCards((prev) => ({
                ...prev,
                [id]: reportHeaderInputFieldValues,
              }));
              setIsFormValid((prev) => ({
                ...prev,
                [id]: false,
              }));
            }}
          >
            <IconCirclePlus fontSize="medium" />
          </ActionIcon>
        </Tooltip>
      </Grid.Col>
      <Grid.Col xs={12} mt={2}>
        <Textarea
          id="read-only-out-params-textfield"
          label="Preview in Json format"
          value={outParamJsonValue}
          maxRows={5}
          readOnly
        />
      </Grid.Col>
      {/* <Grid.Col xs={1} display="flex" justifyContent="center">
        <IconButton
          size="large"
          color="primary"
          aria-label="refresh field"
          onClick={() => {
            const Json_Data = stringifyParamValues(formik.values, "OutParam");
            setOutParamJsonValue(Json_Data);
          }}
        >
          <RefreshIcon fontSize="inherit" />
        </IconButton>
      </Grid.Col> */}
      <Grid.Col xs={12} mt={1}>
        <Group spacing="sm" position="right">
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconArrowLeft />}
            onClick={() => {
              // if (
              //   Object.values(isFormValid).some((valid) => !valid) &&
              //   Object.keys(paramCards).length !== 1
              // ) {
              //   setTriggerFieldTouch(true);
              //   setLoading(false);
              //   return;
              // } else if (Object.values(isFormValid).every((valid) => valid))
              //   setWizardData((prev) => ({
              //     ...prev,
              //     reportOutParams: paramCardValues,
              //   }));
              prev();
            }}
          >
            Back
          </Button>
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconCornerDownRight />}
            onClick={() => {
              setParamCards({});
              setParamCardValues({});
              setIsFormValid({});
              setTimeout(() => {
                if (!Object.keys(wizardData.reportOutParams).length) {
                  setIsFormValid({ randomKey72rh: false });
                  setParamCards(reportHeaderInitialValues);
                } else {
                  const errorValues = Object.keys(
                    wizardData.reportOutParams
                  ).reduce((acc, key) => ({ ...acc, [key]: false }), {});
                  setIsFormValid(errorValues);
                  setParamCards(wizardData.reportOutParams);
                }
              }, 0);
            }}
          >
            Reset
          </Button>
          <Button
            variant="outline"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            leftIcon={<IconCircleX />}
            onClick={() => {
              handleWizardClose();
            }}
          >
            Cancel
          </Button>
          <Button
            leftIcon={<IconDeviceFloppy />}
            variant="filled"
            fullWidth
            sx={{ maxWidth: "12.5rem" }}
            onClick={handleAddOutParams}
          >
            Save
          </Button>
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default StepReportHeader;

import { api } from "../../api";
import {ValidatedResponseProps } from "./types";

export async function uploadExcelForValidation(request: FormData) {
  try {
      return await Promise.resolve(  api.post("purchase/upload/excel", undefined, request));
  } catch (error) {
    return error;
  }
}

export async function getValidatedData(
  fileRefId: string
): Promise<ValidatedResponseProps> {
  try {
      return await Promise.resolve(   api.get(`purchase/${fileRefId}`));

  } catch (error) {
    throw error;
  }
}

export async function uploadPurchaseEntry(refId: string) {
  try {
      return await Promise.resolve( api.post(`purchase/${refId}`, undefined, {}));
  } catch (error) {
    return error;
  }
}


export async function getDetails(purchase_id: string) {
    try {
        return await Promise.resolve( api.post(`purchase/getDetails`, undefined, {purchase_id:purchase_id}));
    } catch (error) {
        return error;
    }
}

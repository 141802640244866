import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ffcLogo from "../../../assets/images/logo/ffclog1.png";
import Barcode from "react-barcode";
import PayTable from "./PayTable";
import Html2Pdf from "js-html2pdf";
import { api } from "../../../api";
import dayjs from "dayjs";
import {
  ActionIcon,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import Loader from "../../../components/Loader";
import MainCard from "../../../components/Cards/MainCard";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconCalendar, IconFileDownload } from "@tabler/icons";
import { showCustomNotification } from "../../../helper/customNotification";
import { isOracleError } from "../../../helper/common";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TUserProfile } from "../../../typings";

type TPayDetail = {};

const Payslip = () => {
  const theme = useMantineTheme();
  const { authUser, userProfile } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [payDetails, setPayDetails] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [payMonth, setPayMonth] = useState<Date | null>(new Date());
  const [payMonthPicker, setPayMonthPicker] = useState<Date | null>(new Date());
  const [userProfileData, setUserProfileData] =
    useState<TUserProfile>(userProfile);
  const paySlipRef = useRef<any>();

  useEffect(() => {
    if (!userProfile) {
      const getUserProfile = async () => {
        try {
          setLoading(true);
          const response = await api.get(
            `users/myProfile/${authUser.user_emp_code}`
          );
          if (response.status) {
            setUserProfileData(response.data);
          } else {
            // dispatch({
            //   type: "OPEN_SNACK_TOAST",
            //   payload: { title: "Server Error", message: response.message },
            // });
            throw new Error(response.message);
          }
          setLoading(false);
        } catch (error: any) {
          // console.log(error);
          // dispatch({
          //   type: "OPEN_SNACK_TOAST",
          //   payload: { title: "Server Error", message: error.message },
          // });
          if (isOracleError(error.message)) {
            dispatch({
              type: "OPEN_ALERT_MODAL",
              payload: {
                title: "Oracle Error",
                message: error.message,
                type: "ORACLE_ERROR",
              },
            });
          } else {
            showCustomNotification({
              title: "Error",
              message: error.message,
              notifyType: "ERROR",
            });
          }
          setLoading(false);
        }
      };

      getUserProfile();
    }
  }, [authUser.user_emp_code, userProfile]);

  useEffect(() => {
    const getPayDetails = async () => {
      try {
        setLoading(true);
        const month = dayjs(payMonth).format("MM");
        const year = dayjs(payMonth).format("YYYY");
        // const response = await api.get(
        //   `users/payslip/${authUser.user_emp_code}/2020/04`
        // );

        const response = await api.get(
          `users/payslip/${authUser.user_emp_code}/${year}/${month}`
        );
        if (response.status) {
          setPayDetails(JSON.parse(response.data));
        } else {
          // dispatch({
          //   type: "OPEN_SNACK_TOAST",
          //   payload: { title: "Server Error", message: response.message },
          // });
          throw new Error(response.message);
        }
        setLoading(false);
      } catch (error: any) {
        // dispatch({
        //   type: "OPEN_SNACK_TOAST",
        //   payload: { title: "Server Error", message: error.message },
        // });
        if (isOracleError(error.message)) {
          dispatch({
            type: "OPEN_ALERT_MODAL",
            payload: {
              title: "Oracle Error",
              message: error.message,
              type: "ORACLE_ERROR",
            },
          });
        } else {
          showCustomNotification({
            title: "Error",
            message: error.message,
            notifyType: "ERROR",
          });
        }

        setLoading(false);
      }
    };
    if (payMonth) getPayDetails();
  }, [authUser.user_emp_code, dispatch, payMonth]);

  const handleDownloadPayslip = async () => {
    const exporter = new Html2Pdf(paySlipRef.current, {
      margin: [20, 7, 20, 7],
      filename: `${dayjs(payMonth).format("MMM_YYYY")}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2,
        logging: false,
        dpi: 192,
        letterRendering: true,
      },
      jsPDF: { unit: "mm", format: "letter", orientation: "portrait" },
    });
    await exporter.getPdf(true);
  };

  const employeeDetails = !!payDetails?.length &&
    !!userProfileData &&
    !!Object.keys(userProfileData)?.length && (
      <Stack>
        <Box>
          <img src={ffcLogo} alt="FFC Logo" />
          <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[6] })}>
            Company: {authUser.company_code}, Division:{" "}
            {payDetails[0].PAYH_DIVN_CODE}
          </Text>
          <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[6] })}>
            P.O Box 5683, Doha-Qatar
          </Text>
          <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[6] })}>
            TEL:4422456, FAX:44427428
          </Text>
          <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[6] })}>
            Email:accounts@family.com.qa
          </Text>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Text component="span" mr={1} weight={500}>
              Division
            </Text>
            <Text size="sm" component="span">
              : {payDetails[0].DIVN_NAME}
            </Text>
          </Box>
          <Box>
            <Text component="span" mr={1} weight={500}>
              Department
            </Text>
            <Text size="sm" component="span">
              : {userProfileData.P_EmpOfficalInfo[0].DEPT_NAME}
            </Text>
          </Box>
          <Box>
            <Text component="span" mr={1} weight={500}>
              Employee
            </Text>
            <Text size="sm" component="span">
              : {userProfileData.P_EmpProf[0].EMP_CODE}
            </Text>
          </Box>
          <Box>
            <Text component="span" mr={1} weight={500}>
              Designation
            </Text>
            <Text size="sm" component="span">
              : {userProfileData.P_EmpProf[0].DESIG_NAME}
            </Text>
          </Box>
          <Box>
            <Text component="span" mr={1} weight={500}>
              Period
            </Text>
            <Text size="sm" component="span">
              :{" "}
              {`${dayjs(payMonth).format("YYYY")}-${dayjs(payMonth).format(
                "MM"
              )}`}
            </Text>
          </Box>
          <Box>
            <Text component="span" mr={1} weight={500}>
              Sponsor
            </Text>
            <Text size="sm" component="span">
              :-{/* : {userProfileData.SPONS_NAME} */}
            </Text>
          </Box>
          <Box>
            <Text component="span" mr={1} weight={500}>
              IBAN No
            </Text>
            <Text size="sm" component="span">
              :-{/* : {userProfileData.EMP_BANK_ACNT_NO} */}
            </Text>
          </Box>
        </Box>
      </Stack>
    );

  const barcodeDetails = !!payDetails?.length && (
    <Stack spacing="xl" align="flex-end">
      <Barcode
        value={payDetails[0].PAYH_BARCODE ?? "NO-BARCODE-FOUND"}
        format="CODE128"
        height={60}
      />
      <Stack
        spacing="sm"
        sx={{ width: "100%", textAlign: "right" }}
        align="end"
      >
        <Text inline weight={500}>
          Doc : {payDetails[0].PAYH_NO}
        </Text>
        <Text inline weight={500}>
          Date : {dayjs(payDetails[0].PAYH_DT).format("DD/MM/YY")}
        </Text>
        <Text inline weight={500}>
          Pay Type : {payDetails[0].PAY_TYPE}
        </Text>
        <Text inline weight={500}>
          No of Working Days : {payDetails[0].PAYD_DAYS}
        </Text>
      </Stack>
    </Stack>
  );
  return (
    <>
      <Loader isLoading={isLoading} />

      <MainCard maxWidth={theme.breakpoints.md}>
        <Stack align="center" justify="center">
          <Box
            sx={(theme) => ({
              width: "100%",
              maxWidth: theme.breakpoints.md,
              display: "flex",
              justifyContent: "space-between",
            })}
            mb={2}
          >
            <ThemeProvider theme={createTheme({})}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  views={["year", "month"]}
                  label="Month and Year"
                  // minDate={new Date("2012-03-01")}
                  // maxDate={new Date()}
                  value={payMonthPicker}
                  onAccept={(newValue) => {
                    setPayMonthPicker(newValue);
                    setPayMonth(newValue);
                  }}
                  onChange={(newValue) => {
                    setPayMonthPicker(newValue);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => {
                    return (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextInput
                          ref={inputRef}
                          {...inputProps}
                          icon={
                            <IconCalendar
                              size={20}
                              color={theme.colors.ffcBrand[6]}
                            />
                          }
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    );
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
            {!!payDetails?.length &&
              !!userProfileData &&
              !!Object.keys(userProfileData)?.length && (
                <Tooltip label="Download pdf">
                  <ActionIcon size="lg" onClick={handleDownloadPayslip}>
                    <IconFileDownload fontSize="medium" />
                  </ActionIcon>
                </Tooltip>
              )}
          </Box>
          {!!userProfileData &&
          !!Object.keys(userProfileData)?.length &&
          !!payDetails?.length ? (
            <Box ref={paySlipRef}>
              <Grid
                gutter="md"
                sx={(theme) => ({ maxWidth: theme.breakpoints.md })}
                px={5}
                pt={2}
              >
                <Grid.Col xs={12}>
                  <Title mb={10} align="center" order={1}>
                    PAY SLIP
                  </Title>
                </Grid.Col>
                <Grid.Col xs={6}>{employeeDetails}</Grid.Col>
                <Grid.Col xs={6}>{barcodeDetails}</Grid.Col>
                <Grid.Col xs={12}>
                  <PayTable payDetails={payDetails} />
                </Grid.Col>
                <Grid.Col xs={12}>
                  <Divider my="sm" />
                  <Stack>
                    <Text size="sm" my={2}>
                      This document is computer generated and does not require
                      the signature or the Company's stamp in order to be
                      considered valid
                    </Text>
                    <Text size="sm" weight={500} align="right">
                      Created on : {dayjs().format("DD/MMM/YYYY h:mm:ss A")}
                    </Text>
                    <Text weight={600} align="right">
                      {userProfileData.P_EmpProf[0].EMP_NAME}
                    </Text>
                  </Stack>
                </Grid.Col>
              </Grid>
            </Box>
          ) : (
            <Paper
              shadow="sm"
              py="xl"
              mt={10}
              sx={{
                width: "100%",
                minHeight: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Title
                order={4}
                sx={(theme) => ({
                  color: isLoading
                    ? theme.colors.ffcBrand[6]
                    : theme.other.danger,
                })}
              >
                {isLoading
                  ? "Fetching Data..."
                  : !!userProfileData && !Object.keys(userProfileData)?.length
                  ? "No Profile data found"
                  : "No Payslip found"}
              </Title>
            </Paper>
          )}
        </Stack>
      </MainCard>
    </>
  );
};

export default Payslip;

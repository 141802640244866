import { Select } from "@mantine/core";

type SelectItem = {
  value: string;
  label?: string;
  disabled?: boolean;
  group?: string;
  [key: string]: any;
};

const SelectDropdown = ({
  id,
  formik,
  options,
  isClearable = true,
  ...props
}: {
  id: string;
  formik: any;
  label?: string;
  disabled?: boolean;
  isClearable?: boolean;
  options: (string | SelectItem)[] | null;
  onChange?: (value: string | null) => void;
  // setValue: any;
}) => {
  return (
    <Select
      data={options ?? []}
      value={formik.values[id]}
      onChange={(value) => formik.setFieldValue(id, value)}
      onBlur={formik.handleBlur}
      error={!!(formik.touched[id] && formik.errors[id]) && formik.errors[id]}
      clearable={isClearable}
      nothingFound="No options"
      maxDropdownHeight={280}
      {...props}
    />
  );
};

export default SelectDropdown;

import {useState} from "react";
import {
    ThemeIcon,
    UnstyledButton,
    Group,
    Text,
    useMantineTheme,
    Collapse,
} from "@mantine/core";
import {IconChevronUp, IconChevronDown} from "@tabler/icons";
import {TNavMenu} from "../../../menu-items";
import NavMenuItem from "../NavMenuItem";
import {useLocation} from "react-router-dom";
import IconRetrieve from "../../../components/MenuIcons/IconRetrieve"

const NavMenuCollapse = ({
                             menuItem,
                             level,
                         }: {
    menuItem: TNavMenu;
    level: number;
}) => {
    const [isGroupOpened, setGroupOpened] = useState(false);
    const theme = useMantineTheme();
    const Icon = menuItem.icon;
    const location = useLocation();

    const isNavChildSelected = menuItem.children.some(
        (child) => child.url === location.pathname
    );

    const navItems = menuItem.children?.map((item) => {
        switch (item.type) {
            case "collapse":
                return (
                    <NavMenuCollapse key={item.id} menuItem={item} level={level + 1}/>
                );
            case "item":
                return <NavMenuItem key={item.id} menuItem={item} level={level + 1}/>;
            default:
                return (
                    <Text key={item.id} color="red">
                        Menu Item Error
                    </Text>
                );
        }
    });

    return (
        <>
            <UnstyledButton
                sx={{
                    display: "block",
                    width: "100%",
                    padding: theme.spacing.xs,
                    // borderRadius: theme.radius.sm,
                    color: theme.white,
                    paddingLeft: `${level * theme.spacing.sm}px`,
                    backgroundColor: isNavChildSelected
                        ? theme.colors.indigo[6]
                        : theme.colors.ffcBrand[7],
                    "&:hover": {
                        backgroundColor: theme.colors.indigo[8],
                    },
                }}
                onClick={() => setGroupOpened((prev) => !prev)}
            >
                <Group position="apart">
                    <Group>
                        <ThemeIcon>
                            {/*<Icon size={20} />*/}
                            <IconRetrieve icon={menuItem.icon}/>
                        </ThemeIcon>
                        <div style={{display: "inline", fontSize: 14}}>
                            {menuItem.title}
                        </div>
                    </Group>
                    {/* <ThemeIcon size={18} color={theme.white} variant="light"> */}
                    {isGroupOpened ? (
                        <IconChevronUp color={theme.white}/>
                    ) : (
                        <IconChevronDown color={theme.white}/>
                    )}
                    {/* </ThemeIcon> */}
                </Group>
            </UnstyledButton>
            <Collapse
                in={isGroupOpened}
                transitionDuration={250}
                transitionTimingFunction="linear"
            >
                {navItems}
            </Collapse>
        </>
    );
};

export default NavMenuCollapse;

import { useEffect, useState } from "react";
import {
  ActionIcon,
  Center,
  Group,
  Menu,
  Pagination,
  Paper,
  Table,
} from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  IconDownload,
  IconFileText,
} from "@tabler/icons";
import dayjs from "dayjs";
import { generateRandomString } from "../../../helper/common";
import { TDocsToGenerate, TReportLog } from "../typings";

type Order = "asc" | "desc";
type TableColumnHeadersProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
};

type HeadCell = {
  id: string;
  label: string;
};

type TProps = {
  selectedReport: string;
  reportLogs: TReportLog[];
  setDocsToGenerate: React.Dispatch<React.SetStateAction<TDocsToGenerate[]>>;
};

const headCells: HeadCell[] = [
  {
    id: "CREP_NAME",
    label: "Report Name",
  },
  {
    id: "CRLOG_CR_USER",
    label: "Description",
  },
  {
    id: "CRLOG_CR_DT",
    label: "Date Created",
  },
];

const DownloadMenu = ({
  log,
  setDocsToGenerate,
}: {
  log: TReportLog;
  setDocsToGenerate: React.Dispatch<React.SetStateAction<TDocsToGenerate[]>>;
}) => {
  // const queryClient = useQueryClient();
  // const id = `${log.CREP_ID}-${log.CRLOG_CR_USER}-${log.CRLOG_CR_DT}-${log.CRLOG_BIND_PARAM}-pdf`;
  // const {
  //   isLoading,
  //   isFetching,
  //   data: fileLink,
  // } = useQuery<any, Error>(
  //   ["reportLogs-download", id],
  //   () => getFileLink({ doc:{...log,isPdf:true}, dispatch, authUser }),
  //   {
  //     // select: (data) => {
  //     //   console.log("select", data);
  //     //   return data;
  //     // },
  //     onError: (error) => {
  //       dispatch({
  //         type: "OPEN_SNACK_TOAST",
  //         payload: { title: "Server Error", message: error.message },
  //       });
  //     },
  //   }
  // );
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon>
          <IconDownload size={24} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          onClick={async () => {
            // const docLink = queryClient.getQueryData([
            //   "report-logs-download",
            //   id,
            // ]);
            // if (fileLink) {
            //   await downloadLink(fileLink as string);
            //   return;
            // }
            const id = generateRandomString(6);
            setDocsToGenerate((prev) => [
              ...prev,
              { ...log, docId: id, isPdf: true },
            ]);
          }}
          icon={<IconFileText size={20} />}
        >
          PDF
        </Menu.Item>
        <Menu.Item
          onClick={async () => {
            // const id = `${log.CREP_ID}-${log.CRLOG_CR_USER}-${log.CRLOG_CR_DT}-${log.CRLOG_BIND_PARAM}-excel`;
            // const docLink = queryClient.getQueryData([
            //   "report-logs-download",
            //   id,
            // ]);
            // if (docLink) {
            //   await downloadLink(docLink as string);
            //   return;
            // }
            const id = generateRandomString(6);
            setDocsToGenerate((prev) => [
              ...prev,
              { ...log, docId: id, isPdf: false },
            ]);
          }}
          icon={<IconFileText size={20} />}
        >
          Excel
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const TableColumnHeaders = (props: TableColumnHeadersProps) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  // const createSortHandler =
  //   (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
  //     onRequestSort(event, property);
  //   };

  return (
    <thead>
      <tr
        style={{
          backgroundColor: "#2e439e",
        }}
      >
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            align="left"
            style={{ color: "#fff" }}
            // align={headCell.numeric ? "right" : "left"}
          >
            <Group spacing="xs" onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {order === "desc" ? (
                <IconChevronDown
                  style={{
                    visibility: orderBy === headCell.id ? "visible" : "hidden",
                  }}
                />
              ) : (
                <IconChevronUp
                  style={{
                    visibility: orderBy === headCell.id ? "visible" : "hidden",
                  }}
                />
              )}
            </Group>
          </th>
        ))}
        <th></th>
      </tr>
    </thead>
  );
};

const LogTable = ({
  selectedReport,
  reportLogs,
  setDocsToGenerate,
}: TProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState("CRLOG_CR_DT");
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [filteredReportLogs, setFilteredReportLogs] = useState<TReportLog[]>(
    []
  );

  useEffect(() => {
    if (selectedReport) {
      setFilteredReportLogs(
        reportLogs.filter((log) => log.CREP_NAME === selectedReport)
      );
    } else setFilteredReportLogs(reportLogs);
  }, [reportLogs, selectedReport]);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string | any },
    b: { [key in Key]: number | string | any }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredReportLogs.length)
      : 0;

  const totalPages = Math.ceil(filteredReportLogs.length / rowsPerPage);

  return (
    <Center>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Table mb={10} striped horizontalSpacing="md" verticalSpacing="sm">
          <TableColumnHeaders
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredReportLogs.length}
          />
          <tbody>
            {filteredReportLogs
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <tr
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                    key={`logTable-${row.CRLOG_CR_DT}`}
                  >
                    <td align="left">{row.CREP_NAME}</td>
                    <td align="left">by {row.CRLOG_CR_USER}</td>
                    <td align="left">
                      {dayjs(row.CRLOG_CR_DT).format("DD-MMM-YYYY h:mm:ss A")}
                    </td>
                    <td align="right">
                      <DownloadMenu
                        log={row}
                        setDocsToGenerate={setDocsToGenerate}
                      />
                    </td>
                  </tr>
                );
              })}
            {emptyRows > 0 && (
              <tr
                style={{
                  height: 20 * emptyRows,
                }}
              >
                <td colSpan={5} />
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          position="center"
          page={page + 1}
          onChange={(page: number) => setPage(page - 1)}
          withEdges
          total={totalPages}
        />
      </Paper>
    </Center>
  );
};

export default LogTable;

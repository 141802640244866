import { Anchor, Group } from "@mantine/core";

const AuthFooter = () => (
  <Group position="apart">
    <Anchor href="https://ffc" target="_blank" variant="text">
      &copy; ffc@2022
    </Anchor>
  </Group>
);

export default AuthFooter;

import { TReport } from ".";
import { Box, Text, ThemeIcon } from "@mantine/core";
import DownloadAnimation from "../../../components/DownloadAnimation";
import { IconCircleCheck, IconCircleX } from "@tabler/icons";

const HistoryListItem = ({ report }: { report: TReport }) => {
  // // dispatch a state to know whether msg displayed or not after toast is called and call toast only if msg is not displayed (mantine ui drawer) or give inside dispatch of generation call for success or failed
  // useEffect(() => {
  //   if (report.status !== "inProgress") {
  //     const message =
  //       report.status === "success"
  //         ? `Successfully generated ${report.reportName}`
  //         : `${report.reportName} generate error - ${report.message}`;
  //     toast[report.status](message);
  //   }
  // }, [report.message, report.reportName, report.status]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "1px solid #AEAEAE",
        paddingBottom: 5,
      }}
    >
      {report.status === "inProgress" ? (
        <ThemeIcon>
          <DownloadAnimation />
        </ThemeIcon>
      ) : report.status === "success" ? (
        <ThemeIcon variant="light" radius="xl" size="lg">
          <IconCircleCheck color="green" />
        </ThemeIcon>
      ) : (
        <ThemeIcon variant="light" radius="xl">
          <IconCircleX color="red" />
        </ThemeIcon>
      )}
      <Box ml="sm">
        <Text>{report.reportName}</Text>
        <Text size="xs">{report.reportDetails}</Text>
      </Box>
    </Box>
  );
};

export default HistoryListItem;

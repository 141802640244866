import {
    Box,
    Button,
    Grid,
    Group,
    Loader,
    LoadingOverlay,
    Text,Card,
    Title, Paper
} from "@mantine/core";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useEffect, useState, useMemo} from "react";

import MainCard from "../../../components/Cards/MainCard";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';

type PurropsTypes = {
    finHead: any;
    finDetails: any;
    downloadLink: any;
};

function FinanceDetails({finHead, finDetails,downloadLink}: PurropsTypes) {

    const handleDownload = () => {
        const fileName: any = downloadLink.split("/").pop();
        fetch(downloadLink).then((resp) => {
            resp.blob().then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            });
        });
        return;
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Main Account',
                header: 'Main Account',
                Cell: ({cell, table, row}) => {
                    var Main_Account: any = row.original['Main Account'] ? row.original['Main Account']  : ''
                    return (<div>
                        {Main_Account && (<Text fw={500}>{Main_Account}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'Division',
                header: 'Division',
                Cell: ({cell, table, row}) => {
                    var Division: any = row.original.Division ? row.original.Division : '-'
                    return (<div>
                        {Division && (
                            <Text fw={500}>{Division}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'Department',
                header: 'Department',
                Cell: ({cell, table, row}) => {
                    var Department: any = row.original.Department ? row.original.Department : '-'
                    return (<div>
                        {Department && (
                            <Text fw={500}>{Department}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'Analysis',
                header: 'Analysis',
                Cell: ({cell, table, row}) => {
                    var Analysis: any = row.original.Analysis ? row.original.Analysis : '-'
                    return (<div>
                        {Analysis && (
                            <Text fw={500}>{Analysis ? Analysis : '-'}</Text>)}
                    </div>)
                },
            },

            {
                accessorKey: 'DR/CR?',
                header: 'DR/CR',
                Cell: ({cell, table, row}) => {
                    var DR_CR: any = row.original['DR/CR?'] ? row.original['DR/CR?']: '-'
                    return (<div>
                        {DR_CR && (
                            <Text fw={500}>{DR_CR?DR_CR:''}</Text>)}
                    </div>)
                },
            },

            {
                accessorKey: 'Activity',
                header: 'Activity',
                Cell: ({cell, table, row}) => {
                    var Activity_Code: any = row.original['Activity Code'] ? row.original['Activity Code'] : '0'
                    var Activity_Value: any = row.original['Activity Value'] ? row.original['Activity Value'] : '0'
                    return (<div>
                        {Activity_Code && (
                            <Text fw={500}>Activity Code:{Activity_Code}</Text>)}
                        {Activity_Value && (
                            <Text fw={500}>Activity Value:{Activity_Value}</Text>)}
                    </div>)
                },
            },

            {
                accessorKey: 'Amount',
                header: 'Amount',
                Cell: ({cell, table, row}) => {
                    var Foreign_Amount: any = row.original['Foreign Amount'] ? row.original['Foreign Amount'] : '0'
                    var Local_Amount: any = row.original['Local Amount'] ? row.original['Local Amount'] : '0'
                    return (<div>
                        {Foreign_Amount && (
                            <Text fw={500}>Foreign Amount:{Foreign_Amount}</Text>)}
                        {Local_Amount && (
                            <Text fw={500}>Local Amount:{Local_Amount}</Text>)}
                    </div>)
                },
            },

            // {
            //     accessorKey: 'Discount',
            //     header: 'Dvd Acty Details',
            //     Cell: ({cell, table, row}) => {
            //         var DVD_ACTY_CODE_1: any = row.original.DVD_ACTY_CODE_1 ? row.original.DVD_ACTY_CODE_1 : '0'
            //         var DVD_ACTY_VALUE: any = row.original.DVD_ACTY_VALUE ? row.original.DVD_ACTY_VALUE : '0'
            //         return (<div>
            //             {DVD_ACTY_CODE_1 && (
            //                 <Text fw={500}>Disc Amount:{DVD_ACTY_CODE_1}</Text>)}
            //             {DVD_ACTY_VALUE && (
            //                 <Text fw={500}>Disc Perc:{DVD_ACTY_VALUE}</Text>)}
            //         </div>)
            //     },
            // }
        ],
        [],
    );

    return (


            <Card  shadow="sm"  radius="md" withBorder>


                <Card.Section withBorder inheritPadding py="lg" mb="sm">
                    <Group position="apart" >
                        <Text weight={700}>Finance Entry Details</Text>
                        <Button    onClick={handleDownload}  >
                      Download Pdf
                        </Button>
                    </Group>
                </Card.Section>


            {finHead ? (
                <div>

                        {/*<Paper shadow="xs" p="md">*/}
                        {/*<Title order={3} underline>Purchase Entry Details</Title>*/}


                        <Grid sx={{marginTop: '10px'}}>


                            <Grid.Col xs={12} md={2}>
                                <Text fw={500} sx={{lineHeight: '1.5rem'}}> Created User </Text>
                                <Text>     {finHead['Owner'] ? finHead['Owner'] : '-'}</Text>
                            </Grid.Col>


                            <Grid.Col xs={12} md={2}>
                                <Text fw={500} sx={{lineHeight: '1.5rem'}}> Created On </Text>
                                <Text> {finHead['Created On'] ? finHead['Created On'] : '-'}</Text>
                            </Grid.Col>


                            <Grid.Col xs={12} md={2}>
                                <Text fw={500} sx={{lineHeight: '1.5rem'}}>Division </Text>
                                <Text>     {finHead['Division'] ? finHead['Division'] : '-'}</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={2} sx={{marginTop: '-3px'}}>
                                <Text fw={500} sx={{lineHeight: '1.5rem'}}>Transaction </Text>
                                <Text>     {finHead['Transaction'] ? finHead['Transaction'] : '-'}</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={2}>
                                <Text fw={500} sx={{lineHeight: '1.5rem'}}>Fn. Year </Text>
                                <Text>     {finHead['Fn. Year'] ? finHead['Fn. Year'] : '-'}</Text>
                            </Grid.Col>
                        </Grid>


                </div>

            ) : null}
            {finDetails ? (
                <ThemeProvider theme={createTheme({})}>
                    <Paper mt='45px' sx={{width: "100%"}}>
                        <MaterialReactTable
                            muiTableBodyProps={{
                                sx: (theme) => ({
                                    '& tr:nth-of-type(odd)': {
                                        backgroundColor: '#fff',
                                    },
                                    '& tr:nth-of-type(even)': {
                                        backgroundColor: '#fff',
                                    },
                                }),
                            }}
                            // enableColumnActions={false}
                            // enableColumnFilters={false}
                            // enableTopToolbar={false}
                            // enableColumnDragging ={false}
                            muiBottomToolbarProps={{
                                sx: {
                                    height: '100px',
                                    backgroundColor: '#fff',
                                    fontWeight: '500',
                                    fontSize: '15px',
                                    color: 'rgb(34, 51, 84)'
                                }
                            }}
                            // muiTopToolbarProps={{
                            //     sx: {
                            //         height: '100px',
                            //         backgroundColor: '#fff',
                            //         fontWeight: '500',
                            //         fontSize: '15px',
                            //         color: 'rgb(34, 51, 84)'
                            //     }
                            // }}
                            // enableColumnFilterModes={false}
                            // enablePinning
                            // enableRowNumbers
                            // // enableRowNumbers
                            // rowNumberMode="original" //default
                            // editingMode="modal" //default
                            // enableColumnOrdering
                            // enableEditing
                            // enableGlobalFilter={false}

                            muiTableHeadCellProps={{
                                sx: (theme) => ({
                                    backgroundColor: '#2e439e', color: '#fff', fontSize: '14px'
                                }),
                            }}
                            columns={columns}
                            data={finDetails}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            //  enablePagination={false}
                            enableSorting={false}
                            //    enableBottomToolbar={false}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{hover: false}}
                            initialState={{
                                pagination: {pageIndex: 0, pageSize: 10},
                                columnVisibility: {redemption_id: false}
                            }}
                        />
                    </Paper>
                </ThemeProvider>
            ) : null}

            </Card>

    );
}

export default FinanceDetails;

import { useEffect, useState, memo } from "react";
import { useFormik } from "formik";
import { Grid } from "@mantine/core";
import { TInputField } from "../../typings";
import ParamTextInput from "./ParamTextInput";
import ParamTextarea from "./ParamTextarea";
import ParamSelect from "./ParamSelect";
import StaticValueModal from "../../ConfigWizard/StepUiSettings/StaticValueModal";
import DynamicValueModal from "../../ConfigWizard/StepUiSettings/DynamicValueModal";

const ParamInputCard = ({
  paramKey,
  paramCardValues,
  validation,
  inputFields,
  paramType,
  setIsFormValid,
  setParamCardValues,
  triggerFieldTouch,
  setTriggerFieldTouch,
  validateParam,
  setValidateParam,
}: any) => {
  const [isStaticValueModalOpen, setStaticValueModalOpen] = useState(false);
  const [isDynamicValueModalOpen, setDynamicValueModalOpen] = useState(false);
  const [selectedParamOfModal, setSelectedParamOfModal] = useState<string>();

  const handleOpenStaticValueModal = (paramKey: string) => {
    setSelectedParamOfModal(paramKey);
    setStaticValueModalOpen(true);
  };
  const handleOpenDynamicValueModal = (paramKey: string) => {
    setSelectedParamOfModal(paramKey);
    setDynamicValueModalOpen(true);
  };

  const cardFormik = useFormik({
    enableReinitialize: true,
    initialValues: paramCardValues,
    validate: validation,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {},
  });

  useEffect(() => {
    if (triggerFieldTouch) {
      const fieldsToTouch = Object.keys(paramCardValues).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      );
      cardFormik.setTouched(fieldsToTouch);
      // console.log("entered");
      setTriggerFieldTouch(false);
    }
  }, [triggerFieldTouch]);

  useEffect(() => {
    cardFormik.validateForm();
  }, [validateParam]);

  useEffect(() => {
    setParamCardValues((prev: Record<string, any>) => ({
      ...prev,
      [paramKey]: cardFormik.values,
    }));
  }, [cardFormik.values, paramCardValues, paramKey, setParamCardValues]);

  useEffect(() => {
    setIsFormValid((prev: any) => ({
      ...prev,
      [paramKey]: cardFormik.isValid,
    }));
  }, [cardFormik.isValid, paramKey, setIsFormValid]);

  const handleModalOpen = (valueType: string) => {
    const type = valueType ?? cardFormik.values.value_type;

    if (type === "STATIC") {
      handleOpenStaticValueModal(paramKey);
      return;
    }
    if (type === "DYNAMIC") {
      handleOpenDynamicValueModal(paramKey);
      return;
    }
  };

  return (
    <>
      {isStaticValueModalOpen && (
        <StaticValueModal
          isModalOpen={isStaticValueModalOpen}
          paramKey={selectedParamOfModal}
          cardFormik={cardFormik}
          handleModalClose={() => {
            setStaticValueModalOpen(false);
          }}
        />
      )}
      {isDynamicValueModalOpen && (
        <DynamicValueModal
          isModalOpen={isDynamicValueModalOpen}
          paramKey={selectedParamOfModal}
          cardFormik={cardFormik}
          handleModalClose={() => {
            setDynamicValueModalOpen(false);
          }}
        />
      )}
      <Grid.Col xs={11}>
        <Grid gutter="sm">
          {inputFields.map((inputField: TInputField) => {
            if (inputField.fieldType === "TXT")
              return (
                <Grid.Col
                  xs={12}
                  md={
                    paramType === "Params"
                      ? 6
                      : paramType === "OutParam"
                      ? 3
                      : 4
                  }
                  key={`param-grid-${inputField.fieldName}-of-${paramKey}`}
                >
                  <ParamTextInput
                    id={`param-textField-${inputField.fieldName}-of-${paramKey}`}
                    inputField={inputField}
                    formik={cardFormik}
                    handleModalOpen={handleModalOpen}
                    setValidateParam={setValidateParam}
                  />
                </Grid.Col>
              );
            else if (inputField.fieldType === "TXT_AREA")
              return (
                <Grid.Col
                  xs={12}
                  md={
                    paramType === "Params"
                      ? 6
                      : paramType === "OutParam"
                      ? 3
                      : 4
                  }
                  key={`param-grid-${inputField.fieldName}-of-${paramKey}`}
                  sx={{
                    display:
                      cardFormik.values.value_type === "CURRENT"
                        ? "none"
                        : "block",
                  }}
                >
                  <ParamTextarea
                    id={`param-textField-${inputField.fieldName}-of-${paramKey}`}
                    inputField={inputField}
                    formik={cardFormik}
                    handleModalOpen={handleModalOpen}
                    setValidateParam={setValidateParam}
                  />
                </Grid.Col>
              );
            else
              return (
                <Grid.Col
                  xs={12}
                  md={
                    paramType === "Params"
                      ? 6
                      : paramType === "OutParam"
                      ? 3
                      : 4
                  }
                  key={`param-grid-${inputField.fieldName}-of-${paramKey}`}
                >
                  <ParamSelect
                    id={`param-select-${inputField.fieldName}-of-${paramKey}`}
                    formik={cardFormik}
                    inputField={inputField}
                    handleModalOpen={handleModalOpen}
                    setValidateParam={setValidateParam}
                  />
                </Grid.Col>
              );
          })}
        </Grid>
      </Grid.Col>
    </>
  );
};
export default memo(ParamInputCard);

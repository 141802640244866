import {useEffect, useMemo, useState} from "react";
import {   Box, Button, Grid, Group, Loader,  LoadingOverlay, Text,Card,  Title, Paper} from "@mantine/core";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import MaterialReactTable, {
    MaterialReactTableProps,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';
type WpsPropsTypes = { approvedListData: any; };
import {Link, generatePath} from "react-router-dom";
import {  Tooltip, IconButton} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';

const ApprovedList = ({approvedListData}: WpsPropsTypes) => {

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Supplier',
                header: 'Code',
                Cell: ({cell, table, row}) => {
                    var Main_Account: any = row.original['SUPP_MAIN_ACNT_CODE'] ? row.original['SUPP_MAIN_ACNT_CODE']  : ''
                    var name: any = row.original.SUPP_NAME ? row.original.SUPP_NAME : '-'
                    return (<div>
                        {Main_Account && (<Text fw={500}>{Main_Account}</Text>)}
                        {name && (
                            <Text fw={500}>{name}</Text>)}
                    </div>)
                },
            },
            {
                accessorKey: 'Email',
                header: 'Email',
                Cell: ({cell, table, row}) => {
                    var Department: any = row.original.SUPP_EMAIL ? row.original.SUPP_EMAIL : '-'
                    var phone: any = row.original.SUPP_TEL1 ? row.original.SUPP_TEL1 : '-'
                    return (<div>
                        {Department && (   <Text fw={500}>{Department}</Text>)}
                        {phone && (  <Text fw={500}>{phone ? phone : '-'}</Text>)}
                    </div>)
                },
            },

            {
                accessorKey: 'Bank',
                header: 'Bank',
                Cell: ({cell, table, row}) => {
                    var SUPPPRH_BANK_NAME: any = row.original['SUPPB_AC_NAME'] ? row.original['SUPPB_AC_NAME'] : ''
                    var SUPPPRH_BANK_CODE: any = row.original['SUPPB_BANK_CODE'] ? row.original['SUPPB_BANK_CODE'] : ''
                    var SUPPPRH_BRANCH_NAME: any = row.original['SUPPB_AC_NO'] ? row.original['SUPPB_AC_NO'] : ''
                    return (<div>
                        {SUPPPRH_BANK_NAME && (
                            <Text fw={500}>Name:{SUPPPRH_BANK_NAME}</Text>)}
                        {SUPPPRH_BANK_CODE && (
                            <Text fw={500}>Code:{SUPPPRH_BANK_CODE}</Text>)}

                        {SUPPPRH_BRANCH_NAME && (
                            <Text fw={500}>Branch:{SUPPPRH_BRANCH_NAME}</Text>)}

                    </div>)
                },
            },

            {
                accessorKey: 'Status',
                header: 'Status',
                Cell: ({cell, table, row}) => {
                    var SUPPPRH_STATUS: any = row.original['SUPPB_STATUS'] ? row.original['SUPPB_STATUS'] : ''
                    // var Local_Amount: any = row.original['Local Amount'] ? row.original['Local Amount'] : '0'
                    return (<div>
                        {SUPPPRH_STATUS && (
                            <Text fw={500}>{SUPPPRH_STATUS}</Text>)}
                        {/*{Local_Amount && (*/}
                            {/*<Text fw={500}>Local Amount:{Local_Amount}</Text>)}*/}
                    </div>)
                },
            },
        ],
        [],
    );


    return (
        <>

            <Card  shadow="sm"  radius="md" withBorder mt={'30px'}>


                {/*<Card.Section withBorder inheritPadding py="lg" mb="sm">*/}

                {/*</Card.Section>*/}


                {approvedListData ? (
                    <ThemeProvider theme={createTheme({})}>
                        <Paper mt='15px' sx={{width: "100%"}}>
                            <MaterialReactTable
                                muiTableBodyProps={{
                                    sx: (theme) => ({
                                        '& tr:nth-of-type(odd)': {
                                            backgroundColor: '#fff',
                                        },
                                        '& tr:nth-of-type(even)': {
                                            backgroundColor: '#fff',
                                        },
                                    }),
                                }}
                                // enableColumnActions={false}
                                // enableColumnFilters={false}
                                // enableTopToolbar={false}
                                // enableColumnDragging ={false}
                                muiBottomToolbarProps={{
                                    sx: {
                                        height: '100px',
                                        backgroundColor: '#fff',
                                        fontWeight: '500',
                                        fontSize: '15px',
                                        color: 'rgb(34, 51, 84)'
                                    }
                                }}
                                // muiTopToolbarProps={{
                                //     sx: {
                                //         height: '100px',
                                //         backgroundColor: '#fff',
                                //         fontWeight: '500',
                                //         fontSize: '15px',
                                //         color: 'rgb(34, 51, 84)'
                                //     }
                                // }}
                                // enableColumnFilterModes={false}
                                // enablePinning
                                // enableRowNumbers
                                // // enableRowNumbers
                                // rowNumberMode="original" //default
                                // editingMode="modal" //default
                                // enableColumnOrdering
                                // enableEditing
                                // enableGlobalFilter={false}

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        backgroundColor: '#2e439e', color: '#fff', fontSize: '14px'
                                    }),
                                }}
                                columns={columns}
                                data={approvedListData}
                                // enableColumnActions={false}
                                enableColumnFilters={false}
                                //  enablePagination={false}
                                enableSorting={false}
                                //    enableBottomToolbar={false}
                                enableTopToolbar={false}
                                muiTableBodyRowProps={{hover: false}}
                                initialState={{
                                    pagination: {pageIndex: 0, pageSize: 10},
                                    columnVisibility: {redemption_id: false}
                                }}
                                positionActionsColumn="last"

                                enableRowActions
                                renderRowActions={({ row, table }) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                        <IconButton
                                            color="secondary"
                                            // onClick={() => {
                                            //     table.setEditingRow(row);
                                            // }}
                                            >
                                            <Link   to={generatePath("/supplier/approved-view/:id", {id: row.original['SUPP_CODE']})}>
                                            <VisibilityIcon/>
                                                </Link>
                                        </IconButton>
                                    </Box>
                                )}

                            />
                        </Paper>
                    </ThemeProvider>
                ) : null}

            </Card>
        </>
    );
};

export default ApprovedList;

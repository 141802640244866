import { Link, useLocation } from "react-router-dom";

import logo from "../../../assets/images/logo/ffclog1.png";

import { Box, Paper, Stack, Text } from "@mantine/core";
import AuthForm from "./AuthForm";
import AuthFooter from "../AuthFooter";

const Login = () => {
  const urlParam = useLocation().search;

  return (
    <Stack
      align="center"
      sx={(theme) => ({
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.colors.ffcBrand[1],
      })}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Paper
          shadow="sm"
          sx={{
            padding: "3rem",
            width: "100%",
            maxWidth: "30rem",
          }}
        >
          <Stack align="center">
            <Box sx={{ mb: 3 }}>
              <Link to="#">
                <img src={logo} alt="logo" height={50} />
              </Link>
            </Box>
            <Text size={50}>Login</Text>
            <Text>Enter your credentials to continue</Text>
            <Box sx={{ width: "100%" }}>
              <AuthForm urlParam={urlParam} />
            </Box>
          </Stack>
        </Paper>
      </Box>
      <Box sx={{ p: 10, mt: 1 }}>
        <AuthFooter />
      </Box>
    </Stack>
  );
};

export default Login;
